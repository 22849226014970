import React, { useState, useEffect, useCallback } from "react";
import { Grid  } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete'; 
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";


// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data 
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "../../../components/Table/Table"; 
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import { PremiumCodeList, PremiumCodeDeleteAll, PremiumCodeInsert } from "../../../apis/premiums";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function PremiumCode() {
  const classes = useStyles();
  const [premiumCode, setPremiumCode] = useState([])
  const [loading, setLoading] = useState(true);
  const [fullLoading, setFullLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [fileInput, setFileInput] = useState(undefined);
  const location = useLocation();
  const history = useHistory()
  const [currentItem, setCurrentItem] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState([]);
  
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );


  useEffect(() => {
    (async () => {
      setLoading(true);
      const list = await PremiumCodeList(filter)
      setLoading(false);
      setPremiumCode(list?.data)
      setTotal(list?.total)
    })();
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleImportCode = async (data) => {
    setFullLoading(true)
    await PremiumCodeInsert(data)
    const list = await PremiumCodeList(filter)
    setFileInput(undefined)
    setPremiumCode(list?.data)
    setTotal(list?.total)
    setFullLoading(false);
  }
  function openImport() {
    document?.getElementById("fileid")?.click();
  }
  const onFileChange = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        handleImportCode({
          data: lines?.map(line => line?.trim()),
        });
      };
      reader.readAsText(file);
    }

  };
  const handleShowConfirm = () => {
    setOpenConfirm(true)
  }
  const handleDeletePremiumCode = async () => {
    setFullLoading(true);
    await PremiumCodeDeleteAll()
    const list = await PremiumCodeList(filter)
    setPremiumCode(list?.data)
    setTotal(list?.total)
    setCurrentItem(undefined)
    setFullLoading(false);
    setOpenConfirm(false);
  }
  const columns = [
    { field: 'stt', headerName: 'stt', width: 30, editable: false, sortable: false },
    {
      field: 'uid',
      headerName: 'code',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'counter',
      headerName: 'counter',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'action', headerName: 'action', width: 120, type: 'number',
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <DeleteIcon onClick={() => handleShowConfirm(params?.row)} />
            </IconButton>
          </>
        );
      }
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="PremiumCode" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            {/* <Filter filter={filter} setFilter={setFilterToParams} /> */}
            <Button onClick={openImport} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Import</Button>

            <Button onClick={() => {
              handleShowConfirm()
            }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">DELETE</Button>
            {/* <Button onClick={() => setOpenConfirm(false)} disabled={!selectedRow.length} style={{ height: 48, width: 120 }} variant="contained" color="secondary">Delete</Button> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="PremiumCode" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={premiumCode?.map((code, i) => ({ ...code, id: code.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete cards ?" onSubmit={() => { handleDeletePremiumCode() }} />
      <input value={fileInput} onChange={onFileChange} id="fileid" type="file" hidden />

    </>
  );
}
