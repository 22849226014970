import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";



// components
import PageTitle from "@/components/PageTitle/PageTitle";
import Widget from "@/components/Widget/Widget";

// data
import Filter from "./Filter";
import {
  Button, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Select, InputLabel,
  FormControl,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "@/components/Table/Table";
import DialogConfirm from "@/components/DialogConfirm/DialogConfirm";
import { useParams } from "react-router";
import { createPackageScenarios, deletePackageScenarios, getListPackageScenarios, getPackage, } from "../../../../../apis/packages";


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
const defaultParams = {
  page: 1, limit: 100, keyword: '', status: ''
}
let timer
export default function PackageScript() {
  const classes = useStyles();
  const { id } = useParams()
  const [scripts, setPackageScript] = useState([])
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [selectedRow, setSelectedRow] = useState([]);
  const [packageInfo, setPackageInfo] = useState({})
  const location = useLocation();
  const history = useHistory()

  const [openTypeDialog, setOpenTypeDialog] = React.useState(false)
  const [scenarioType, setScenarioType] = React.useState("YOUTUBE")

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: 100,
        // limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  useEffect(() => {
    (async () => {
      var packInfo = await getPackage(id)
      setPackageInfo(packInfo)
      if (packInfo && packInfo.package_type) {
        setScenarioType(packInfo.package_type)
      }
    })()



    clearTimeout(timer);
    timer = setTimeout(() => {
      (async () => {
        setLoading(true);
        const list = await getListPackageScenarios(filter, id)
        setLoading(false);
        setTotal(list?.total)
        setPackageScript(list?.data)
      })();
    }, 300)
  }, [filter, id]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleDeletePackageScript = async () => {
    await deletePackageScenarios({ uids: selectedRow }, id)
    const list = await getListPackageScenarios(filter, id)
    setPackageScript(list?.data)
    setTotal(list?.total)
    setOpenConfirm(false);
  }

  const handleShowConfirm = () => {
    setOpenConfirm(true)
  }

  const handleEdit = (row) => {
    history.push(`/app/package/${id}/script/${row.uid}`)
  }

  const handleDelete = (row) => {
    setSelectedRow([row.uid])
    setOpenConfirm(true)
  }
  const handleAdd = async () => {
     // create 
    var initScenario = {
      name: "need_change",
      weight: 10,
      type: scenarioType,
      max_duration: 10,
      package_uid: id,
      scripts: [
        {
          "parent_index": -1,
          "action": "END",
          "note": "End",
          "right": -1,
          "wrong": -1,
          curIndex: 0
        },
      ],
    }
    var dataCreate = await createPackageScenarios({ data: [initScenario] }, id)
    console.log("dataCreate:", dataCreate)
    history.push(`/app/package/${id}/script/${dataCreate.uids[0]}`)

    // setOpenTypeDialog(true)
  }

  function openImport() {
    document?.getElementById("fileid")?.click();
  }

  const onFileChange = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        // console.log('this?.result', JSON.parse(this?.result))
        var data = JSON.parse(this?.result)
        await createPackageScenarios(data, id);
        const list = await getListPackageScenarios(filter, id)
        setLoading(false);
        setTotal(list?.total)
        setPackageScript(list?.data)
      };
      reader.readAsText(file);
    }
  };
  const handleExport = () => {
    const element = document.createElement("a");
    const dataExport = scripts.filter(item => selectedRow.includes(item.uid))
    const file = new Blob(
      [
        JSON.stringify(
          {
            data: dataExport
          },
          null,
          2
        ),
      ],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "scripts.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const columns = [
    { field: 'stt', headerName: 'stt', width: 30, editable: false },
    { field: 'name', headerName: 'name', flex: 0.7, editable: false },
    {
      field: 'script', headerName: 'script', width: 200,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return params?.row?.scripts?.map(key => <Chip
          label={key.action}
          key={key.action}
        />)
      }
    },
    { field: 'weight', headerName: 'weight', width: 120, editable: false },
    { field: 'selected_times', headerName: 'selected', width: 120, editable: false },
    { field: 'max_duration', headerName: 'duration', width: 180, editable: false },
    { field: 'selected_times', headerName: 'Selected Times', width: 180, editable: false },
    { field: 'type', headerName: 'type', width: 180, editable: false },
    {
      field: 'action', headerName: 'Actions', width: 120, type: 'number',
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <EditIcon onClick={() => handleEdit(params?.row)} />
            </IconButton>
            <IconButton>
              <DeleteIcon onClick={() => handleDelete(params?.row)} />
            </IconButton>
          </>
        );
      }
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Scripts" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Filter filter={filter} setFilter={setFilterToParams} />
            <did>
              <Button onClick={openImport} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Import</Button>
              <Button disabled={!selectedRow.length} onClick={handleExport} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Export</Button>
              <Button disabled={!selectedRow.length} onClick={() => { handleShowConfirm() }} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">Delete</Button>
              <Button onClick={handleAdd} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">Add</Button>
            </did>
          </Box>
        </Grid>
        <Grid item xs={12} >
          <Box display="flex" flexGrow="1">
            {/* <div style={{ width: 180, marginRight: 20 }}>
              <MenuLink id={id} />
            </div> */}
            <div style={{ width: "100%" }} >
              <Widget title="List Script" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                <DataTable
                  total={total}
                  columns={columns}
                  data={scripts?.map((user, i) => ({ ...user, id: user.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
                  loading={loading}
                  setSelectedRow={setSelectedRow}
                  selectedRow={selectedRow}
                  filter={filter}
                  setFilter={setFilterToParams}
                />
              </Widget>
            </div>
          </Box>
        </Grid>
        <input value="" onChange={onFileChange} id="fileid" type="file" hidden />
      </Grid>
      <DialogConfirm
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        message={`Are you sure delete script?`}
        onSubmit={() => { handleDeletePackageScript() }}
      />
      <Dialog
        fullWidth
        open={openTypeDialog}
        onClose={() => { setOpenTypeDialog(false) }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Scenario Type</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl} >
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Scenario Types
            </InputLabel>
            <Select
              value={scenarioType}
              onChange={(event) => {
                setScenarioType(event.target.value);
              }}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {
                [
                  { value: "YOUTUBE" },
                  { value: "TIKTOK" },
                  { value: "REDDIT" },
                  { value: "GITLAB" },
                  { value: "MEDIUM" },
                  { value: "LINKEDIN" },
                ]?.map(pk => <MenuItem key={pk.value} value={pk.value}>{pk.value}</MenuItem>)
              }
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenTypeDialog(false) }} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!scenarioType}
            onClick={async () => {
              // create 
              var initScenario = {
                name: "need_change",
                weight: 10,
                type: scenarioType,
                max_duration: 10,
                package_uid: id,
                scripts: [
                  {
                    "parent_index": -1,
                    "action": "END",
                    "note": "End",
                    "right": -1,
                    "wrong": -1,
                    curIndex: 0
                  },
                ],
              }
              var dataCreate = await createPackageScenarios({ data: [initScenario] }, id)
              console.log("dataCreate:", dataCreate)
              history.push(`/app/package/${id}/script/${dataCreate.uids[0]}`)

              // history.push(`/app/package/${id}/script-add/${typeDialog}`)
            }}
            type="submit" variant="contained" color="primary">
            ADD AND CONFIG
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
