import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { SvgIcon } from "material-ui";
import React, { useEffect, useState } from "react";
import {
    countOrders,
    pushAction,
    requestOrderByMachine,
    getImage,
    getPosition,
} from "../../../../../apis/stream";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

var time;
var pushAt = 0
var loadingImage = false

export default function DialogStream({ openDialogStream, onClose, machine, subMachine }) {
    const [session, setSession] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState({
        requestOrder: false,
    });
    loadingImage = false
    const [coords, setCoords] = useState({ x: 0, y: 0 });

    const handlePushAction = async (action, text = "") => {
        if (session) {
            await pushAction({
                session,
                actions: [
                    {
                        action,
                        loc_x: coords.x,
                        loc_y: coords.y,
                        text,
                    },
                ],
            });
        }
    };

    useEffect(() => {
        handleRequestOrder();
        const timer = setInterval(handleRequestOrder, 5000);

        const timerImage = setInterval(async () => {
            console.log(loadingImage)
            if (session && !loadingImage) {
                loadingImage = true
                const imageResp = await getImage({ session: session });
                try {
                    if (
                        imageResp.capture &&
                        // (imageResp?.push_at > imageSrc?.push_at || !imageSrc?.push_at)) &&
                        imageResp?.push_at > pushAt &&
                        !imageResp.capture.includes('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')) {
                        // console.log(imageSrc)
                        // console.log(imageResp)
                        pushAt = imageResp.push_at
                        setImageSrc(imageResp);
                    }
                } catch (error) {

                }
                loadingImage = false
            }
        }, 200);

        // const timerPosition = setInterval(async () => {
        //     if (session) {
        //         const positionResp = await getPosition({ session: session });
        //         if (positionResp.x_mouse && positionResp.y_mouse) {
        //             setPositionMouse(positionResp);
        //         }
        //     }
        // }, 500);
        return () => {
            clearInterval(timer);
            clearInterval(timerImage);
            // clearInterval(timerPosition);
        };
    }, [session]);

    const handleRequestOrder = async () => {
        try {
            if (!session) {
                const data = await requestOrderByMachine({ machine: machine, sub_machine: subMachine });
                console.log(data);
                if (data.uid) {
                    setSession(data.uid);
                }
            }
        } catch (error) {
            setLoading({
                ...loading,
                requestOrder: false,
            });
        }
    };
    const handleMouseMove = (event) => {
        setCoords({
            x: event.clientX - event.target.offsetLeft,
            y: event.clientY - 250,
        });
        clearTimeout(time);
        time = setTimeout(() => {
            handlePushAction("MOVE_MOUSE");
        }, 70);
    };

    const handleDoubleClick = () => {
        handlePushAction("DOUBLE_CLICK");
    };

    let scrollTopOld = 0;
    const handleScroll = (e) => {
        console.log.apply(e.currentTarget?.scrollTop)
        const isScrollDown = e.currentTarget?.scrollTop > scrollTopOld;
        scrollTopOld = e.currentTarget?.scrollTop;
        if (isScrollDown) {
            console.log("SCROLL_DOWN")
            handlePushAction("SCROLL_DOWN");
        } else {
            console.log("SCROLL_UP")
            handlePushAction("SCROLL_UP");
        }
    };

    const handleDrag = () => {
        console.log("handleDrag");
        handlePushAction("DRAG");
    };

    const handleRightClick = () => {
        console.log("handleRightClick");
        handlePushAction("RIGHT_CLICK");
    };

    const handleClick = () => {
        console.log("onClick");
        handlePushAction("CLICK");
    };

    const handleAnswerChange = (event) => {
        handlePushAction("INPUT", event.key);
    };

    return (
        <Dialog fullWidth maxWidth="lg" open={openDialogStream} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{machine}:{subMachine} {session ? "" : "waiting ..."}</DialogTitle>
            <DialogContent>


                <>
                    <div
                        variant="outlined"
                        style={
                            imageSrc
                                ? {
                                    position: "relative",
                                    marginTop: 30,
                                    marginLeft: 0,
                                    marginBottom: 30,
                                    width: imageSrc?.x_size,
                                    height: imageSrc?.y_size,
                                    padding: 0,
                                    opacity: 0.7,
                                    border: "none",
                                }
                                : {
                                    marginTop: 30,
                                    marginBottom: 30,
                                    padding: 0,
                                    height: 300,
                                    overflow: "auto",
                                    // minHeight: 300,
                                    border: "none",
                                }
                        }
                        square
                        onKeyDown={handleAnswerChange}
                        // onMouseMove={handleOnMouseMove}
                        onDrag={handleDrag}
                        onDoubleClick={handleDoubleClick}
                        onContextMenu={handleRightClick}
                        onMouseMove={handleMouseMove}
                        onClick={handleClick}
                        onScroll={handleScroll}
                        draggable
                        tabIndex="0"
                        className="w-container"
                    >
                        {imageSrc && (
                            <img
                                style={{
                                    width: imageSrc?.x_size,
                                    height: imageSrc?.y_size,
                                }}
                                src={`data:image/png;base64, ${imageSrc?.capture}`}
                                alt="img"
                            />
                        )}
                    </div>
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
