import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";
import moment from "moment";


// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
// import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "../../../components/Table/Table";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import {
  createAdsProxies,
  deleteAllAdsProxies,
  getListAdsProxies,
} from "../../../apis/proxies";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
let timer
export default function ProxyAds() {
  const classes = useStyles();
  const [proxies, setProxies] = useState([])
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [fileInput, setFileInput] = useState(undefined);
  const [total, setTotal] = useState(0);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      (async () => {
        setLoading(true);
        const list = await getListAdsProxies(filter)
        setLoading(false);
        setProxies(list?.data)
        setTotal(list?.total)
      })();
    }, 300)
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleDeleteProxies = async () => {
    await deleteAllAdsProxies()
    const list = await getListAdsProxies(filter)
    setProxies(list?.data)
    setTotal(list?.total)
    setOpenConfirm(false);
  }

  const handleImportProxies = async (data) => {
    await createAdsProxies(data)
    const list = await getListAdsProxies(filter)
    setFileInput(undefined)
    setProxies(list?.data)
    setTotal(list?.total)
  }
  function openImport() {
    document?.getElementById("fileid")?.click();
  }
  const onFileChange = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        handleImportProxies({
          data: lines?.map(pr => pr?.trim()),
        });
      };
      reader.readAsText(file);
    }
  };
  const columns = [
    { field: 'stt', headerName: 'STT', width: 150, editable: false },
    {
      field: 'proxy', headerName: 'Proxy', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'counter', headerName: 'Counter', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'created_at', headerName: 'Created At', width: 120,
      flex: 0.3,
      renderCell: (params) => moment.unix(params.row.created_at).format('DD-MM-YYYY HH:MM A'),
      editable: false
    },
    {
      field: 'status', headerName: 'Status', width: 120,
      flex: 0.3,
      editable: false
    },
  ];
  return (
    <>
      <PageTitle title="Ads Proxy" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            <Button onClick={openImport} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Import</Button>
            <Button onClick={() => setOpenConfirm(true)} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">Delete All</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Ads Proxy" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={proxies?.map((item, i) => ({ ...item, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={() => { }}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
      <input value={fileInput} onChange={onFileChange} id="fileid" type="file" hidden />
      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete all ads proxies?" onSubmit={() => { handleDeleteProxies() }} />
    </>
  );
}
