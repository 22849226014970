import React, { useState, useEffect, useCallback, useRef } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "../../../components/Table/Table";
import moment from "moment";
import { deleteGmailAccount, getListGmailAccount } from "../../../apis/account";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import ButtonLoading from "../../../components/ButtonLoading/ButtonLoading";

const axios = require('axios')
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: '', from: '', to: ''
}
export default function Accounts() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(defaultParams);
  const [selectedRow, setSelectedRow] = useState([]);
  const [dataDownload, setDataDownload] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const btnRef = useRef(null)
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
        sort_dir: "desc",
        sort_by: "created_at",
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  const [gmails, setGmailAccount] = useState([])

  useEffect(() => {
    (async () => {
      setLoading(true);
      const list = await getListGmailAccount(filter)
      setLoading(false);
      setGmailAccount(list?.data)
      setTotal(list?.total)
    })();
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleDeleteGmailAccount = async () => {
    await deleteGmailAccount({ uids: selectedRow })
    const list = await getListGmailAccount(filter)
    setGmailAccount(list?.data)
    setTotal(list?.total)
    setOpenConfirm(false);
  }

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    let plainText = ``
    dataDownload.map(item => {
      plainText += `${moment.unix(item.created_at).format('DD-MM-YYYY HH:MM')}\t${item.email}\t${item.password}\t${item.backup_email}\t${item.machine}\t${item.sub_machine}\n`
      return plainText
    })
    const file = new Blob(
      [plainText],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "account-gmail.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  async function getDownloadData(page, limit) {
    return await axios.get(`${process.env.REACT_APP_API_URL}/u-apis/accounts/gmails?page=${page}&limit=${limit}&from=${filter.from}&to=${filter.to}&search=${filter.search}&sort_by=created_at&sort_dir=asc`);
  }
  const handleDownload = async () => {
    setBtnLoading(true)
    const limit = 100
    const count = Math.ceil(total / limit);
    const promises = [...Array(count)].map(async (pr, i) => await getDownloadData(i + 1, limit))
    const all = await Promise.all(promises)
    let data = []
    all.map(item => {
      data = [...data, ...item.data.data]
      return data
    })
    setBtnLoading(false)
    setDataDownload(data)
    btnRef?.current?.click()
    return data
  }
  const columns = [
    { field: 'stt', headerName: 'stt', width: 30, editable: false, sortable: false },
    {
      field: 'created_at', headerName: 'created_at', width: 120,
      flex: 0.2,
      renderCell: (params) => moment.unix(params.row.created_at).format('DD-MM-YYYY HH:MM A'),
      editable: false,
      sortable: false
    },
    {
      field: 'email',
      headerName: 'email',
      flex: 0.2,
      editable: false,
      sortable: false
    },
    {
      field: 'password', headerName: 'password', width: 120,
      flex: 0.2,
      editable: false,
      sortable: false
    },
    {
      field: 'backup_email', headerName: 'backup', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false
    },
    // {
    //   field: 'isp', headerName: 'isp', width: 120,
    //   flex: 0.3,
    //   editable: false,
    //   sortable: false
    // },
    {
      field: 'ua', headerName: 'ua', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false
    },
    // {
    //   field: 'action', headerName: 'Actions', width: 120, type: 'number',
    //   flex: 0.3,
    //   editable: false,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <IconButton>
    //           <DeleteIcon onClick={() => handleShowConfirm(params?.row?.uid)} />
    //         </IconButton>
    //       </>
    //     );
    //   }
    // },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Accounts" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Filter filter={filter} setFilter={setFilterToParams} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={() => setOpenConfirm(true)} disabled={!selectedRow.length} style={{ height: 48, width: 120 }} variant="contained" color="secondary">Delete</Button>
              <ButtonLoading loading={btnLoading} onClick={handleDownload} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">
                Export
              </ButtonLoading>
              <Button ref={btnRef} onClick={downloadTxtFile} style={{ height: 48, width: 120, marginLeft: 10, display: 'none' }} variant="contained" color="primary">
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Account List" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={gmails?.map((user, i) => ({ ...user, id: user.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete account?" onSubmit={() => { handleDeleteGmailAccount() }} />
    </>
  );
}
