import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";
import moment from 'moment'
import { CSVLink } from "react-csv";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// components
import PageTitle from "@/components/PageTitle/PageTitle";
import Widget from "@/components/Widget/Widget";

// data
import Filter from "./Filter";
import DataTable from "@/components/Table/Table";
import { useParams } from "react-router";
import { getListAndroid, importMachineAndroidEmail, importMachineAndroiProxies } from "../../../../../apis/machine";
import { useInterval } from "../../../../../helpers/utils";

const axios = require('axios')
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, name: '', status: ''
}
let timer;
export default function AndroidList() {
  const classes = useStyles();
  const { id } = useParams()
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [vms, setVms] = useState([])
  const [filter, setFilter] = useState(defaultParams);
  const [dataDownload, setDataDownload] = useState([]);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      (async () => {
        setLoading(true);
        const list = await getListAndroid(filter, id)
        setLoading(false);
        setTotal(list?.total)
        setVms(list?.data)
      })();
    }, 300)
  }, [filter, id]);

  useInterval(() => {
    (async () => {
      setLoading(true);
      const list = await getListAndroid(filter, id)
      setLoading(false);
      setTotal(list?.total)
      setVms(list?.data)
    })()
  }, 1000 * 300)

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  async function getUserData(page, limit) {
    return await axios.get(`${process.env.REACT_APP_API_URL}/u-apis/machines/${id}/vms?page=${page}&limit=${limit}`);
  }

  const handleDownload = async () => {
    const limit = 100
    const count = Math.ceil(total / limit);
    const promises = [...Array(count)].map(async (pr, i) => await getUserData(i + 1, limit))
    const all = await Promise.all(promises)
    let data = []
    all.map(item => {
      data = [...data, ...item.data.data]
      return data
    })
    setDataDownload(data)
  }

  function openImportEmail() {
    document?.getElementById("fileidEmail")?.click();
  }
  const onFileChangeEmail = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        let listIps = [];
        for (var line = 0; line < lines.length; line++) {
          const stringArray = lines[line].replace(/\t/g, " ").split(" ");
          const item = {
            sub_machine: stringArray[0],
            email: stringArray[1],
            pass: stringArray[2] || "",
            backup_email: stringArray[3] || "",
          };
          listIps.push(item);
        }
        await importMachineAndroidEmail({
          data: listIps,
        }, id);
        const list = await getListAndroid(filter, id)
        setLoading(false);
        setTotal(list?.total)
        setVms(list?.data)
      };
      reader.readAsText(file);
    }
  };

  function openImportProxies() {
    document?.getElementById("fileidProxies")?.click();
  }
  const onFileChangeProxies = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        await importMachineAndroiProxies({
          data: lines?.map(pr => pr?.trim()),
        }, id);
        const list = await getListAndroid(filter, id)
        setLoading(false);
        setTotal(list?.total)
        setVms(list?.data)
      };
      reader.readAsText(file);
    }
  };

  // function openImportUserAgent() {
  //   document?.getElementById("fileidUserAgent")?.click();
  // }
  // const onFileChangeUserAgent = (e) => {
  //   const file = e?.target?.files ? e?.target?.files[0] : null;
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = function (progressEvent) {
  //       // By lines
  //       var lines = (this?.result).split("\n");
  //       importMachineUserAgent({
  //         data: lines?.map(pr => pr?.trim()),
  //       });
  //     };
  //     reader.readAsText(file);
  //   }
  // };

  const columns = [
    { field: 'stt', headerName: 'stt', width: 30, editable: false, sortable: false, },
    {
      field: 'emulator',
      headerName: 'emulator',
      flex: 0.3,
      editable: false
    },
    {
      field: 'machine_name', headerName: 'machine', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'status', headerName: 'status', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'counter', headerName: 'counter', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'proxy', headerName: 'proxy', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'email', headerName: 'email', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'pass', headerName: 'pass', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'backup_email', headerName: 'backup', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'script_name', headerName: 'scenarios', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'script_name', headerName: 'Last time', width: 120,
      sortable: false,
      flex: 0.3,
      editable: false,
      renderCell: (params) => moment.unix(params.row.created_at).format('DD-MM-YYYY HH:MM A'),
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Machine VMS" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Filter filter={filter} setFilter={setFilterToParams} />
            <did>
              {/* <Button onClick={openImportUserAgent} style={{ height: 48, width: 150, marginLeft: 10 }} variant="contained" color="primary">Import UserAgent</Button> */}
              <Button onClick={openImportEmail} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">Import Email</Button>
              <Button onClick={openImportProxies} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">Import Proxies</Button>
              <Button style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">
                <CSVLink onClick={async (event, done) => await handleDownload().then((res) => { console.log('res', res) })} style={{ color: '#fff', textDecoration: 'none' }} data={dataDownload}>
                  Export
                </CSVLink>
              </Button>
            </did>
          </Box>
        </Grid>
        <Grid item xs={12} >
          <Box display="flex" flexGrow="1">
            {/* <div style={{ width: 180, marginRight: 20 }}>
              <MenuLink id={id} />
            </div> */}
            <div style={{ width: "100%" }} >
              <Widget title="VMS List" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                <DataTable
                  total={total}
                  columns={columns}
                  data={vms?.map((user, i) => ({ ...user, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
                  loading={loading}
                  setSelectedRow={() => { }}
                  filter={filter}
                  setFilter={setFilterToParams}
                />
              </Widget>
            </div>
          </Box>
        </Grid>
      </Grid>
      <input onChange={onFileChangeEmail} id="fileidEmail" type="file" hidden />
      <input onChange={onFileChangeProxies} id="fileidProxies" type="file" hidden />
      {/* <input onChange={onFileChangeUserAgent} id="fileidUserAgent" type="file" hidden /> */}
    </>
  );
}
