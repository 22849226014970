import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  InputLabel,
  Select,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OPTIONS = [
  {
    name: "NO FILLTER",
    value: "",
  },
  {
    name: "RESET",
    value: "RESET",
  },
  {
    name: "START",
    value: "START",
  },
  {
    name: "DISCONNECT",
    value: "DISCONNECT",
  },
  {
    name: "RUNNING",
    value: "RUNNING",
  },
  {
    name: "NO_NETWORK",
    value: "NO_NETWORK",
  },
  {
    name: "SIM_ERROR",
    value: "SIM_ERROR",
  },
];
export default function Filter({ filter, setFilter }) {
  const classes = useStyles();
  const [search, setsearch] = useState(filter);
  React.useEffect(() => {
    setsearch(filter);
  }, [filter]);
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={() => setFilter({ ...filter, ...search, time: Math.random() })}
    >
      <TextField
        id="standard-basic"
        label="machine"
        value={search.machine}
        onChange={(event) =>
          setsearch({ ...search, machine: event.target.value })
        }
      />
      <TextField
        id="standard-basic"
        label="Search"
        value={search.search}
        onChange={(event) =>
          setsearch({ ...search, search: event.target.value })
        }
      />
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="status"
          value={search?.status}
          onChange={(event) =>
            setsearch({ ...search, status: event.target.value })
          }
        >
          {OPTIONS?.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary">
        Search
      </Button>
    </form>
  );
}
