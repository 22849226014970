import React, { useState, useEffect, useCallback, useRef } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";
import moment from "moment";

// components
import PageTitle from "@/components/PageTitle/PageTitle";
import Widget from "@/components/Widget/Widget";

// data
import Filter from "./Filter";
import { Button, IconButton, Tooltip, Box } from "@material-ui/core";
import { NotificationsActive } from "@material-ui/icons";
import DataTable from "@/components/Table/Table";
import { useParams } from "react-router";
import {
  getListVms,
  importMachineEmail,
  importMachineProxies,
  importMachineChangePass,
  importMachineLogoutGmail,
  logoutFuncApi,
  // changePassApi,
  setRecreateApi,
  setCommandApi,
  setMachineIpApi,
  setTurnOffApi,
  setStreaming,
  unsetStreaming,
  importMachineBSize,
  setVerifyMailApi,
  setRegMailApi,
  setDeleteViewHistoryApi,
  // setRegFacebookApi,
  setChangeInfolApi,
  importMachineImportPremium,
  importMachineImportUserAgent,
  setPremiumApi,
  setIgnoreApi,
  setUnignoreApi,
  unsetRegMailApi,
  setChangeAllInfoApi,
  importMachineAndroidInfo,
} from "../../../../../apis/machine";
import {
  hasPermission,
  sleep,
  useInterval,
} from "../../../../../helpers/utils";
import ButtonLoading from "../../../../../components/ButtonLoading/ButtonLoading";
import { Link } from "react-router-dom";
import DialogStream from "./DialogStream";
import DialogSecretKeys from "./DialogSecretKeys";
import DialogOtp from "./DialogOtp";

const axios = require("axios");
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  name: "",
  status: "",
};

export default function VMSList() {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [vms, setVms] = useState([]);
  const [filter, setFilter] = useState(defaultParams);
  const [selectedRow, setSelectedRow] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dataDownload, setDataDownload] = useState([]);
  const btnRef = useRef(null);
  const [openDialogStream, setOpenDialogStream] = useState(false);
  const [streamSubMachine, setStreamSubMachine] = useState("");

  const [subMachine, setSubMachine] = useState({});
  const [openSecretKeysDialog, setOpenSecretKeysDialog] = useState(false);
  const [openOtpDialog, setOpenOtpDialog] = useState(false);

  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory();

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  const listData = async () => {
    const list = await getListVms(filter, id);
    setVms(list?.data);
    setTotal(list?.total);
  };
  useEffect(() => {
    listData();

    const timer1 = setInterval(async () => {
      await listData();
    }, 15 * 1000);

    return () => {
      clearInterval(timer1);
    };
  }, [filter, id]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    let plainText = ``;
    dataDownload.map((item) => {
      plainText += `${moment
        .unix(item.created_at)
        .format("DD-MM-YYYY HH:MM")}\t${item.machine_name}\t${item.name}\t${
        item.email
      }\t${item.pass}\t${item.backup_email}\t${item.b_size}\t${
        item.login_status
      }\t${item.login_failed_reason}\t${item.change_pass_status}\t${
        item.proxy
      }\t${item.user_agent}\t${item.acc_otp_secret}\t${item.acc_secret_keys}\n`;
      return item;
    });
    const file = new Blob([plainText], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${id}.txt`;
    // element.download = `vms.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  async function getUserData(page, limit) {
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/u-apis/machines/${id}/vms?page=${page}&limit=${limit}`,
    );
  }

  const handleDownload = async () => {
    setBtnLoading(true);
    const limit = 100;
    const count = Math.ceil(total / limit);
    const promises = [...Array(count)].map(
      async (pr, i) => await getUserData(i + 1, limit),
    );
    const all = await Promise.all(promises);
    let data = [];
    all.map((item) => {
      data = [...data, ...item.data.data];
      return item;
    });
    await sleep(1000);
    setBtnLoading(false);
    setDataDownload(data);
    btnRef?.current?.click();
    console.log(btnRef);
    return data;
  };

  function openImportEmail() {
    document?.getElementById("fileidEmail")?.click();
  }
  const onFileChangeEmail = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        let listIps = [];
        for (var line = 0; line < lines.length; line++) {
          const stringArray = lines[line].replace(/\t/g, " ").split(" ");
          const item = {
            sub_machine: stringArray[0],
            email: stringArray[1],
            pass: stringArray[2] || "",
            backup_email: stringArray[3] || "",
          };
          listIps.push(item);
        }
        await importMachineEmail(
          {
            data: listIps,
          },
          id,
        );

        await listData();
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  function openImportProxies() {
    document?.getElementById("fileidProxies")?.click();
  }
  const onFileChangeProxies = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        await importMachineProxies(
          {
            data: lines?.map((pr) => pr?.trim()),
          },
          id,
        );
        await listData();
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  function openImportUserAgent() {
    document?.getElementById("fileidUserAgent")?.click();
  }
  const onFileChangeUserAgent = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        await importMachineImportUserAgent(
          {
            data: lines?.map((pr) => pr?.trim()),
          },
          id,
        );
        await listData();
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  function openImportBSize() {
    document?.getElementById("fileidBSize")?.click();
  }
  const onFileChangeBSize = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        await importMachineBSize(
          {
            data: lines?.map((pr) => pr?.trim()),
          },
          id,
        );
        await listData();
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  function openImportAndroidInfo() {
    document?.getElementById("fileidAndroidInfo")?.click();
  }
  const onFileChangeAndroidInfo = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        await importMachineAndroidInfo(
          {
            data: lines?.map((pr) => pr?.trim()),
          },
          id,
        );
        await listData();
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  function openImportChangePass() {
    document?.getElementById("fileidChangePass")?.click();
  }
  const onFileChangePass = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        await importMachineChangePass(
          {
            data: lines?.map((pr) => pr?.trim()),
          },
          id,
        );
        await listData();
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  const onFileImportPremium = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        let data = [];
        for (var line = 0; line < lines.length; line++) {
          const stringArray = lines[line].replace(/\t/g, " ").split(" ");
          const item = {
            sub_machine: stringArray[0],
            premium_info: stringArray[1],
          };
          data.push(item);
        }
        await importMachineImportPremium(
          {
            data: data,
          },
          id,
        );
        await listData();
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  const onFileLogoutGmail = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        await importMachineLogoutGmail(
          {
            data: lines?.map((pr) => pr?.trim()),
          },
          id,
        );
        await listData();
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  const handleColor = (status) => {
    if (status === "LIVE" || status === "SUCCESS") {
      return "green";
    }
    if (status === "DEAD" || status === "FAILED") {
      return "red";
    }
    if (status === "PROCESSING") {
      return "orange";
    }

    return "grey";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };

  const handleLoginColor = (status) => {
    if (status === "LIVE" || status === "SUCCESS") {
      return "green";
    }
    if (status === "DEAD" || status === "FAILED") {
      return "red";
    }
    if (status === "PROCESSING") {
      return "orange";
    }

    return "grey";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };
  const handleIpColor = (is_machine_ip) => {
    if (is_machine_ip) {
      return "#CC9900";
    }

    return "white";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };
  const handleVerifyEmailColor = (is_verify_mail) => {
    if (is_verify_mail) {
      return "#CC9900";
    }

    return "white";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };
  const handleIgnoreColor = (ignore) => {
    if (ignore) {
      return "grey";
    }

    return "white";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };
  const handleOtpColor = (otp) => {
    if (otp) {
      return "primary";
    }

    return "grey";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };

  const logoutFunc = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await logoutFuncApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  // const changePass = async () => {
  //   const seletedItems = vms.filter(item => selectedRow.includes(item.id))
  //   const data = {
  //     data: seletedItems.map(item => item?.name)
  //   }
  //   await changePassApi(data, id);
  //   const list = await getListVms(filter, id)
  //   setTotal(list?.total)
  //   setVms(list?.data)
  // }
  const setRecreate = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setRecreateApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };
  const setMachineIp = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setMachineIpApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };
  const setVerifyMail = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setVerifyMailApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };
  const setChangeInfo = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setChangeInfolApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };
  const setChangeAllInfo = async () => {
    await setChangeAllInfoApi(id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  const setIgnore = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setIgnoreApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  const setUnignore = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setUnignoreApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  const setRegMail = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setRegMailApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };
  const unsetRegMail = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await unsetRegMailApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };
  const setNoCommand = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      command: "",
      data: seletedItems.map((item) => item?.name),
    };
    await setCommandApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  const setPremium = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setPremiumApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };
  const setTurnOff = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setTurnOffApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  // const setRegFacebook = async () => {
  //   const seletedItems = vms.filter(item => selectedRow.includes(item.id))
  //   const data = {
  //     data: seletedItems.map(item => item?.name)
  //   }
  //   await setRegFacebookApi(data, id);
  //   const list = await getListVms(filter, id)
  //   setTotal(list?.total)
  //   setVms(list?.data)
  // }

  const setDeleteViewHistory = async () => {
    const seletedItems = vms.filter((item) => selectedRow.includes(item.id));
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    await setDeleteViewHistoryApi(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  // ============== Streaming =============================
  const vmsSetStreaming = async (data) => {
    await setStreaming(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  const vmUnsetStreaming = async (data) => {
    await unsetStreaming(data, id);
    const list = await getListVms(filter, id);
    setTotal(list?.total);
    setVms(list?.data);
  };

  var displayColumns = [];
  // stt
  displayColumns.push({
    field: "stt",
    headerName: "stt",
    width: 30,
    editable: false,
    sortable: false,
  });
  // name, machine
  displayColumns.push({
    field: "machine_name",
    headerName: "vm",
    flex: 0.5,
    editable: false,
    renderCell: (params) => {
      return (
        <div
          style={{
            lineHeight: "normal",
            backgroundColor: handleIgnoreColor(params?.row?.ignore),
          }}
        >
          <div style={{ lineHeight: "normal" }}>
            <strong>{params?.row?.name}</strong> /{" "}
            <strong>{params?.row?.machine_name}</strong>
          </div>
          <div style={{ lineHeight: "normal !important" }}>
            {params?.row?.dir}
          </div>
          <div style={{ lineHeight: "normal !important" }}>
            Status: {params?.row?.status}
          </div>
          <div style={{ lineHeight: "normal !important" }}>
            Counter: {params?.row?.counter}
          </div>
        </div>
      );
    },
  });
  // proxy, ua, b_size
  displayColumns.push({
    field: "proxy",
    headerName: "info",
    flex: 0.4,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ lineHeight: "normal" }}>
          <div
            style={{
              lineHeight: "normal !important",
              backgroundColor: handleIpColor(params?.row?.is_machine_ip),
            }}
          >
            {" "}
            {params?.row?.proxy}
          </div>
          <div style={{ lineHeight: "normal !important" }}>
            {params?.row?.user_agent}
          </div>
          <div style={{ lineHeight: "normal !important" }}>
            bs: {params?.row?.b_size}
          </div>
        </div>
      );
    },
  });
  // email, pass, email_backup
  displayColumns.push({
    field: "email",
    headerName: "email",
    width: 120,
    flex: 0.3,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ lineHeight: "normal" }}>
          <div
            style={{
              lineHeight: "normal !important",
              backgroundColor: handleVerifyEmailColor(
                params?.row?.is_verify_mail,
              ),
            }}
          >
            {" "}
            {params?.row?.email}
          </div>
          <div>{params?.row?.pass}</div>
          <div>{params?.row?.backup_email}</div>
          <div
            style={{
              lineHeight: "normal !important",
              backgroundColor: handleVerifyEmailColor(
                params?.row?.is_change_info,
              ),
            }}
          >
            {" "}
            change_info: {params?.row?.is_change_info}
          </div>
          <div style={{ lineHeight: "normal !important" }}>
            status:{" "}
            <strong
              style={{
                backgroundColor: handleColor(params?.row?.email_status),
                color: "#fff",
                padding: 3,
              }}
            >
              {params?.row?.email_status}
            </strong>
          </div>
        </div>
      );
    },
  });
  // login status
  displayColumns.push({
    field: "login_status",
    headerName: "login_status",
    width: 120,
    flex: 0.4,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      var elm = (
        <div style={{ lineHeight: "normal" }}>
          <div style={{ lineHeight: "normal !important" }}>
            <strong
              style={{
                backgroundColor: handleColor(params?.row?.login_status),
                color: "#fff",
                padding: 3,
              }}
            >
              {params?.row?.login_status}
            </strong>
          </div>
          <br />
          <div style={{ lineHeight: "normal !important" }}>
            reg_mail:{" "}
            <strong
              style={{
                backgroundColor: handleVerifyEmailColor(
                  params?.row?.is_reg_mail,
                ),
                color: "#fff",
                padding: 3,
              }}
            >
              {params?.row?.is_reg_mail ? "REGMAIL" : ""}
            </strong>
          </div>
          {/* <div style={{ lineHeight: 'normal !important' }}>mail_disable: <strong style={{ backgroundColor: handleVerifyEmailColor(params?.row?.mail_disable), color: '#fff', padding: 3 }}>{params?.row?.mail_disable ? "DISABLE" : ""}</strong></div> */}
          {/* <div style={{ lineHeight: 'normal !important' }}>reg_facebook: <strong style={{ backgroundColor: handleVerifyEmailColor(params?.row?.regfb_status), color: '#fff', padding: 3 }}>{params?.row?.regfb_status}</strong></div> */}

          {!hasPermission(["machines-vms-show_other_info"]) ? (
            <></>
          ) : (
            <>
              <div style={{ lineHeight: "normal !important" }}>
                reddit:{" "}
                <strong
                  style={{
                    backgroundColor: handleLoginColor(
                      params?.row?.login_reddit_status,
                    ),
                    color: "#fff",
                    padding: 3,
                  }}
                >
                  {params?.row?.login_reddit_status}
                </strong>
              </div>
              <div style={{ lineHeight: "normal !important" }}>
                tiktok:{" "}
                <strong
                  style={{
                    backgroundColor: handleLoginColor(
                      params?.row?.login_tiktok_status,
                    ),
                    color: "#fff",
                    padding: 3,
                  }}
                >
                  {params?.row?.login_tiktok_status}
                </strong>
              </div>
              <div style={{ lineHeight: "normal !important" }}>
                gitlab:{" "}
                <strong
                  style={{
                    backgroundColor: handleLoginColor(
                      params?.row?.login_gitlab_status,
                    ),
                    color: "#fff",
                    padding: 3,
                  }}
                >
                  {params?.row?.login_gitlab_status}
                </strong>
              </div>
              <div style={{ lineHeight: "normal !important" }}>
                medium:{" "}
                <strong
                  style={{
                    backgroundColor: handleLoginColor(
                      params?.row?.login_medium_status,
                    ),
                    color: "#fff",
                    padding: 3,
                  }}
                >
                  {params?.row?.login_medium_status}
                </strong>
              </div>
              <div style={{ lineHeight: "normal !important" }}>
                linkedin:{" "}
                <strong
                  style={{
                    backgroundColor: handleLoginColor(
                      params?.row?.login_linkedin_status,
                    ),
                    color: "#fff",
                    padding: 3,
                  }}
                >
                  {params?.row?.login_linkedin_status}
                </strong>
              </div>
            </>
          )}
        </div>
      );

      return elm;
    },
  });
  // login message
  displayColumns.push({
    field: "login_message",
    headerName: "log msg",
    width: 120,
    flex: 0.4,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ lineHeight: "normal" }}>
          <div>{params?.row?.login_failed_reason}</div>
          {/* <div style={{ lineHeight: 'normal !important' }}>mail_disable: <strong style={{ backgroundColor: handleVerifyEmailColor(params?.row?.mail_disable), color: '#fff', padding: 3 }}>{params?.row?.mail_disable ? "DISABLE" : ""}</strong></div> */}
          {/* <div style={{ lineHeight: 'normal !important' }}>reg_facebook: <strong style={{ backgroundColor: handleVerifyEmailColor(params?.row?.regfb_status), color: '#fff', padding: 3 }}>{params?.row?.regfb_status}</strong></div> */}

          {!hasPermission(["machines-vms-show_other_info"]) ? (
            <></>
          ) : (
            <>
              <div style={{ lineHeight: "normal !important" }}>
                reddit: <strong>{params?.row?.login_reddit_message}</strong>
              </div>
              <div style={{ lineHeight: "normal !important" }}>
                tiktok: <strong>{params?.row?.login_tiktok_message}</strong>
              </div>
              <div style={{ lineHeight: "normal !important" }}>
                gitlab: <strong>{params?.row?.login_gitlab_message}</strong>
              </div>
              <div style={{ lineHeight: "normal !important" }}>
                medium: <strong>{params?.row?.login_medium_message}</strong>
              </div>
              <div style={{ lineHeight: "normal !important" }}>
                linkedin: <strong>{params?.row?.login_linkedin_message}</strong>
              </div>
            </>
          )}
        </div>
      );
    },
  });
  // change pass status
  displayColumns.push({
    field: "change_pass_status",
    headerName: "change_pass_status",
    width: 120,
    flex: 0.3,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ lineHeight: "normal" }}>
          <div style={{ lineHeight: "normal !important" }}>
            <strong
              style={{
                backgroundColor: handleColor(params?.row?.change_pass_status),
                color: "#fff",
                padding: 3,
              }}
            >
              {params?.row?.change_pass_status}
            </strong>
          </div>
        </div>
      );
    },
  });
  // logout status
  displayColumns.push({
    field: "logout_status",
    headerName: "logout_status",
    width: 120,
    flex: 0.3,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ lineHeight: "normal" }}>
          <div style={{ lineHeight: "normal !important" }}>
            <strong
              style={{
                backgroundColor: handleColor(params?.row?.logout_status),
                color: "#fff",
                padding: 3,
              }}
            >
              {params?.row?.logout_status}
            </strong>
          </div>
        </div>
      );
    },
  });
  // script name
  displayColumns.push({
    field: "script_name",
    headerName: "scenarios",
    width: 120,
    flex: 0.3,
    editable: false,
    sortable: false,
  });
  // // // premium info
  // // displayColumns.push({
  // //   field: 'premium_info', headerName: 'premium_info', width: 120,
  // //   flex: 0.3,
  // //   editable: false,
  // //   sortable: false,
  // // })
  // // reg_premium_status
  // displayColumns.push({
  //   field: 'reg_premium_status', headerName: 'reg_premium_status', width: 120,
  //   flex: 0.3,
  //   editable: false,
  //   sortable: false,
  //   renderCell: (params) => {
  //     return (
  //       <div style={{ lineHeight: 'normal' }}>
  //         <div style={{ lineHeight: 'normal !important' }}><strong style={{ backgroundColor: handleColor(params?.row?.reg_premium_status), color: '#fff', padding: 3 }}>{params?.row?.reg_premium_status}</strong></div>
  //         <div style={{ lineHeight: 'normal !important' }}>{params?.row?.reg_premium_message}</div>
  //       </div>
  //     )
  //   }
  // })
  // vnc
  displayColumns.push({
    field: "vnc",
    headerName: "vnc",
    width: 120,
    flex: 0.3,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ lineHeight: "normal" }}>
          <Link href={`http://${params?.row?.vnc}`} target="_blank">
            {" "}
            {params?.row?.vnc}
          </Link>
        </div>
      );
    },
  });

  // streaming
  if (hasPermission(["machines-vms-set_streaming"])) {
    displayColumns.push({
      field: "is_streaming",
      headerName: "stream",
      width: 120,
      flex: 0.2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="stream" aria-label="add">
              <IconButton>
                <NotificationsActive
                  style={{ color: params?.row?.is_streaming ? "blue" : "" }}
                  onClick={async () => {
                    if (!params?.row?.is_streaming) {
                      await vmsSetStreaming({ data: [params?.row?.name] });
                      setStreamSubMachine(params?.row?.name);
                      setOpenDialogStream(true);
                    } else {
                      await vmUnsetStreaming({ data: [params?.row?.name] });
                    }
                    // await handleUpdateNotifiPerformance({ machine_id: params.row.id, noti_performance: !params.row.noti_performance })
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    });
  }
  // dker_command
  displayColumns.push({
    field: "dker_command",
    headerName: "CMD",
    width: 120,
    flex: 0.25,
    editable: false,
  });
  // otp
  displayColumns.push({
    field: "otp",
    headerName: "otp",
    width: 120,
    flex: 0.2,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          <Button
            onClick={async () => {
              setSubMachine(params?.row);
              setOpenOtpDialog(true);
            }}
            style={{
              height: 30,
              width: 100,
              marginRight: 10,
              display: "display",
            }}
            variant="contained"
            color={handleOtpColor(params?.row?.acc_otp_secret)}
          >
            OTP
          </Button>
        </>
      );
    },
  });
  // secret keys
  displayColumns.push({
    field: "secret_keys",
    headerName: "secret_keys",
    width: 120,
    flex: 0.2,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          <Button
            onClick={async () => {
              setSubMachine(params?.row);
              setOpenSecretKeysDialog(true);
            }}
            style={{
              height: 30,
              width: 100,
              marginRight: 10,
              display: "display",
            }}
            variant="contained"
            color={handleOtpColor(params?.row?.acc_secret_keys)}
          >
            SKeys
          </Button>
        </>
      );
    },
  });

  var menus = [];
  if (hasPermission(["machines-vms-delete_view_history"])) {
    menus.push({
      key: "DeleteView",
      name: "DeleteView",
      cp: (
        <MenuItem onClick={setDeleteViewHistory}>
          <Typography>DeleteView</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-set_machine_ip"])) {
    menus.push({
      key: "SetMachineIp",
      name: "SetMachineIp",
      cp: (
        <MenuItem onClick={setMachineIp}>
          <Typography>SetMachineIp</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-set_verify_mail"])) {
    menus.push({
      key: "SetVerifyMail",
      name: "SetVerifyMail",
      cp: (
        <MenuItem onClick={setVerifyMail}>
          <Typography>SetVerifyMail</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-set_reg_mail"])) {
    menus.push({
      key: "SetRegMail",
      name: "SetRegMail",
      cp: (
        <MenuItem onClick={setRegMail}>
          <Typography>SetRegMail</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-unset_reg_mail"])) {
    menus.push({
      key: "UnsetRegMail",
      name: "UnsetRegMail",
      cp: (
        <MenuItem onClick={unsetRegMail}>
          <Typography>UnsetRegMail</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-turnoff"])) {
    menus.push({
      key: "turnoff",
      name: "turnoff",
      cp: (
        <MenuItem onClick={setTurnOff}>
          <Typography>Turnoff</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-set_change_info"])) {
    menus.push({
      key: "SetChangeInfo",
      name: "SetChangeInfo",
      cp: (
        <MenuItem onClick={setChangeInfo}>
          <Typography>SetChangeInfo</Typography>
        </MenuItem>
      ),
    });
    menus.push({
      key: "SetChangeAllInfo",
      name: "SetChangeAllInfo",
      cp: (
        <MenuItem onClick={setChangeAllInfo}>
          <Typography>SetChangeAllInfo</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-premium_start"])) {
    menus.push({
      key: "SetPremium",
      name: "SetPremium",
      cp: (
        <MenuItem onClick={setPremium}>
          <Typography>SetPremium</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-set_ignore"])) {
    menus.push({
      key: "Ignore",
      name: "Ignore",
      cp: (
        <MenuItem onClick={setIgnore}>
          <Typography>Ignore</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-set_unignore"])) {
    menus.push({
      key: "Unignore",
      name: "Unignore",
      cp: (
        <MenuItem onClick={setUnignore}>
          <Typography>Unignore</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-logout_gmail"])) {
    menus.push({
      key: "logout_gmail",
      name: "logout_gmail",
      cp: (
        <MenuItem onClick={logoutFunc}>
          <Typography>logout_gmail</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-set_command"])) {
    menus.push({
      key: "NoCommand",
      name: "NoCommand",
      cp: (
        <MenuItem onClick={setNoCommand}>
          <Typography>NoCommand</Typography>
        </MenuItem>
      ),
    });
  }
  if (hasPermission(["machines-vms-recreate"])) {
    menus.push({
      key: "Recreate",
      name: "Recreate",
      cp: (
        <MenuItem onClick={setRecreate}>
          <Typography>Recreate</Typography>
        </MenuItem>
      ),
    });
  }

  // console.log(openDialogStream)
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Machine VMS" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Filter filter={filter} setFilter={setFilterToParams} />
            <did>
              {/* <Button onClick={openImportUserAgent} style={{ height: 48, width: 100, marginLeft: 10, display: hasPermission(['machines-vms-import_user_agents']) ? 'display' : 'none' }} variant="contained" color="primary">Import UserAgent</Button> */}
              <Button
                onClick={openImportEmail}
                style={{
                  height: 48,
                  width: 100,
                  marginLeft: 10,
                  display: hasPermission(["machines-vms-import_emails"])
                    ? "display"
                    : "none",
                }}
                variant="contained"
                color="primary"
              >
                Import Email
              </Button>
              <Button
                onClick={openImportProxies}
                style={{
                  height: 48,
                  width: 100,
                  marginLeft: 10,
                  display: hasPermission(["machines-vms-import_proxies"])
                    ? "display"
                    : "none",
                }}
                variant="contained"
                color="primary"
              >
                Import Proxies
              </Button>
              <Button
                onClick={openImportAndroidInfo}
                style={{
                  height: 48,
                  width: 100,
                  marginLeft: 10,
                  display: hasPermission(["machines-vms-import_proxies"])
                    ? "display"
                    : "none",
                }}
                variant="contained"
                color="primary"
              >
                Android Info
              </Button>
              {/* <Button onClick={openImportBSize} style={{ height: 48, width: 100, marginLeft: 10, display: hasPermission(['machines-vms-import_bsize']) ? 'display' : 'none' }} variant="contained" color="primary">Import Bsize</Button> */}
              {/* <Button onClick={openImportChangePass} style={{ height: 48, width: 100, marginLeft: 10, display: hasPermission(['machines-vms-change_pass']) ? 'display' : 'none' }} variant="contained" color="primary">Change Pass</Button> */}
              {/* <Button onClick={openImportPremium} style={{ height: 48, width: 100, marginLeft: 10, display: hasPermission(['machines-vms-import_premium']) ? 'display' : 'none' }} variant="contained" color="primary">Premium</Button> */}
              {/* <Button onClick={openLogoutGmail} style={{ height: 48, width: 100, marginLeft: 10, display: hasPermission(['machines-vms-export']) ? 'display' : 'none' }} variant="contained" color="primary">Logout</Button> */}
              <ButtonLoading
                loading={btnLoading}
                onClick={handleDownload}
                style={{
                  height: 48,
                  width: 100,
                  marginLeft: 10,
                  display: hasPermission(["machines-vms-export"])
                    ? "display"
                    : "none",
                }}
                variant="contained"
                color="primary"
              >
                {" "}
                Export
              </ButtonLoading>
              <Button
                ref={btnRef}
                onClick={downloadTxtFile}
                style={{
                  height: 48,
                  width: 100,
                  marginLeft: 10,
                  display: "none",
                }}
                variant="contained"
                color="primary"
              ></Button>
            </did>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexGrow="1">
            {/* <div style={{ width: 180, marginRight: 20 }}>
              <MenuLink id={id} />
            </div> */}
            <div style={{ width: "100%" }}>
              <Widget
                title="VMS List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
                menus={menus}
              >
                <DataTable
                  total={total}
                  columns={displayColumns}
                  data={vms?.map((user, i) => ({
                    ...user,
                    stt: i + 1 + (filter?.page - 1) * filter?.limit,
                  }))}
                  loading={loading}
                  setSelectedRow={setSelectedRow}
                  filter={filter}
                  setFilter={setFilterToParams}
                  rowHeight={150}
                />
              </Widget>
            </div>
          </Box>
        </Grid>
      </Grid>

      {openDialogStream && (
        <DialogStream
          openDialogStream={openDialogStream}
          onClose={async () => {
            setOpenDialogStream(false);
            await vmUnsetStreaming({ data: [streamSubMachine] });
          }}
          machine={id}
          subMachine={streamSubMachine}
        />
      )}

      {openSecretKeysDialog && (
        <DialogSecretKeys
          openDialog={openSecretKeysDialog}
          onClose={async () => {
            setOpenSecretKeysDialog(false);
            await listData();
          }}
          subMachine={subMachine}
        />
      )}

      {openOtpDialog && (
        <DialogOtp
          openDialog={openOtpDialog}
          onClose={async () => {
            setOpenOtpDialog(false);
            await listData();
          }}
          subMachine={subMachine}
        />
      )}
      <input onChange={onFileChangeEmail} id="fileidEmail" type="file" hidden />
      <input
        onChange={onFileChangeProxies}
        id="fileidProxies"
        type="file"
        hidden
      />
      <input
        onChange={onFileChangeUserAgent}
        id="fileidUserAgent"
        type="file"
        hidden
      />
      <input onChange={onFileChangeBSize} id="fileidBSize" type="file" hidden />
      <input
        onChange={onFileChangeAndroidInfo}
        id="fileidAndroidInfo"
        type="file"
        hidden
      />
      <input
        onChange={onFileChangePass}
        id="fileidChangePass"
        type="file"
        hidden
      />
      <input
        onChange={onFileLogoutGmail}
        id="fileidLogoutGmail"
        type="file"
        hidden
      />
      <input
        onChange={onFileImportPremium}
        id="fileImportPremium"
        type="file"
        hidden
      />
    </>
  );
}
