import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function FormCreateSmsV2({ open, onClose, onSubmitForm, currentItem }) {
    const classes = useStyles();
    const [ncc, setNcc] = React.useState(currentItem?.ncc || '');
    const [serviceCode, setServiceCode] = React.useState(currentItem?.service_code || '');
    const [countryCode, setCountryCode] = React.useState(currentItem?.country_code || '');
    const [apiKey, setApiKey] = React.useState(currentItem?.api_key || '');
    const [status, setStatus] = React.useState(currentItem?.status || '');

    const handleSubmit = () => {
        const data = {
            "ncc": ncc,
            "service_code": serviceCode,
            "country_code": countryCode,
            "api_key": apiKey,
            "status": status,
        }
        onSubmitForm(data)
    }

    return (
        <div>
            <form>
                <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create sms</DialogTitle>
                    <DialogContent>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                ncc
                            </InputLabel>
                            <Select
                                value={ncc}
                                onChange={(event) => setNcc(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="SMS_ONLINE">
                                    <em>SMS_ONLINE</em>
                                </MenuItem>
                                {[
                                    { name: "SMS_ONLINE_V1" },
                                    { name: "CODE_SIM" },
                                    { name: "OTP_SMS" },
                                    { name: "FIVE_SIM_NET" },
                                    { name: "LUCKY_WIND" },
                                    { name: "HUU_VAN" },
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            id="service_code"
                            name="service_code"
                            label="service_code"
                            value={serviceCode}
                            onChange={(event) => setServiceCode(event.target.value)}
                        />
                        <TextField
                            fullWidth
                            id="country_code"
                            name="country_code"
                            label="country code"
                            value={countryCode}
                            onChange={(event) => setCountryCode(event.target.value)}
                        />
                        <TextField
                            fullWidth
                            id="api_key"
                            name="api_key"
                            label="api_key"
                            value={apiKey}
                            onChange={(event) => setApiKey(event.target.value)}
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                status
                            </InputLabel>
                            <Select
                                value={status}
                                onChange={(event) => setStatus(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="ENABLE">
                                    <em>ENABLE</em>
                                </MenuItem>
                                {
                                    [{ name: "DISABLE" },
                                    ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}