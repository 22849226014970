import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars({ open = false, message = '', severity = 'success' }) {
    const [openDialog, setOpenDialog] = React.useState(open);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDialog(false);
    };
    return (
        <div>
            <Snackbar open={openDialog} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message || 'Nothing here, blank message !!'}
                </Alert>
            </Snackbar>
        </div>
    );
}