import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router";

// components
import PageTitle from "@/components/PageTitle/PageTitle";

// data
import { useParams } from "react-router";
import { getPackageSetting, updatePackageSetting } from "../../../../apis/packages";

export default function PackageSetting() {
    const { id } = useParams()
    const [setting, setSetting] = useState(undefined);
    const history = useHistory()

    const handleChange = (event) => {
        setSetting({ ...setting, is_running: event.target.checked })
    };

    useEffect(() => {
        (async () => {
            const data = await getPackageSetting(id)
            setSetting(data)
        })();
    }, [id]);

    const handleUpdateSetting = async (data) => {
        const dataResp = await updatePackageSetting(id, data)
        setSetting(dataResp)
    }
    const goBack = () => {
        history.push(`/app/package/${id}/video`)
    }

    
    return (
        <>
            <PageTitle title="Setting Package" />
            <Grid container spacing={12}>
                {setting && <div style={{ padding: 30, marginTop: 30, backgroundColor: '#fff', borderRadius: 8, width: '100%' }} >
                    <FormControlLabel
                        control={<Switch checked={setting.is_running} onChange={handleChange} />}
                        label="Is running"
                    />
                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="duration_repeat (ngày)" value={setting?.duration_repeat} onChange={(event) => setSetting({ ...setting, duration_repeat: event.target.value })} />

                    {/* <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_search (%)" value={setting?.threshold_view_search} onChange={(event) => setSetting({ ...setting, threshold_view_search: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_home (%)" value={setting?.threshold_view_home} onChange={(event) => setSetting({ ...setting, threshold_view_home: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_home_channel (%)" value={setting?.threshold_view_home_channel} onChange={(event) => setSetting({ ...setting, threshold_view_home_channel: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_suggest (%)" value={setting?.threshold_view_suggest} onChange={(event) => setSetting({ ...setting, threshold_view_suggest: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_plalist (%)" value={setting?.threshold_view_plalist} onChange={(event) => setSetting({ ...setting, threshold_view_plalist: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_notification (%)" value={setting?.threshold_view_notification} onChange={(event) => setSetting({ ...setting, threshold_view_notification: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_hashtag (%)" value={setting?.threshold_view_hashtag} onChange={(event) => setSetting({ ...setting, threshold_view_hashtag: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_end_screen (%)" value={setting?.threshold_view_end_screen} onChange={(event) => setSetting({ ...setting, threshold_view_end_screen: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="threshold_view_other (%)" value={setting?.threshold_view_other} onChange={(event) => setSetting({ ...setting, threshold_view_other: event.target.value })} /> */}

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="Số lần phân phối ads" value={setting?.ads_distribution_times} onChange={(event) => setSetting({ ...setting, ads_distribution_times: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="Timeout của máy (giây)" value={setting?.sub_timeout_duration} onChange={(event) => setSetting({ ...setting, sub_timeout_duration: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="Thời gian nghỉ của máy (giờ)" value={setting?.break_duration} onChange={(event) => setSetting({ ...setting, break_duration: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="Thời gian giữa 2 lần sub 1 kênh (giây)" value={setting?.duration_to_sub} onChange={(event) => setSetting({ ...setting, duration_to_sub: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="Thời gian giữa 2 lần like 1 kênh (giây)" value={setting?.duration_to_like} onChange={(event) => setSetting({ ...setting, duration_to_like: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="Số kịch bản của 1 phiên" value={setting?.script_number} onChange={(event) => setSetting({ ...setting, script_number: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="Thời lượng video min (%)" value={setting?.watching_min} onChange={(event) => setSetting({ ...setting, watching_min: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="Thời lượng video max (%)" value={setting?.watching_max} onChange={(event) => setSetting({ ...setting, watching_max: event.target.value })} />

                    <div style={{ marginTop: 30 }} />
                    <TextField style={{ backgroundColor: '#fff', width: '30%' }} InputLabelProps={{
                        shrink: true,
                    }} id="standard-basic" type="number" label="(WEB)Tỉ lệ click ads/views (%)" value={setting?.ads_click_per_views} onChange={(event) => setSetting({ ...setting, ads_click_per_views: event.target.value })} />


                    <div style={{ marginTop: 30 }}>
                        <Button onClick={goBack} type="submit" variant="contained" color="secondary">Cancel</Button>
                        <Button disabled={!setting?.sub_timeout_duration} onClick={() => handleUpdateSetting({
                            is_running: setting.is_running,

                            duration_repeat: parseInt(setting.duration_repeat),

                            threshold_view_search: parseInt(setting.threshold_view_search),
                            threshold_view_home: parseInt(setting.threshold_view_home),
                            threshold_view_home_channel: parseInt(setting.threshold_view_home_channel),
                            threshold_view_suggest: parseInt(setting.threshold_view_suggest),
                            threshold_view_plalist: parseInt(setting.threshold_view_plalist),
                            threshold_view_notification: parseInt(setting.threshold_view_notification),
                            threshold_view_hashtag: parseInt(setting.threshold_view_hashtag),
                            threshold_view_end_screen: parseInt(setting.threshold_view_end_screen),
                            threshold_view_other: parseInt(setting.threshold_view_other),

                            ads_distribution_times: parseInt(setting.ads_distribution_times),

                            sub_timeout_duration: parseInt(setting.sub_timeout_duration),
                            break_duration: parseInt(setting.break_duration),
                            script_number: parseInt(setting.script_number),

                            duration_to_sub: parseInt(setting.duration_to_sub),
                            duration_to_like: parseInt(setting.duration_to_like),

                            watching_min: parseInt(setting.watching_min),
                            watching_max: parseInt(setting.watching_max),


                            ads_click_per_views: parseInt(setting.ads_click_per_views),

                        })} style={{ marginLeft: 30 }} type="submit" variant="contained" color="primary">Save</Button>
                    </div>
                </div>}
            </Grid>
        </>
    );
}
