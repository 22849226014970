import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "@/components/PageTitle/PageTitle";
import Widget from "@/components/Widget/Widget";

// data
import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "@/components/Table/Table";
import DialogConfirm from "@/components/DialogConfirm/DialogConfirm";
import { useParams } from "react-router";
import DialogCreatePlayList from "./CreatePlayList";
import {
  createPackagePlayList,
  deleteAllPackagePlayList,
  deletePackagePlayList,
  getListPackagePlayList,
  updatePackagePlayList
}
  from "../../../../../apis/packages";
import { ShowForPermission } from "../../../../../components/Permission/Permission";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, name: '', status: ''
}
const defaultItem = {
  yt_elm_id: "",
  yt_elm_keyword: "",
  user_id: "",
  keywords: [],
  duration: 120
}
let timer
export default function PackagePlayList() {
  const classes = useStyles();
  const { id } = useParams()
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [currentPlayList, setCurrentPlayList] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setCurrentPlayList(undefined)
    setOpen(true);
  };

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  const [playlists, setPlayList] = useState([])

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      (async () => {
        setLoading(true);
        const list = await getListPackagePlayList(filter, id)
        setLoading(false);
        setTotal(list?.total)
        setPlayList(list?.data?.map(item => ({ ...item, keywords: item?.keywords?.split("\n") })))
      })();
    }, 300)
  }, [filter, id]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleCreatePackagePlayList = async (data) => {
    const reformatData = data.map(item => {
      return { ...item, duration: parseInt(item.duration, 10), keywords: item?.keywords?.join('\n') }
    })
    await createPackagePlayList({ data: reformatData }, id)
    history.push(`/app/package/${id}/playlist`)
    setOpen(false);
  }

  const handleUpdatePackagePlayList = async (data) => {
    const reformatData = { ...data[0], keywords: data[0].keywords.join('\n'), duration: parseInt(data[0].duration, 10) }
    await updatePackagePlayList(reformatData, id, data[0]?.uid)
    history.push(`/app/package/${id}/playlist`)
    setOpen(false);
  }
  const handleDeletePackagePlayList = async () => {
    await deletePackagePlayList({ uids: selectedRow }, id)
    const list = await getListPackagePlayList(filter, id)
    setPlayList(list?.data?.map(item => ({ ...item, keywords: item?.keywords?.split("\n") })))
    setTotal(list?.total)
    setOpenConfirm(false);
  }
  const handleDeleteAllPackagePlayList = async () => {
    await deleteAllPackagePlayList(id)
    const list = await getListPackagePlayList(filter, id)
    setPlayList(list?.data?.map(item => ({ ...item, keywords: item?.keywords?.split("\n") })))
    setTotal(list?.total)
    setOpenConfirm(false);
  }
  const handleShowConfirm = () => {
    setOpenConfirm(true)
  }

  const handleEdit = (row) => {
    setOpen(true)
    setCurrentPlayList(row)
  }

  function openDialog() {
    document?.getElementById("fileid")?.click();
  }

  const onFileChange = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        let listPll = [];
        for (var line = 0; line < lines.length; line++) {
          const stringArray = lines[line].split("\t");
          const item = {
            yt_elm_id: stringArray[1] || "",
            yt_elm_keyword: stringArray[0] || "",
            duration: parseInt(stringArray[2], 10)
          };
          stringArray[0] && listPll.push(item);
        }
        handleCreatePackagePlayList(listPll);
      };
      reader.readAsText(file);
    }
  };

  const handleExport = () => {
    const element = document.createElement("a");
    let plainText = ``
    playlists.map(item => {
      if (selectedRow.includes(item.uid)) {
        plainText += `${item.yt_elm_keyword}\t${item.yt_elm_id}\t${item.duration}\n`
      }
      return item
    })
    const file = new Blob(
      [plainText],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "playlist.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const columns = [
    { field: 'stt', headerName: 'stt', width: 30, editable: false },
    {
      field: 'yt_elm_keyword',
      headerName: 'name',
      flex: 0.7,
      editable: false,
      sortable: false,
    },
    {
      field: 'yt_elm_id', headerName: 'yt_id', width: 120,
      flex: 0.5,
      editable: false,
      sortable: false,
    },
    {
      field: 'keywords', headerName: 'keywords', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.keywords?.map(key => <Chip
          label={key}
          key={key}
        />)
      }
    },
    {
      field: 'duration', headerName: 'duration', width: 60,
      flex: 0.3,
      editable: false,
    },
    {
      field: 'search_counter', headerName: 'search', width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: 'suggest_counter', headerName: 'suggest', width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: 'home_counter', headerName: 'click', width: 60,
      flex: 0.3,
      editable: false,
    },
    {
      field: 'action', headerName: ' ', width: 120, type: 'number',
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <ShowForPermission permission="packages-yt_playlists-add">
            <IconButton>
              <EditIcon onClick={() => handleEdit(params?.row)} />
            </IconButton>
          </ShowForPermission>
        );
      }
    },
  ];
  return (
    <>
      <PageTitle title="PlayList" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Filter filter={filter} setFilter={setFilterToParams} />
            <did>
              <ShowForPermission permission="packages-yt_playlists-delete"><Button disabled={!selectedRow.length} onClick={() => { handleShowConfirm(); setIsDeleteAll(false) }} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">Delete</Button></ShowForPermission>
              <ShowForPermission permission="packages-yt_playlists-delete"><Button onClick={() => { handleShowConfirm(); setIsDeleteAll(true) }} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">DeleteAll</Button></ShowForPermission>
              <ShowForPermission permission="packages-yt_playlists-delete"><Button disabled={!selectedRow.length} onClick={handleExport} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">Export</Button></ShowForPermission>
              <ShowForPermission permission="packages-yt_playlists-export"><Button onClick={openDialog} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">Import</Button></ShowForPermission>
              <ShowForPermission permission="packages-yt_playlists-add"><Button onClick={handleClickOpen} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">Add</Button></ShowForPermission>
            </did>
          </Box>
        </Grid>
        <Grid item xs={12} >
          <Box display="flex" flexGrow="1">
            {/* <div style={{ width: 180, marginRight: 20 }}>
              <MenuLink id={id} />
            </div> */}
            <div style={{ width: "100%" }} >
              <Widget title="PlayList" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                <DataTable
                  total={total}
                  columns={columns}
                  data={playlists?.map((user, i) => ({ ...user, id: user.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
                  loading={loading}
                  setSelectedRow={setSelectedRow}
                  filter={filter}
                  setFilter={setFilterToParams}
                />
              </Widget>
            </div>
          </Box>
        </Grid>
      </Grid>
      <DialogCreatePlayList
        playlist={currentPlayList ? [currentPlayList] : [defaultItem]}
        open={open} setOpen={setOpen}
        onSubmit={(data) => currentPlayList ? handleUpdatePackagePlayList(data) : handleCreatePackagePlayList(data)}
      />
      <DialogConfirm
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        message={isDeleteAll ? `Are you sure delete all playlists?` : `Are you sure delete selected playlists?`}
        onSubmit={() => { !isDeleteAll ? handleDeletePackagePlayList() : handleDeleteAllPackagePlayList() }}
      />
      <input onChange={onFileChange} id="fileid" type="file" hidden />
    </>
  );
}
