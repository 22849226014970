import React from 'react';
import {
    Button, TextField, Dialog, DialogActions,
    DialogContent, DialogTitle, MenuItem,
    InputLabel, FormControl, Select
} from '@material-ui/core/'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function FormCreateVpnAccount({ open, onClose, onSubmitForm, currentItem }) {
    const classes = useStyles();
    const [userAccount, setUserAccount] = React.useState(currentItem?.user_account || '');
    const [supplier, setSupplier] = React.useState(currentItem?.supplier || '');
    const [countries, setCountries] = React.useState(currentItem?.countries || '');
    const [loginInfo, setLoginInfo] = React.useState(currentItem?.login_info || '');
    const [chooseDuration, setChooseDuration] = React.useState(currentItem?.choose_duration || 0);
    const [maxThreads, setMaxThreads] = React.useState(currentItem?.max_threads || 0);

    const handleSubmit = () => {
        const data = {
            "user_account": userAccount,
            "supplier": supplier,
            "countries": countries,
            "login_info": loginInfo,
            "choose_duration": chooseDuration,
            "max_threads": maxThreads,
        }
        onSubmitForm(data)
    }

    return (
        <div>
            <form>
                <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create</DialogTitle>
                    <DialogContent>

                        <TextField
                            fullWidth
                            id="user_account"
                            name="user_account"
                            label="user_account: dùng để nhận biết account nào"
                            value={userAccount}
                            onChange={(event) => setUserAccount(event.target.value)}
                        />

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                supplier
                            </InputLabel>
                            <Select
                                value={supplier}
                                onChange={(event) => setSupplier(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="nordvpn"> 
                                </MenuItem>
                                {[
                                    { name: "nordvpn" },
                                    { name: "pia" },
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            id="countries"
                            name="countries"
                            label="countries: danh sách các nước lấy ip"
                            value={countries}
                            onChange={(event) => setCountries(event.target.value)}
                        />

                        <TextField
                            fullWidth
                            id="login_info"
                            name="login_info"
                            label="login_info:vd: nordvpn token"
                            value={loginInfo}
                            onChange={(event) => setLoginInfo(event.target.value)}
                        />

                        <TextField
                            fullWidth
                            id="choose_duration"
                            name="choose_duration"
                            label="choose_duration: khoảng thời gian giữa 2 lần chọn"
                            type="number"
                            value={chooseDuration}
                            onChange={(event) => setChooseDuration(parseInt(event.target.value))}
                        />

                        <TextField
                            fullWidth
                            id="max_threads"
                            name="max_threads"
                            label="max_threads: số luồng tối đa cho account hiện tại"
                            type="number"
                            value={maxThreads}
                            onChange={(event) => setMaxThreads(parseInt(event.target.value))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}