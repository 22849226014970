import request from "../helpers/request";
import { hasPermission } from "../helpers/utils";

// get list video
export function listPackageTypes() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/user/list-package-types`,
    method: "get",
  });
}

export function getListPackage(params) {
  if (hasPermission(['packages-info-list'])) {
    return request({
      headers: {
        "Content-Type": "application/json",
      },
      url: "/u-apis/packages",
      method: "get",
      params,
    });
  }
}
// get list video
export function getListPackageVideo(params, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${id}/yt_videos`,
    method: "get",
    params,
  });
}

// get list playlist
export function getListPackagePlayList(params, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${id}/yt_playlists`,
    method: "get",
    params,
  });
}

// get list channel
export function getListPackageChannel(params, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${id}/yt_channels`,
    method: "get",
    params,
  });
}

// get list ggsearch
export function getListPackageGGSearch(params, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${id}/gg_search`,
    method: "get",
    params,
  });
}
// get list hashtag
export function getListPackageHashtag(params, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${id}/hashtag`,
    method: "get",
    params,
  });
}

// get list scenarios
export function getListPackageScenarios(params, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${id}/scenarios`,
    method: "get",
    params,
  });
}
// get detail scenarios
export function getListPackageScenariosDetail(id, uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${id}/scenarios/${uid}`,
    method: "get",
  });
}
// get list action
export function getListPackageScenariosAction(scenarioType) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/user/list-actions?type=${scenarioType}`,
    method: "get",
  });
}
// get detail setting
export function getPackageSetting(uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${uid}/settings`,
    method: "get",
  });
}
// get list comments
export function getListComment(params, uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${uid}/comments`,
    method: "get",
    params
  });
}
// create comments
export function createComments(uid, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${uid}/comments`,
    method: "post",
    data
  });
}
// delete comments
export function deleteComments(uid, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${uid}/comments`,
    method: "delete",
    data
  });
}
// delete allcomments
export function deleteAllComments(uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${uid}/comments/all`,
    method: "delete",
  });
}
// Update detail setting
export function updatePackageSetting(uid, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${uid}/settings`,
    method: "put",
    data
  });
}

// Create package
export function createPackage(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages`,
    method: "post",
    data
  });
}
// Get package
export function getPackage(uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${uid}`,
    method: "get",
  });
}
// Create package
export function updatePackage(packageUid, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${packageUid}`,
    method: "put",
    data
  });
}
// create video
export function createPackageVideo(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_videos`,
    method: "post",
    data
  });
}

// create playlist
export function createPackagePlayList(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_playlists`,
    method: "post",
    data
  });
}

// create channel
export function createPackageChannel(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_channels`,
    method: "post",
    data
  });
}

// create ggsearch
export function createPackageGGSearch(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/gg_search`,
    method: "post",
    data
  });
}
// create hashtag
export function createPackageHashtag(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/hashtag`,
    method: "post",
    data
  });
}

// create Scenarios
export function createPackageScenarios(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/scenarios`,
    method: "post",
    data
  });
}

// update video
export function updatePackageVideo(data, package_uid, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_videos/${id}`,
    method: "put",
    data
  });
}
// update playlist
export function updatePackagePlayList(data, package_uid, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_playlists/${id}`,
    method: "put",
    data
  });
}
// update Channel
export function updatePackageChannel(data, package_uid, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_channels/${id}`,
    method: "put",
    data
  });
}
// update GGsearch
export function updatePackageGGSearch(data, package_uid, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/gg_search/${id}`,
    method: "put",
    data
  });
}
// update Hashtag
export function updatePackageHashtag(data, package_uid, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/hashtag/${id}`,
    method: "put",
    data
  });
}
// update Scenarios
export function updatePackageScenarios(data, package_uid, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/scenarios/${id}`,
    method: "put",
    data
  });
}

// Delete package
export function deletePackage(id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${id}`,
    method: "delete",
  });
}

// Delete video
export function deletePackageVideo(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_videos`,
    method: "delete",
    data
  });
}

// Delete Channel
export function deletePackageChannel(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_channels`,
    method: "delete",
    data
  });
}
// Delete Scenarios
export function deletePackageScenarios(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/scenarios`,
    method: "delete",
    data
  });
}

// Delete all video
export function deleteAllPackageVideo(package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_videos/delete-all`,
    method: "delete",
  });
}

// Delete playlist
export function deletePackagePlayList(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_playlists`,
    method: "delete",
    data
  });
}
// Delete GGSearch
export function deletePackageGGSearch(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/gg_search`,
    method: "delete",
    data
  });
}
// Delete Hashtag
export function deletePackageHashtag(data, package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/hashtag`,
    method: "delete",
    data
  });
}

// Delete all playlist
export function deleteAllPackagePlayList(package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_playlists`,
    method: "delete",
  });
}


// Delete all Channel
export function deleteAllPackageChannel(package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/yt_channels`,
    method: "delete",
  });
}

// Delete all GGsearch
export function deleteAllPackageGGSearch(package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/gg_search/delete-all`,
    method: "delete",
  });
}

// Delete all Hashtag
export function deleteAllPackageHashtag(package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/hashtag/delete-all`,
    method: "delete",
  });
}


// ================= Short Link ============================
export function packageShortLinkAdd(package_uid, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/short_links`,
    method: "post",
    data: { data: data }
  });
}
export function packageShortLinkList(package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/short_links`,
    method: "get"
  });
}
export function packageShortLinkDellAll(package_uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/packages/${package_uid}/short_links`,
    method: "delete"
  });
}