import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const validationSchema = yup.object({
    ncc: yup
        .string('Enter your package ncc')
        .required('Package ncc is required'),
});
export default function FormCreatePremiumApiKey({ open, onClose, onSubmitForm, currentItem }) {
    const classes = useStyles();
    const [ncc, setNcc] = React.useState(currentItem?.ncc || '');
    const [secret_key, setSecretKey] = React.useState(currentItem?.secret_key || ''); 

    const handleSubmit = () => {
        const data = {
            "ncc": ncc,
            "secret_key": secret_key,
        }
        onSubmitForm(data)
    }

    return (
        <div>
            <form>
                <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create Premium ApiKey</DialogTitle>
                    <DialogContent>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                ncc
                            </InputLabel>
                            <Select
                                value={ncc}
                                onChange={(event) => setNcc(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="FLUTERWARE">
                                    <em>FLUTERWARE</em>
                                </MenuItem>
                                {[
                                    { name: "FLUTERWARE" },
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            id="secret_key"
                            name="secret_key"
                            label="secret_key"
                            value={secret_key}
                            onChange={(event) => setSecretKey(event.target.value)}
                        />  
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}