import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
export default function DataTable({ columns, data, loading, total, setSelectedRow, filter, setFilter, rowHeight = 52 }) {
  // console.log('filter', filter)
  // console.log('data', data)
  return (
    <div style={{ height: "100%", width: '100%' }}>
      {/* <div style={{ height: 'calc(100vh - 350px)', width: '100%' }}> */}
      <DataGrid
        rows={data}
        // loading={loading}
        columns={columns?.map(column => ({
          ...column,
          // flex: 1
        }))}
        rowsPerPageOptions={[25, 50, 100, 300, 500]}
        autoHeight
        wrapText
        disableColumnMenu
        checkboxSelection
        disableSelectionOnClick
        disableColumnFilter
        disableColumnReorder
        disableColumnResize
        autoPageSize={true}
        disableExtendRowFullWidth
        pagination={true}
        paginationMode="server"
        rowHeight={rowHeight}
        onSelectionModelChange={(newSelection) => {
          setSelectedRow(newSelection);
        }}
        rowCount={total || 0}
        page={parseInt(filter.page) - 1}
        pageSize={parseInt(filter.limit)}
        onPageSizeChange={(e) => setFilter({
          ...filter,
          limit: e
        })}
        onPageChange={(page) => {
          setFilter({
            ...filter,
            page: page + 1
          })
        }}
      />
    </div>
  );
}