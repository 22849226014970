import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { proxyV2ChangeUserPass } from "../../../apis/proxies";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function DialogChangeUserPass({ open, onClose, uids }) {
  const classes = useStyles();
  const [userProxy, setUserProxy] = React.useState("");
  const [passProxy, setPassProxy] = React.useState("");
  const [isRandom, setIsRandom] = React.useState(true);

  return (
    <div>
      <form>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Duration</DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRandom}
                  onChange={() => {
                    setIsRandom(!isRandom);
                  }}
                />
              }
              label="Random user pass"
            />
            <TextField
              fullWidth
              disabled={isRandom}
              id="userProxy"
              name="userProxy"
              label="userProxy"
              value={userProxy}
              onChange={(event) => setUserProxy(event.target.value)}
            />
            <TextField
              fullWidth
              disabled={isRandom}
              id="passProxy"
              name="passProxy"
              label="passProxy"
              value={passProxy}
              onChange={(event) => setPassProxy(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                const data = {
                  uids: uids,
                  user_proxy: userProxy,
                  pass_proxy: passProxy,
                  is_random: isRandom,
                };
                await proxyV2ChangeUserPass(data);
                onClose();
              }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
