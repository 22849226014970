import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { hasPermission } from '../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function EditMachine({ open, onClose, onSubmitForm, selectedRow, packages, currentPackage, workerTypes }) {
    const classes = useStyles();
    const [ipType, setIpType] = React.useState("");
    const [bType, setBType] = React.useState("");
    const [liveDead, setLiveDead] = React.useState("");
    const [workerType, setWorkerType] = React.useState("");
    const [crPackage, setCrPackage] = React.useState("");
    const [notiPerformance, setNotiPerformance] = React.useState("");

    const [duration, setDuration] = React.useState(0);
    const [runningThreadhold, setRunningThreadhold] = React.useState(0);
    const [concurrentMax, setConcurrentMax] = React.useState(0);
    const [concurrent, setConcurrent] = React.useState(0);
    const [runningNumber, setRunningNumber] = React.useState(0);

    const handleSubmit = () => {
        const data = {
            "package_uid": crPackage,
            "worker_type": workerType,
            "ip_type": ipType,
            "b_type": bType,
            "live_dead": liveDead,
            "noti_performance": notiPerformance,

            "duration": duration,
            "running_threadhold": runningThreadhold,
            "concurrent_max": concurrentMax,
            "concurrent": concurrent,
            "running_number": runningNumber,

            "vms": selectedRow?.map(mc => mc.name)
        }
        onSubmitForm(data)
    }
    return (
        <div>
            <form>
                <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit machine</DialogTitle>
                    <DialogContent>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Package
                            </InputLabel>
                            <Select
                                value={crPackage}
                                onChange={(event) => setCrPackage(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    packages?.map(pk => <MenuItem key={pk.uid} value={pk.uid}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Worker type
                            </InputLabel>
                            <Select
                                value={workerType}
                                onChange={(event) => setWorkerType(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    workerTypes?.map(pk => <MenuItem key={pk.worker_type} value={pk.worker_type}>{pk.worker_type}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Ip type
                            </InputLabel>
                            <Select
                                value={ipType}
                                onChange={(event) => setIpType(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    [
                                        {
                                            name: "DEFAULT"
                                        },
                                        {
                                            name: "DYNAMIC"
                                        },
                                        {
                                            name: "STATIC"
                                        },
                                        {
                                            name: "AUTH_IP"
                                        },
                                        {
                                            name: "LINK_CHANGE"
                                        },
                                        {
                                            name: "USED_NOISP_DEFAULT"
                                        },
                                        {
                                            name: "STARVPN"
                                        },
                                        {
                                            name: "ROTATION"
                                        },
                                        {
                                            name: "VPN"
                                        },
                                        {
                                            name: "VPN_PROXY"
                                        },
                                        {
                                            name: "DCOM_PROXY"
                                        }
                                    ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        {hasPermission(['machines-info-show_btype']) ?
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                    B type
                                </InputLabel>
                                <Select
                                    value={bType}
                                    onChange={(event) => setBType(event.target.value)}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        [
                                            {
                                                name: "bra"
                                            },
                                            {
                                                name: "gol"
                                            },
                                            {
                                                name: "gol_t08"
                                            },
                                            {
                                                name: "gol_t09"
                                            },
                                            {
                                                name: "gol_t10"
                                            },
                                            {
                                                name: "gol_v108"
                                            },
                                            {
                                                name: "chr"
                                            },
                                            {
                                                name: "lucky_browser"
                                            },
                                        ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl> : <></>
                        }
                        {/* <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Live dead
                            </InputLabel>
                            <Select
                                value={liveDead}
                                onChange={(event) => setLiveDead(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    [
                                        {
                                            name: "CHECK"
                                        },
                                        {
                                            name: "UNCHECK"
                                        }
                                    ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl> */}

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                RunningNumber
                            </InputLabel>
                            <Select
                                value={runningNumber}
                                onChange={(event) => setRunningNumber(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {[
                                    { name: "1", value: 1, },
                                    { name: "2", value: 2, },
                                    { name: "3", value: 3, },
                                    { name: "5", value: 5, },
                                    { name: "10", value: 10, },
                                    { name: "20", value: 20, },
                                    { name: "30", value: 30, },
                                    { name: "50", value: 50, },
                                    { name: "60", value: 60, },
                                    { name: "70", value: 70, },
                                    { name: "80", value: 80, },
                                    { name: "90", value: 90, },
                                    { name: "100", value: 100, },
                                    { name: "110", value: 110, },
                                    { name: "120", value: 120, },
                                    { name: "130", value: 130, },
                                    { name: "140", value: 140, },
                                    { name: "150", value: 150, },
                                    { name: "160", value: 160, },
                                    { name: "170", value: 170, },
                                    { name: "180", value: 180, },
                                    { name: "190", value: 190, },
                                    { name: "200", value: 200, },
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.value}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Duration
                            </InputLabel>
                            <Select
                                value={duration}
                                onChange={(event) => setDuration(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {[
                                    { name: "30", value: 30, },
                                    { name: "60", value: 60, },
                                    { name: "90", value: 90, },
                                    { name: "120", value: 120, },
                                    { name: "150", value: 150, },
                                    { name: "180", value: 180, },
                                    { name: "210", value: 210, },
                                    { name: "240", value: 240, },
                                    { name: "270", value: 270, },
                                    { name: "300", value: 300, }
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.value}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Threadhold
                            </InputLabel>
                            <Select
                                value={runningThreadhold}
                                onChange={(event) => setRunningThreadhold(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {[
                                    { name: "1", value: 1, },
                                    { name: "2", value: 2, },
                                    { name: "3", value: 3, },
                                    { name: "5", value: 5, },
                                    { name: "10", value: 10, },
                                    { name: "20", value: 20, },
                                    { name: "30", value: 30, },
                                    { name: "40", value: 40, },
                                    { name: "45", value: 45, },
                                    { name: "50", value: 50, },
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.value}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Max
                            </InputLabel>
                            <Select
                                value={concurrentMax}
                                onChange={(event) => setConcurrentMax(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {[
                                    { name: "1", value: 1, },
                                    { name: "2", value: 2, },
                                    { name: "3", value: 3, },
                                    { name: "5", value: 5, },
                                    { name: "5", value: 5, },
                                    { name: "10", value: 10, },
                                    { name: "20", value: 20, },
                                    { name: "30", value: 30, },
                                    { name: "40", value: 40, },
                                    { name: "45", value: 45, },
                                    { name: "50", value: 50, },
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.value}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Concurrent
                            </InputLabel>
                            <Select
                                value={concurrent}
                                onChange={(event) => setConcurrent(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {[
                                    { name: "1", value: 1, },
                                    { name: "2", value: 2, },
                                    { name: "3", value: 3, },
                                    { name: "5", value: 5, },
                                    { name: "3", value: 3, },
                                    { name: "5", value: 5, },
                                    { name: "7", value: 7, },
                                    { name: "10", value: 10, },
                                    { name: "15", value: 15, },
                                    { name: "20", value: 20, },
                                    { name: "25", value: 25, },
                                    { name: "30", value: 30, },
                                    { name: "35", value: 35, },
                                    { name: "40", value: 40, },
                                    { name: "45", value: 45, },
                                    { name: "50", value: 50, }
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.value}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Noti Performance
                            </InputLabel>
                            <Select
                                value={notiPerformance}
                                onChange={(event) => setNotiPerformance(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    [
                                        {
                                            name: "ENABLE"
                                        },
                                        {
                                            name: "DISABLE"
                                        }
                                    ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}