import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import DataTable from "../../../components/Table/Table";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import { createSMSKey, deleteSMSKey, getListSMSDetail, updateSMSKey } from "../../../apis/sms";
import FormCreateSMSNcc from "./CreateSMSKey";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function SMSDetail() {
  const classes = useStyles();
  const [sms, setSms] = useState([])
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  // const [currentId, setCurrentId] = useState(undefined);
  // const mounted = React.useRef(false);
  // const [fullLoading, setFullLoading] = useState(false);
  // const [selectedRow, setSelectedRow] = useState([]);
  const [currentItem, setCurrentItem] = useState(undefined);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const history = useHistory()
  const { id } = useParams()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );


  useEffect(() => {
    (async () => {
      setLoading(true);
      const list = await getListSMSDetail(id)
      setLoading(false);
      setSms(list?.data)
      setTotal(list?.total)
    })();
  }, [id]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleDeleteSMS = async (cuId) => {
    // setFullLoading(true);
    await deleteSMSKey(id, cuId)
    const list = await getListSMSDetail(id)
    setSms(list?.data)
    setTotal(list?.total)
    // setFullLoading(false);
    setOpenConfirm(false);
  }
  const handleEdit = (item) => {
    setCurrentItem(item)
    setOpen(true)
  }
  const handleShowConfirm = (item) => {
    setOpenConfirm(true)
    setCurrentItem(item)
  }

  const handleCreateSMSKey = async (data) => {
    // setFullLoading(true)
    currentItem ? await updateSMSKey(data, id, currentItem.id) : await createSMSKey(data, id)
    const list = await getListSMSDetail(id)
    setCurrentItem(undefined)
    setSms(list?.data)
    setTotal(list?.total)
    setOpen(false);
    // setFullLoading(false);
  }

  const columns = [
    { field: 'stt', headerName: 'STT', width: 150, editable: false },
    {
      field: 'ncc',
      headerName: 'NCC',
      flex: 0.3,
      editable: false
    },
    {
      field: 'api_key', headerName: 'API Key', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'counter', headerName: 'Counter', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'action', headerName: 'Actions', width: 120, type: 'number',
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <Edit onClick={() => handleEdit(params?.row)} />
            </IconButton>
            <IconButton>
              <DeleteIcon onClick={() => handleShowConfirm(params?.row)} />
            </IconButton>
          </>
        );
      }
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Config NCC" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            {/* <Filter filter={filter} setFilter={setFilterToParams} /> */}
            <Button onClick={() => { setCurrentItem(undefined); handleClickOpen() }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">ADD</Button>
            {/* <Button onClick={() => setOpenConfirm(false)} disabled={!selectedRow.length} style={{ height: 48, width: 120 }} variant="contained" color="secondary">Delete</Button> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Keys" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={sms?.map((item, i) => ({ ...item, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={() => { }}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
      < FormCreateSMSNcc currentItem={currentItem} key={currentItem} open={open} onClose={handleClose} onSubmitForm={(data) => handleCreateSMSKey(data)} />
      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete key?" onSubmit={() => { handleDeleteSMS(currentItem?.id) }} />
    </>
  );
}
