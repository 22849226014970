import {
  Home as HomeIcon,
  LibraryBooks as LibraryIcon,
  People,
  Devices,
  SwapHoriz,
  AccountBox,
} from "@material-ui/icons";
// pages
import Dashboard from "../pages/dashboard";
import Users from "../pages/users/UserList/User";
import UserDetail from "../pages/users/UserDetail/UserDetail";
import Packages from "../pages/packages/PackageList/Packages";
import PackageVideo from "../pages/packages/PackageDetail/Video/List/PackageVideo";
import PackagePlayList from "../pages/packages/PackageDetail/PlayList/List/PackagePlayList";
import PackageChannel from "../pages/packages/PackageDetail/Channel/List/PackageChannel";
import Machine from "../pages/machine/MachineList/Machine";
import GoogleApiKeys from "../pages/gg_apikey/apikey/GgApiKey";
import Apis from "../pages/api/ApiList/APIs";
import Performances from "../pages/performance/Performances/Performance";
import Accounts from "../pages/account/Gmail/AccountsGmail";
import AccountsFacebook from "../pages/account/Facebook/AccountsFacebook";
import PackageGGSearch from "../pages/packages/PackageDetail/GoogleSearch/List/PackageGGSearch";
import SMSList from "../pages/sms/SMSList/SMSList";
import SMSDetail from "../pages/sms/SMSDetail/SMSDetail";
import VMSList from "../pages/machine/MachineDetail/VMS/List/VMSList";

import ProxyDynamicList from "../pages/proxies/ProxyDynamicList/ProxyDynamicList";
import ProxyStaticList from "../pages/proxies/ProxyStaticList/ProxyStaticList";
import ProxyLinkChangeList from "../pages/proxies/ProxyLinkChangeList/ProxyLinkChangeList";
import StarvpnList from "../pages/proxies/ProxyStarvpn/StarvpnList";
import BlacklistIpList from "../pages/proxies/BlacklistIp/BlacklistIpList";

import AndroidList from "../pages/machine/MachineDetail/Android/List/AndroidList";
import PackageScript from "../pages/packages/PackageDetail/Script/List/PackageScript";
import CreateUpdateScript from "../pages/packages/PackageDetail/Script/Detail/CrateUpdateScript";
import PackageSetting from "../pages/packages/PackageDetail/Setting/PackageSetting";
import CommentList from "../pages/packages/PackageDetail/Comments/List/CommentList";
import UserLogs from "../pages/users/UserLogs/UserLogs";
import SmsV2 from "../pages/sms/SmsV2/SmsV2List";
import PackageHashtag from "../pages/packages/PackageDetail/Hashtag/List/PackageHashtag";
import PremiumApiKey from "../pages/premiums/api_keys/PremiumApiKeyList";
import PremiumCard from "../pages/premiums/cards/PremiumCardList";
import PremiumCode from "../pages/premiums/codes/PremiumCodeList";
import ProxyAuthIp from "../pages/proxies/ProxyAuthIp/ProxyAuthIp";
import ProxyAds from "../pages/proxies/ProxyAds/ProxyAds";
import PackageShortLink from "../pages/packages/PackageDetail/ShortLink/ShortLink";
import ProxyRotationList from "../pages/proxies/ProxyRotationList/ProxyRotationList";
import UserAgentList from "../pages/proxies/ProxyUserAgent/UserAgentList";
import VpnAccountList from "../pages/proxies/VpnProxy/VpnAccountList";
import DcomProxy from "../pages/proxies/DcomProxy/DcomProxyList";
import SmsHuuVanList from "../pages/sms/SmsHuuVan/SmsHuuVanList";
import SmsHuuVanTransactionList from "../pages/sms/SmsHuuVanTransaction/SmsHuuVanTransactionList";
import DcomProxyV2 from "../pages/proxies/DcomProxyV2/DcomProxyV2List";
import DcomV2 from "../pages/proxies/DcomV2/DcomV2List";
import TinsoftProxy from "../pages/proxies/TinsoftProxy/TinsoftProxyList";
import MailNuoiList from "../pages/account/MailNuoi/MailNuoiList";

const routes = [
  {
    key: "Dashboard",
    id: 0,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    ContentComponent: Dashboard,
    notAuth: true,
  },
  {
    key: "Packages",
    label: "Packages",
    link: "/app/packages",
    icon: <LibraryIcon />,
    exact: true,
    ContentComponent: Packages,
    roles: ["packages-info-list"],
  },
  {
    key: "Packages Detail Video",
    label: "Packages Detail Video",
    link: "/app/package/:id/video",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: PackageVideo,
    roles: ["packages-yt_videos-list"],
  },
  {
    key: "Packages Detail Playlist",
    label: "Packages Detail Playlist",
    link: "/app/package/:id/playlist",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: PackagePlayList,
    roles: ["packages-yt_playlists-list"],
  },
  {
    key: "Packages Detail Channel",
    label: "Packages Detail Channel",
    link: "/app/package/:id/channel",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: PackageChannel,
    roles: ["packages-yt_channels-list"],
  },
  {
    key: "Packages Detail GGSearch",
    label: "Packages Detail GGSearch",
    link: "/app/package/:id/ggsearch",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: PackageGGSearch,
    roles: ["packages-gg_search-list"],
  },
  {
    key: "Packages Detail Hastag",
    label: "Packages Detail Hastag",
    link: "/app/package/:id/hashtag",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: PackageHashtag,
    roles: ["packages-gg_search-list"],
  },
  {
    key: "Packages Detail Script",
    label: "Packages Detail Script",
    link: "/app/package/:id/script/:uid",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: CreateUpdateScript,
    roles: [""],
  },
  {
    key: "Packages Script",
    label: "Packages Script",
    link: "/app/package/:id/script",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: PackageScript,
    roles: ["packages-scenarios-list"],
  },
  {
    key: "Comments",
    label: "Comments",
    link: "/app/package/:id/comments",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: CommentList,
    roles: ["packages-comments-list"],
  },
  {
    key: "Packages Setting",
    label: "Packages Setting",
    link: "/app/package/:id/setting",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: PackageSetting,
    roles: ["packages-settings-list"],
  },
  {
    key: "Short Links",
    label: "Short Links",
    link: `/app/package/:id/short-links`,
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: PackageShortLink,
    roles: ["packages-short_links-list"],
  },

  {
    key: "Machine",
    label: "Machine",
    link: "/app/machines",
    icon: <Devices />,
    exact: true,
    ContentComponent: Machine,
    roles: ["machines-info-list"],
  },
  {
    key: "Machine VMS",
    label: "Machine VMS",
    link: `/app/machine-detail/:id/vms`,
    icon: <Devices />,
    exact: true,
    isSub: true,
    ContentComponent: VMSList,
    roles: ["machines-vms-list"],
  },
  {
    key: "Machine Android",
    label: "Machine Android",
    link: "/app/machine-detail/:id/android",
    icon: <Devices />,
    exact: true,
    isSub: true,
    ContentComponent: AndroidList,
    roles: ["machines-androids-list"],
  },

  {
    key: "Performances",
    label: "Performances",
    link: "/app/performances",
    icon: <Devices />,
    exact: true,
    ContentComponent: Performances,
    roles: ["performances-vms-info"],
  },

  {
    key: "Proxies",
    label: "Proxies",
    link: "/app/proxies/dynamic",
    icon: <Devices />,
    exact: true,
    ContentComponent: ProxyDynamicList,
    roles: ["proxies-dynamic-list"],
    children: [
      {
        key: "Dynamic",
        id: 181,
        label: "Dynamic",
        link: "/app/proxies/dynamic",
        icon: <AccountBox />,
        exact: true,
        ContentComponent: ProxyDynamicList,
        roles: ["proxies-dynamic-list"],
      },
      {
        key: "Static",
        id: 182,
        label: "Static",
        link: "/app/proxies/static",
        icon: <AccountBox />,
        exact: true,
        ContentComponent: ProxyStaticList,
        roles: ["proxies-static-list"],
      },
      {
        key: "AuthIp",
        id: 186,
        label: "AuthIp",
        link: "/app/proxies/auth-ip",
        icon: <AccountBox />,
        exact: true,
        ContentComponent: ProxyAuthIp,
        roles: ["proxies-auth_ip-add_list_del"],
      },
      {
        key: "Link Change",
        id: 183,
        label: "Link Change",
        link: "/app/proxies/guys",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: ProxyLinkChangeList,
        roles: ["proxies-guy-list"],
      },
      {
        key: "Starvpn",
        id: 184,
        label: "Starvpn",
        link: "/app/proxies/starvpn",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: StarvpnList,
        roles: ["proxies-starvpn-list"],
      },
      {
        key: "Blacklist",
        label: "Blacklist",
        link: "/app/proxies/blacklist",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: BlacklistIpList,
        roles: ["proxies-blacklist_ip-list"],
      },
      {
        key: "ProxyAds",
        label: "ProxyAds",
        link: "/app/proxies/proxy_ads",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: ProxyAds,
        roles: ["proxies-proxy_ads-add_list_del"],
      },
      {
        key: "Rotation",
        label: "Rotation",
        link: "/app/proxies/proxy_rotation",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: ProxyRotationList,
        roles: ["proxies-proxy_rotation-add_list_del"],
      },
      {
        key: "VpnAccount",
        label: "VpnAccount",
        link: "/app/proxies/vpn_account",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: VpnAccountList,
        roles: ["proxies-vpn_account-add_list_del"],
      },
      {
        key: "DCOM",
        label: "DCOM",
        link: "/app/proxies/dcom_proxy",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: DcomProxy,
        roles: ["proxies-dcom_proxy-add_list_del"],
      },
      {
        key: "DCOMV2",
        label: "DCOMV2",
        link: "/app/proxies/dcom_v2",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: DcomV2,
        roles: ["proxies-dcom_proxy-add_list_del"],
      },
      {
        key: "DCOMPROXYV2",
        label: "DCOMPROXYV2",
        link: "/app/proxies/dcom_proxy_v2",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: DcomProxyV2,
        roles: ["proxies-dcom_proxy-add_list_del"],
      },
      {
        key: "TinsoftKey",
        label: "TinsoftKey",
        link: "/app/proxies/tinsoft_proxy",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: TinsoftProxy,
        roles: ["proxies-dcom_proxy-add_list_del"],
      },
      {
        key: "UserAgent",
        label: "UserAgent",
        link: "/app/proxies/user_agents",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: UserAgentList,
        roles: ["proxies-user_agents-add_list_del"],
      },
    ],
  },
  {
    key: "Link Change",
    label: "Link Change",
    link: "/app/proxies/guys",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: ProxyLinkChangeList,
    roles: ["proxies-guy-list"],
  },
  {
    key: "Starvpn",
    label: "Starvpn",
    link: "/app/proxies/starvpn",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: StarvpnList,
    roles: ["proxies-starvpn-list"],
  },
  {
    key: "Blacklist",
    label: "Blacklist",
    link: "/app/proxies/blacklist",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: BlacklistIpList,
    roles: ["proxies-blacklist_ip-list"],
  },
  {
    key: "Static",
    label: "Static",
    link: "/app/proxies/static",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: ProxyStaticList,
    roles: ["proxies-static-list"],
  },
  {
    key: "AuthIp",
    id: 186,
    label: "AuthIp",
    link: "/app/proxies/auth-ip",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: ProxyAuthIp,
    roles: ["proxies-auth_ip-add_list_del"],
  },
  {
    key: "ProxyAds",
    id: 185,
    label: "ProxyAds",
    link: "/app/proxies/proxy_ads",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: ProxyAds,
    roles: ["proxies-proxy_ads-add_list_del"],
  },
  {
    key: "Rotation",
    id: 185,
    label: "Rotation",
    link: "/app/proxies/proxy_rotation",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: ProxyRotationList,
    roles: ["proxies-proxy_rotation-add_list_del"],
  },
  {
    key: "VpnAccount",
    id: 185,
    label: "VpnAccount",
    link: "/app/proxies/vpn_account",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: VpnAccountList,
    roles: ["proxies-vpn_account-add_list_del"],
  },
  {
    key: "DCOM",
    label: "DCOM",
    link: "/app/proxies/dcom_proxy",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: DcomProxy,
    roles: ["proxies-dcom_proxy-add_list_del"],
  },
  {
    key: "DCOMV2",
    label: "DCOMV2",
    link: "/app/proxies/dcom_v2",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: DcomV2,
    roles: ["proxies-dcom_proxy-add_list_del"],
  },
  {
    key: "DCOMPROXYV2",
    label: "DCOMPROXYV2",
    link: "/app/proxies/dcom_proxy_v2",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: DcomProxyV2,
    roles: ["proxies-dcom_proxy-add_list_del"],
  },
  {
    key: "TinsoftKey",
    label: "TinsoftKey",
    link: "/app/proxies/tinsoft_proxy",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: TinsoftProxy,
    roles: ["proxies-dcom_proxy-add_list_del"],
  },
  {
    key: "UserAgent",
    id: 185,
    label: "UserAgent",
    link: "/app/proxies/user_agents",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: UserAgentList,
    roles: ["proxies-user_agents-add_list_del"],
  },

  {
    key: "Account Gmail",
    label: "Account",
    link: "/app/accounts/gmails",
    icon: <AccountBox />,
    exact: true,
    ContentComponent: Accounts,
    roles: ["accounts-gmails-list"],
    children: [
      {
        key: "Account Gmail",
        id: 20,
        label: "Account Gmail",
        link: "/app/accounts/gmails",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: Accounts,
        roles: ["accounts-gmails-list"],
      },
      {
        key: "Account Facebook",
        id: 21,
        label: "Account Facebook",
        link: "/app/accounts/facebooks",
        icon: <AccountBox />,
        isSub: true,
        exact: true,
        ContentComponent: AccountsFacebook,
        roles: ["accounts-facebooks-list"],
      },
      {
        key: "MailNuoi",
        label: "MailNuoi",
        link: "/app/accounts/mail-nuoi",
        icon: <AccountBox />,
        isSub: true,
        exact: true,
        ContentComponent: MailNuoiList,
        roles: ["accounts-gmails-list"],
      },
    ],
  },
  {
    key: "Account Gmail",
    label: "Account Gmail",
    link: "/app/accounts/facebooks",
    icon: <AccountBox />,
    isSub: true,
    exact: true,
    ContentComponent: AccountsFacebook,
    roles: ["accounts-facebooks-list"],
  },
  {
    key: "MailNuoi",
    label: "MailNuoi",
    link: "/app/accounts/mail-nuoi",
    icon: <AccountBox />,
    isSub: true,
    exact: true,
    ContentComponent: MailNuoiList,
    roles: ["accounts-gmails-list"],
  },
  {
    key: "SMS Detail",
    label: "SMS Detail",
    link: "/app/sms/:id/setting",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: SMSDetail,
    roles: ["sms-keys-list"],
  },
  {
    key: "SMS",
    label: "SMS",
    link: "/app/sms",
    icon: <AccountBox />,
    exact: true,
    ContentComponent: SMSList,
    roles: ["sms-nccs-list"],
    children: [
      {
        key: "SMS V1",
        label: "SMS V1",
        link: "/app/sms",
        icon: <AccountBox />,
        exact: true,
        ContentComponent: SMSList,
        roles: ["sms-nccs-list"],
      },
      {
        key: "SMS V2",
        label: "SMS V2",
        link: "/app/sms-v2",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: SmsV2,
        roles: ["sms-nccs-list"],
      },
      {
        key: "HuuVan",
        label: "HuuVan",
        link: "/app/huuvan",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: SmsHuuVanList,
        roles: ["sms-huuvan-list"],
      },
      {
        key: "HvTransaction",
        label: "HvTransaction",
        link: "/app/hvtransaction",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: SmsHuuVanTransactionList,
        roles: ["sms-huuvan-list"],
      },
    ],
  },
  {
    key: "SMS V2",
    label: "SMS V2",
    link: "/app/sms-v2",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: SmsV2,
    roles: ["sms-v2-list"],
  },
  {
    key: "HuuVan",
    label: "HuuVan",
    link: "/app/huuvan",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: SmsHuuVanList,
    roles: ["sms-huuvan-list"],
  },
  {
    key: "HvTransaction",
    label: "HvTransaction",
    link: "/app/hvtransaction",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: SmsHuuVanTransactionList,
    roles: ["sms-huuvan-list"],
  },

  {
    label: "Premiums",
    link: "/app/premiums",
    icon: <LibraryIcon />,
    exact: true,
    ContentComponent: PremiumApiKey,
    roles: ["packages-info-list"],
    children: [
      {
        label: "PremiumApiKeys",
        link: "/app/premium_api_keys",
        icon: <AccountBox />,
        exact: true,
        ContentComponent: PremiumApiKey,
        roles: ["premium-api_keys-info"],
      },
      {
        label: "PremiumCard",
        link: "/app/premium_cards",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: PremiumCard,
        roles: ["premium-cards-info"],
      },
      {
        label: "PremiumCode",
        link: "/app/premium_codes",
        icon: <AccountBox />,
        exact: true,
        isSub: true,
        ContentComponent: PremiumCode,
        roles: ["premium-codes-info"],
      },
    ],
  },
  {
    label: "PremiumApiKeys",
    link: "/app/premium_api_keys",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: PremiumApiKey,
    roles: ["premium-api_keys-info"],
  },
  {
    label: "PremiumCards",
    link: "/app/premium_cards",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: PremiumCard,
    roles: ["premium-cards-info"],
  },
  {
    label: "PremiumCode",
    link: "/app/premium_codes",
    icon: <AccountBox />,
    exact: true,
    isSub: true,
    ContentComponent: PremiumCode,
    roles: ["premium-codes-info"],
  },

  {
    key: "Google ApiKey",
    label: "Google ApiKey",
    link: "/app/google-apikeys",
    icon: <SwapHoriz />,
    exact: true,
    ContentComponent: GoogleApiKeys,
    roles: ["thidparty-apikeys-google"],
  },
  {
    key: "API",
    label: "API",
    link: "/app/apis",
    icon: <SwapHoriz />,
    exact: true,
    ContentComponent: Apis,
    roles: ["apis-keys-list"],
  },
  {
    key: "User",
    label: "User",
    link: "/app/users",
    icon: <People />,
    exact: true,
    ContentComponent: Users,
    roles: ["users-info-list"],
  },
  {
    key: "UserDetail",
    label: "UserDetail",
    link: "/app/user/:id",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: UserDetail,
    roles: ["users-info-list"],
  },
  {
    key: "UserAdd",
    label: "UserAdd",
    link: "/app/user-add",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: UserDetail,
    roles: ["users-info-add"],
  },
  {
    key: "UserLogs",
    label: "UserLogs",
    link: "/app/user-logs/:id",
    icon: <People />,
    exact: true,
    isSub: true,
    ContentComponent: UserLogs,
    roles: ["users-info-list"],
  },
];

export default routes;
