import React, { useState, useEffect, } from "react";
import { Grid } from "@material-ui/core";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

// data 
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { performancesVmsInfo } from "../../../apis/performances";
import FullLoading from "../../../components/Loading/FullLoading";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Performances() {
  const [loading, setLoading] = useState(true);
  const [machines, setMachines] = useState([]);

  function compare(a, b) {
    if (a.machine < b.machine) {
      return -1;
    }
    if (a.machine > b.machine) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    (async () => {
      const data = await performancesVmsInfo()
      setLoading(false);
      data.sort(compare)
      setMachines(data)

    })();
  }, []);



  return (
    <div style={{ paddingLeft: 30 }}>
      <FullLoading open={loading} />
      <PageTitle title="Performances" />
      <Grid container spacing={4}>

        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Button
              type="submit"
              variant="contained"
              size="medium"
              style={{ minWidth: "180px", marginRight: "20px", height: 40 }}
              color="primary"
              onClick={async () => {
                const data = await performancesVmsInfo()
                setLoading(false);
                data.sort(compare)
                setMachines(data)
              }}
            >
              Refresh
            </Button>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>machine</TableCell>
                  <TableCell align="right">web</TableCell>
                  <TableCell align="right">real</TableCell>
                  <TableCell align="right">expected</TableCell>
                  <TableCell align="right">total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {machines.map((row) => (
                  <TableRow
                    key={row.machine}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.machine}
                    </TableCell>
                    <TableCell align="right">{row.web}</TableCell>
                    <TableCell align="right">{row.real}</TableCell>
                    <TableCell align="right">{row.expected}</TableCell>
                    <TableCell align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
