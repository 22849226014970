import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Timeline from '@material-ui/icons/Timeline';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";


// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import Filter from "./Filter";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from "@material-ui/core/Grid";

import DataTable from "../../../components/Table/Table";
import { getListUser } from "../../../apis/users";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function Users() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  const [users, setUsers] = useState([])

  useEffect(() => {
    (async () => {
      const list = await getListUser(filter)
      setLoading(false);
      setUsers(list?.data)
      setTotal(list?.total)
    })();
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const goToAdd = () => history.push('/app/user-add');

  const goToDetail = (id) => history.push(`/app/user/${id}`);

  const goToLogs = (id) => history.push(`/app/user-logs/${id}`);


  const columns = [
    { field: 'stt', headerName: 'STT', width: 150, editable: false },
    {
      field: 'user_name',
      headerName: 'User name',
      flex: 0.3,
      editable: false
    },
    {
      field: 'created_at', headerName: 'Created At', width: 120,
      flex: 0.3,
      renderCell: (params) => moment.unix(params.row.created_at).format('DD-MM-YYYY HH:MM A'),
      editable: false
    },
    {
      field: 'status', headerName: 'Status', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'action', headerName: 'Actions', width: 120, type: 'number',
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View logs" aria-label="add">
              <IconButton>
                <Timeline onClick={() => goToLogs(params?.row?.uid)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit detail" aria-label="add">
              <IconButton>
                <EditIcon onClick={() => goToDetail(params?.row?.uid)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" aria-label="add">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
    },
  ];
  return (
    <>
      <PageTitle title="Users" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Filter />
            <Button onClick={goToAdd} style={{ height: 48, width: 120 }} variant="contained" color="secondary">Add</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="User List" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={users?.map((user, i) => ({ ...user, id: user.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              setSelectedRow={() => { }}
              filter={filter}
              setFilter={setFilterToParams}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
