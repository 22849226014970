import request from "../helpers/request";

export function getListUser(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/users",
    method: "get",
    params,
  });
}

export function getRoles(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/roles",
    method: "get",
    params,
  });
}

export function getUser(id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/users/${id}`,
    method: "get",
  });
}

export function getUserLogs(uid, params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/users/${uid}/log-actions`,
    method: "get",
    params
  });
}

export function createUser(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/users`,
    method: "post",
    data
  });
}


export function updateUser(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/users/${data.id}`,
    method: "put",
    data
  });
}

