import React, { useState, useEffect, useCallback } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

import { NotificationsActive, Timeline } from "@material-ui/icons";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";
import EditCurrentPackage from "./EditCurrentPackage";

// data
import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import { getListPackage } from "../../../apis/packages";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import {
  deleteMachine,
  getListMachine,
  getListPreImport,
  getListWorkerType,
  setMachineWorkerType,
  setPackage,
  updateMachine,
  vmPreBulkImport,
  vmPreBulkCommand,
  updateMachines,
  resetCounterApi,
  resetPowerApi,
  setUpdateMachinePasswordApi,
  unsetUpdateMachinePasswordApi,
  setMachineNotiPerformanceApi,
  setMachineRequestLog,
} from "../../../apis/machine";
import EditCurrentWorkerType from "./EditCurrentWorkerType";
import EditCurrentIpType from "./EditCurrentIpType";
import EditCurrentBType from "./EditCurrentBType";
import EditCurrentLiveDead from "./EditCurrentLiveDead";
import EditMachine from "./EditMachine";
import EditDuration from "./EditDuration";
import EditConcurent from "./EditConcurent";
import EditMax from "./EditMax";
import EditRunningNumber from "./EditRunningNumber";
import EditThreadhold from "./EditThreadhold";
import { hasPermission, useInterval } from "../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  machine_name: "",
  sub_machine_name: "",
};
let timer;
let timerPreImport;
export default function Machine() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEditPackage, setOpenEditPackage] = useState(false);
  const [openEditIpType, setOpenEditIpType] = useState(false);
  const [openEditBType, setOpenEditBType] = useState(false);
  const [openEditLiveDead, setOpenEditLiveDead] = useState(false);
  const [openEditConcurrent, setOpenEditConcurrent] = useState(false);
  const [openEditDuration, setOpenEditDuration] = useState(false);
  const [openEditMax, setOpenEditMax] = useState(false);
  const [openEditRunningNumber, setOpenEditRunningNumber] = useState(false);
  const [openEditThreadhold, setOpenEditThreadhold] = useState(false);
  const [openEditWorkerType, setOpenEditWorkerType] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [selectedRow, setSelectedRow] = useState([]);
  const [machines, setMachine] = useState([]);
  const [packages, setPackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState("");
  const [currentMachine, setCurrentMachine] = useState(null);
  const [currentWorkerType, setCurrentWorkerType] = useState("");
  const [workerType, setWorkerType] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory();

  const [total, setTotal] = useState(0);
  const [totalPreImport, setTotalPreImport] = useState(0);

  function openBulkImport() {
    document?.getElementById("fileBulkImport")?.click();
  }
  const onFileBulkImport = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        let listVmPre = [];
        for (var line = 0; line < lines.length; line++) {
          const stringArray = lines[line].split("\t");
          const item = {
            machine_name: stringArray[0],
            sub_machine: stringArray[1],
            email: stringArray[2],
            pass: stringArray[3] || "",
            backup_email: stringArray[4] || "",
            proxy: stringArray[5] || "",
            user_agent: stringArray[6] || "",
            b_size: stringArray[7] || "",
            acc_otp_secret: stringArray[8] || "",
            acc_secret_keys: stringArray[9] || "",
          };
          listVmPre.push(item);
        }
        vmPreBulkImport({
          data: listVmPre,
        });
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  const resetCounter = async () => {
    const seletedItems = machines.filter((item) =>
      selectedRow.includes(item.id),
    );
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    setLoading(true);
    await resetCounterApi(data);
    setLoading(false);
    // const list = await getListVms(filter, id)
    // setTotal(list?.total)
    // setVms(list?.data)
  };

  const resetPower = async () => {
    const seletedItems = machines.filter((item) =>
      selectedRow.includes(item.id),
    );
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    setLoading(true);
    await resetPowerApi(data);
    setLoading(false);
  };

  const setUpdateMachinePassword = async () => {
    const seletedItems = machines.filter((item) =>
      selectedRow.includes(item.id),
    );
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    setLoading(true);
    await setUpdateMachinePasswordApi(data);
    setLoading(false);
  };

  const unsetUpdateMachinePassword = async () => {
    const seletedItems = machines.filter((item) =>
      selectedRow.includes(item.id),
    );
    const data = {
      data: seletedItems.map((item) => item?.name),
    };
    setLoading(true);
    await unsetUpdateMachinePasswordApi(data);
    setLoading(false);
  };

  // ====================================
  function openBulkReCreate() {
    document?.getElementById("fileBulkReCreate")?.click();
  }
  const onFileBulkReCreate = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        vmPreBulkCommand({
          data: lines,
          command: "RECREATE",
        });
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  function openBulkNoAction() {
    document?.getElementById("fileBulkNoAction")?.click();
  }
  const onFileBulkNoAction = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        vmPreBulkCommand({
          data: lines,
          command: "NO_ACTION",
        });
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
        notify: filterParams.notify,
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      (async () => {
        setLoading(true);
        const list = await getListMachine(filter);
        setMachine(list?.data);
        setTotal(list?.total);

        const listPreImport = await getListPreImport(filter);
        setTotalPreImport(listPreImport?.total);
        setLoading(false);
      })();
    }, 300);
  }, [filter]);

  useEffect(() => {
    (async () => {
      const list = await getListPackage({ page: 1, limit: 300 });
      setPackages(list?.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const list = await getListWorkerType({ page: 1, limit: 100 });
      setWorkerType(list?.data);
    })();
  }, []);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleUpdatePackage = async (data) => {
    await setPackage(data);
    const list = await getListMachine(filter);
    setMachine(list?.data);
    setTotal(list?.total);

    const listPreImport = await getListPreImport(filter);
    setTotalPreImport(listPreImport?.total);
    setOpenEditPackage(false);
  };

  const handleUpdateMachine = async (data, name) => {
    await updateMachine(data, name);
    const list = await getListMachine(filter);
    setMachine(list?.data);
    setTotal(list?.total);
    setOpenEditIpType(false);
    setOpenEditBType(false);
    setOpenEditLiveDead(false);

    setOpenEditDuration(false);
    setOpenEditThreadhold(false);
    setOpenEditMax(false);
    setOpenEditRunningNumber(false);
    setOpenEditConcurrent(false);

    const listPreImport = await getListPreImport(filter);
    setTotalPreImport(listPreImport?.total);
  };

  const handleUpdateMachines = async (data) => {
    await updateMachines(data);
    const list = await getListMachine(filter);
    setMachine(list?.data);
    setTotal(list?.total);

    const listPreImport = await getListPreImport(filter);
    setTotalPreImport(listPreImport?.total);
    setOpenEdit(false);
    setOpenEditLiveDead(false);
  };

  const handleUpdateWorkerType = async (data) => {
    await setMachineWorkerType(data);
    const list = await getListMachine(filter);
    setMachine(list?.data);
    setTotal(list?.total);

    const listPreImport = await getListPreImport(filter);
    setTotalPreImport(listPreImport?.total);
    setOpenEditWorkerType(false);
  };

  const handleUpdateNotifiPerformance = async (data) => {
    await setMachineNotiPerformanceApi(data);
    const list = await getListMachine(filter);
    setMachine(list?.data);
    setTotal(list?.total);

    const listPreImport = await getListPreImport(filter);
    setTotalPreImport(listPreImport?.total);
  };

  const handleUpdateRequestLog = async (data) => {
    await setMachineRequestLog(data);
    const list = await getListMachine(filter);
    setMachine(list?.data);
    setTotal(list?.total);

    const listPreImport = await getListPreImport(filter);
    setTotalPreImport(listPreImport?.total);
  };

  const handleDeleteMachine = async (id) => {
    await deleteMachine(id);
    const list = await getListMachine(filter);
    setMachine(list?.data);
    setTotal(list?.total);

    const listPreImport = await getListPreImport(filter);
    setTotalPreImport(listPreImport?.total);
    setOpenConfirm(false);
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const columns = [];
  // stt
  columns.push({
    field: "stt",
    headerName: "stt",
    width: 30,
    editable: false,
    sortable: false,
  });
  // name
  columns.push({
    field: "name",
    headerName: "name",
    flex: 0.4,
    editable: false,
    renderCell: (params) => (
      <div style={{ lineHeight: "normal" }}>
        <Link to={`/app/machine-detail/${params?.row?.name}/vms`}>
          <strong>{params?.row?.name}</strong>
        </Link>
        <div style={{ lineHeight: "normal !important" }}>
          <strong>{params?.row?.ip}</strong>
        </div>
        <div style={{ lineHeight: "normal !important" }}>{params.row.ips}</div>
      </div>
    ),
  });
  // rtype
  if (hasPermission(["machines-info-edit"])) {
    columns.push({
      field: "rtype",
      headerName: "rtype",
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {params.row.package_running_type}
            </div>
          </div>
        );
      },
    });
  }
  // total_sub_machine
  columns.push({
    field: "total_sub_machine",
    headerName: "vm",
    editable: false,
    sortable: false,
    renderCell: (params) => (
      <>
        {params?.row?.running_number}/{params?.row?.total_sub_machine}
        {hasPermission(["machines-info-edit"]) ? (
          <Tooltip title="Edit RunningNumber" aria-label="add">
            <IconButton>
              <EditIcon
                color="primary"
                onClick={() => {
                  setCurrentPackage(params?.row);
                  setOpenEditRunningNumber(true);
                }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </>
    ),
  });
  if (hasPermission(["machines-info-edit"])) {
    // package_name
    columns.push({
      field: "package_name",
      headerName: "package",
      width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <div>{params?.row?.package_name}</div>
          <Tooltip title="Edit package" aria-label="add">
            <IconButton>
              <EditIcon
                onClick={() => {
                  setCurrentPackage(params?.row);
                  setOpenEditPackage(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    });
    // worker_type
    columns.push({
      field: "worker_type",
      headerName: "worker_type",
      width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <div>{params?.row?.worker_type}</div>
          <Tooltip title="Edit worker type" aria-label="add">
            <IconButton>
              <EditIcon
                color="primary"
                onClick={() => {
                  setCurrentWorkerType(params?.row);
                  setOpenEditWorkerType(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    });
    // ip_type
    columns.push({
      field: "ip_type",
      headerName: "ip_type",
      width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <div>{params?.row?.ip_type}</div>
          <Tooltip title="Edit ip type" aria-label="add">
            <IconButton>
              <EditIcon
                color="primary"
                onClick={() => {
                  setCurrentPackage(params?.row);
                  setOpenEditIpType(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    });
    // b_type
    if (hasPermission(["machines-info-show_btype"])) {
      columns.push({
        field: "b_type",
        headerName: "b_type",
        width: 120,
        flex: 0.3,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <div
            style={{ display: "flex", justifyContent: "right", width: "100%" }}
          >
            <div>{params?.row?.b_type}</div>
            <Tooltip title="Edit b type" aria-label="add">
              <IconButton>
                <EditIcon
                  color="primary"
                  onClick={() => {
                    setCurrentPackage(params?.row);
                    setOpenEditBType(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        ),
      });
    }
    // duration
    columns.push({
      field: "duration",
      headerName: "duration",
      flex: 0.2,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <div>{params?.row?.duration}</div>
          <Tooltip title="Edit duration" aria-label="add">
            <IconButton>
              <EditIcon
                color="primary"
                onClick={() => {
                  setCurrentPackage(params?.row);
                  setOpenEditDuration(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    });
    // running_threadhold
    columns.push({
      field: "running_threadhold",
      headerName: "threadhold",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <div>{params?.row?.running_threadhold}</div>
          <Tooltip title="Edit Threadhold" aria-label="add">
            <IconButton>
              <EditIcon
                color="primary"
                onClick={() => {
                  setCurrentPackage(params?.row);
                  setOpenEditThreadhold(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    });
    // concurrent_max
    columns.push({
      field: "concurrent_max",
      headerName: "max",
      flex: 0.2,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <div>{params?.row?.concurrent_max}</div>
          <Tooltip title="Edit Max" aria-label="add">
            <IconButton>
              <EditIcon
                color="primary"
                onClick={() => {
                  setCurrentPackage(params?.row);
                  setOpenEditMax(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    });
    // concurrent
    columns.push({
      field: "concurrent",
      headerName: "concurrent",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <div>{params?.row?.concurrent}</div>
          <Tooltip title="Edit Concurrent" aria-label="add">
            <IconButton>
              <EditIcon
                color="primary"
                onClick={() => {
                  setCurrentPackage(params?.row);
                  setOpenEditConcurrent(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    });
    // noti
    columns.push({
      field: "noti",
      headerName: "noti",
      width: 120,
      type: "number",
      flex: 0.2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Notify" aria-label="add">
              <IconButton>
                <NotificationsActive
                  style={{ color: params?.row?.noti_performance ? "blue" : "" }}
                  onClick={async () => {
                    await handleUpdateNotifiPerformance({
                      machine_id: params.row.id,
                      noti_performance: !params.row.noti_performance,
                    });
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    });
  }
  // action
  columns.push({
    field: "action",
    headerName: "actions",
    width: 120,
    type: "number",
    flex: 0.2,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ lineHeight: "normal" }}>
          {hasPermission(["machines-info-logs"]) ? (
            <div style={{ lineHeight: "normal" }}>
              <Tooltip title="logs" aria-label="add">
                <IconButton>
                  <Timeline
                    style={{ color: params?.row?.request_log ? "blue" : "" }}
                    onClick={async () => {
                      await handleUpdateRequestLog({
                        machine_id: params.row.id,
                        request_log: !params.row.request_log,
                      });
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
          <div>
            <Tooltip title="Delete" aria-label="add">
              <IconButton>
                <DeleteIcon
                  onClick={() => {
                    setCurrentMachine(params?.row?.name);
                    setOpenConfirm(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      );
    },
  });

  var menus = [];
  if (hasPermission(["machines-info-edit"])) {
    menus.push({
      name: "Reset Counter",
      cp: (
        <MenuItem onClick={resetCounter}>
          {" "}
          <Typography>Reset Counter</Typography>{" "}
        </MenuItem>
      ),
    });
    menus.push({
      name: "Reset Power",
      cp: (
        <MenuItem onClick={resetPower}>
          {" "}
          <Typography>Reset Power</Typography>{" "}
        </MenuItem>
      ),
    });
    menus.push({
      name: "SetMachinePassword",
      cp: (
        <MenuItem onClick={setUpdateMachinePassword}>
          {" "}
          <Typography>SetMachinePassword</Typography>{" "}
        </MenuItem>
      ),
    });
    menus.push({
      name: "UnsetMachinePassword",
      cp: (
        <MenuItem onClick={unsetUpdateMachinePassword}>
          {" "}
          <Typography>UnsetMachinePassword</Typography>{" "}
        </MenuItem>
      ),
    });
  }
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title={`Machine:${totalPreImport}`} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Filter filter={filter} setFilter={setFilterToParams} />
            {hasPermission(["machines-info-edit"]) ? (
              <div style={{ display: "flex" }}>
                <Button
                  onClick={openBulkImport}
                  style={{ height: 48, width: 120, marginRight: 15 }}
                  variant="contained"
                  color="primary"
                >
                  Bulk Import
                </Button>
                <Button
                  disabled={!selectedRow.length}
                  onClick={handleClickOpenEdit}
                  style={{ height: 48, width: 120, marginRight: 15 }}
                  variant="contained"
                  color="secondary"
                >
                  Update
                </Button>
                <Button
                  onClick={openBulkReCreate}
                  style={{ height: 48, width: 120, marginRight: 15 }}
                  variant="contained"
                  color="primary"
                >
                  Recreate Import
                </Button>
                <Button
                  onClick={openBulkNoAction}
                  style={{ height: 48, width: 120, marginRight: 15 }}
                  variant="contained"
                  color="primary"
                >
                  NoAction Import
                </Button>
              </div>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget
            title="Machine List"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            menus={menus}
          >
            <DataTable
              columns={columns}
              data={machines?.map((user, i) => ({
                ...user,
                stt: i + 1 + (filter?.page - 1) * filter?.limit,
              }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
              rowHeight={100}
            />
          </Widget>
        </Grid>
      </Grid>
      {openEditWorkerType && (
        <EditCurrentWorkerType
          workerType={workerType || []}
          currentWorkerType={currentWorkerType}
          open={openEditWorkerType}
          onClose={() => setOpenEditWorkerType(false)}
          onSubmitForm={(data) => {
            handleUpdateWorkerType(data);
          }}
        />
      )}
      {openEditPackage && (
        <EditCurrentPackage
          packages={packages || []}
          currentPackage={currentPackage}
          open={openEditPackage}
          onClose={() => setOpenEditPackage(false)}
          onSubmitForm={(data) => {
            handleUpdatePackage(data);
          }}
        />
      )}
      {openEdit && (
        <EditMachine
          packages={packages || []}
          workerTypes={workerType || []}
          currentPackage={currentPackage}
          open={openEdit}
          selectedRow={machines?.filter((mc) => selectedRow.includes(mc.id))}
          onClose={() => setOpenEdit(false)}
          onSubmitForm={(data) => {
            handleUpdateMachines(data);
          }}
        />
      )}
      {openEditIpType && (
        <EditCurrentIpType
          packages={[
            {
              name: "DEFAULT",
            },
            {
              name: "DYNAMIC",
            },
            {
              name: "STATIC",
            },
            {
              name: "AUTH_IP",
            },
            {
              name: "LINK_CHANGE",
            },
            {
              name: "USED_NOISP_DEFAULT",
            },
            {
              name: "STARVPN",
            },
            {
              name: "ROTATION",
            },
            {
              name: "VPN",
            },
            {
              name: "VPN_PROXY",
            },
            {
              name: "DCOM_PROXY",
            },
          ]}
          currentPackage={currentPackage}
          open={openEditIpType}
          onClose={() => setOpenEditIpType(false)}
          onSubmitForm={(data, name) => {
            handleUpdateMachine(data, name);
          }}
        />
      )}
      {openEditBType && (
        <EditCurrentBType
          packages={[
            {
              name: "bra",
            },
            {
              name: "gol",
            },
            {
              name: "gol_t08",
            },
            {
              name: "gol_t09",
            },
            {
              name: "gol_t10",
            },
            {
              name: "gol_v108",
            },
            {
              name: "chr",
            },
            {
              name: "lucky_browser",
            },
          ]}
          currentPackage={currentPackage}
          open={openEditBType}
          onClose={() => setOpenEditBType(false)}
          onSubmitForm={(data, name) => {
            handleUpdateMachine(data, name);
          }}
        />
      )}
      {openEditLiveDead && (
        <EditCurrentLiveDead
          packages={[
            {
              name: "CHECK",
            },
            {
              name: "UNCHECK",
            },
          ]}
          currentPackage={currentPackage}
          open={openEditLiveDead}
          onClose={() => setOpenEditLiveDead(false)}
          onSubmitForm={(data, name) => {
            handleUpdateMachine(data, name);
          }}
        />
      )}
      {openEditDuration && (
        <EditDuration
          durations={[
            {
              name: "30",
              value: 30,
            },
            {
              name: "60",
              value: 60,
            },
            {
              name: "90",
              value: 90,
            },
            {
              name: "120",
              value: 120,
            },
            {
              name: "150",
              value: 150,
            },
            { name: "180", value: 180 },
            { name: "210", value: 210 },
            { name: "240", value: 240 },
            { name: "270", value: 270 },
            { name: "300", value: 300 },
          ]}
          machine={currentPackage}
          open={openEditDuration}
          onClose={() => setOpenEditDuration(false)}
          onSubmitForm={(data, name) => {
            handleUpdateMachine(data, name);
          }}
        />
      )}
      {openEditConcurrent && (
        <EditConcurent
          concurrents={[
            { name: "1", value: 1 },
            { name: "2", value: 2 },
            { name: "3", value: 3 },
            { name: "5", value: 5 },
            { name: "7", value: 7 },
            { name: "10", value: 10 },
            { name: "15", value: 15 },
            { name: "20", value: 20 },
            { name: "25", value: 25 },
            { name: "30", value: 30 },
            { name: "35", value: 35 },
            { name: "40", value: 40 },
            { name: "45", value: 45 },
            { name: "50", value: 50 },
          ]}
          machine={currentPackage}
          open={openEditConcurrent}
          onClose={() => setOpenEditConcurrent(false)}
          onSubmitForm={(data, name) => {
            handleUpdateMachine(data, name);
          }}
        />
      )}
      {openEditMax && (
        <EditMax
          maxs={[
            { name: "1", value: 1 },
            { name: "2", value: 2 },
            { name: "3", value: 3 },
            { name: "5", value: 5 },
            { name: "10", value: 10 },
            { name: "20", value: 20 },
            { name: "30", value: 30 },
            { name: "40", value: 40 },
            { name: "45", value: 45 },
            { name: "50", value: 50 },
          ]}
          machine={currentPackage}
          open={openEditMax}
          onClose={() => setOpenEditMax(false)}
          onSubmitForm={(data, name) => {
            handleUpdateMachine(data, name);
          }}
        />
      )}

      {openEditRunningNumber && (
        <EditRunningNumber
          runningNumbers={[
            { name: "1", value: 1 },
            { name: "2", value: 2 },
            { name: "3", value: 3 },
            { name: "5", value: 5 },
            { name: "10", value: 10 },
            { name: "20", value: 20 },
            { name: "30", value: 30 },
            { name: "50", value: 50 },
            { name: "60", value: 60 },
            { name: "70", value: 70 },
            { name: "80", value: 80 },
            { name: "90", value: 90 },
            { name: "100", value: 100 },
            { name: "110", value: 110 },
            { name: "120", value: 120 },
            { name: "130", value: 130 },
            { name: "140", value: 140 },
            { name: "150", value: 150 },
            { name: "160", value: 160 },
            { name: "170", value: 170 },
            { name: "180", value: 180 },
            { name: "190", value: 190 },
            { name: "200", value: 200 },
          ]}
          machine={currentPackage}
          open={openEditRunningNumber}
          onClose={() => setOpenEditRunningNumber(false)}
          onSubmitForm={(data, name) => {
            handleUpdateMachine(data, name);
          }}
        />
      )}
      {openEditThreadhold && (
        <EditThreadhold
          threadholds={[
            { name: "1", value: 1 },
            { name: "2", value: 2 },
            { name: "3", value: 3 },
            { name: "5", value: 5 },
            { name: "10", value: 10 },
            { name: "20", value: 20 },
            { name: "30", value: 30 },
            { name: "40", value: 40 },
            { name: "45", value: 45 },
            { name: "50", value: 50 },
          ]}
          machine={currentPackage}
          open={openEditThreadhold}
          onClose={() => setOpenEditThreadhold(false)}
          onSubmitForm={(data, name) => {
            handleUpdateMachine(data, name);
          }}
        />
      )}
      <DialogConfirm
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        message="Are you sure delete machines?"
        onSubmit={() => {
          handleDeleteMachine(currentMachine);
        }}
      />

      <input
        onChange={onFileBulkImport}
        id="fileBulkImport"
        type="file"
        hidden
      />
      <input
        onChange={onFileBulkReCreate}
        id="fileBulkReCreate"
        type="file"
        hidden
      />
      <input
        onChange={onFileBulkNoAction}
        id="fileBulkNoAction"
        type="file"
        hidden
      />
    </>
  );
}
