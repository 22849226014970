import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function FormUpdateDcomProxy({ open, onClose, onSubmitForm, currentItem }) {
    const classes = useStyles();
    const [sshInfo, setSshInfo] = React.useState(currentItem?.ssh_info || '');
    const [changeDuration, setChangeDuration] = React.useState(currentItem?.change_duration);
    const [hire, setHire] = React.useState(currentItem?.hire || 'NO');
    const [customerName, setCustomerName] = React.useState(currentItem?.customer_name || '');

    const handleSubmit = () => {
        const data = {
            "ssh_info": sshInfo,
            "change_duration": changeDuration,
            "hire": hire,
            "customer_name": customerName,
        }
        onSubmitForm(data)
    }

    return (
        <div>
            <form>
                <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Update Info</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="ssh_info"
                            name="ssh_info"
                            label="ssh_info"
                            value={sshInfo}
                            onChange={(event) => setSshInfo(event.target.value)}
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                hire
                            </InputLabel>
                            <Select
                                value={hire}
                                onChange={(event) => setHire(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="NO">
                                    <em>NO</em>
                                </MenuItem>
                                {[
                                    { name: "NO" },
                                    { name: "YES" },
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.name}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                change_duration
                            </InputLabel>
                            <Select
                                value={changeDuration}
                                onChange={(event) => setChangeDuration(event.target.value)}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={120}>
                                    <em>2 minutes</em>
                                </MenuItem>
                                {[
                                    { name: "5 minutes", value: 300 },
                                    { name: "10 minutes", value: 600 },
                                    { name: "15 minutes", value: 900 },
                                    { name: "20 minutes", value: 1200 },
                                    { name: "25 minutes", value: 1500 },
                                    { name: "30 minutes", value: 1800 },
                                    { name: "35 minutes", value: 2100 },
                                    { name: "40 minutes", value: 2400 },
                                    { name: "45 minutes", value: 2700 },
                                    { name: "50 minutes", value: 3000 },
                                    { name: "55 minutes", value: 3300 },
                                    { name: "NEVER", value: 86400 * 7 },
                                ]?.map(pk => <MenuItem key={pk.name} value={pk.value}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            id="customer_name"
                            name="customer_name"
                            label="customer_name"
                            value={customerName}
                            onChange={(event) => setCustomerName(event.target.value)}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}