import React, { useState, useEffect, useCallback } from "react";

import Grid from "@material-ui/core/Grid"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import TextField from '@mui/material/TextField';

import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";
import moment from "moment";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import DataTable from "../../../components/Table/Table";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import { createDynamic, } from "../../../apis/proxies";
import { createGoogleApikeys, delAllGoogleApikeys, listGoogleApikeys } from "../../../apis/gg_apikeys";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto',
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
let timer
export default function GgApikey() {
  const classes = useStyles();
  const [ggApikeys, setGgApikeys] = useState([])
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const [filter, setFilter] = useState(defaultParams);
  const [fileInput, setFileInput] = useState(undefined);
  const [inputText, setInputText] = useState("")
  const [total, setTotal] = useState(0);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      (async () => {
        setLoading(true);
        const list = await listGoogleApikeys(filter)
        setLoading(false);
        setGgApikeys(list?.data)
        setTotal(list?.total)
      })();
    }, 300)
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleImportProxies = async (data) => {
    await createDynamic(data)
    const list = await listGoogleApikeys(filter)
    setFileInput(undefined)
    setGgApikeys(list?.data)
    setTotal(list?.total)
  }

  const onFileChange = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        handleImportProxies({
          data: lines?.map(pr => pr?.trim()),
        });
      };
      reader.readAsText(file);
    }
  };
  const columns = [
    { field: 'stt', headerName: 'STT', width: 150, editable: false },
    {
      field: 'api_key', headerName: 'api_key', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'cnt', headerName: 'Counter', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'created_at', headerName: 'Created At', width: 120,
      flex: 0.3,
      renderCell: (params) => moment.unix(params.row.created_at).format('DD-MM-YYYY HH:MM A'),
      editable: false
    },
    {
      field: 'action', headerName: 'actions', width: 120, type: 'number',
      flex: 0.2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Delete" aria-label="add">
              <IconButton>
                {/* <DeleteIcon onClick={() => {
                }} /> */}
              </IconButton>
            </Tooltip>
          </>
        );
      }
    },
  ];
  return (
    <>
       <PageTitle title="Google Api Keys" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            <Button onClick={() => { setOpenAddDialog(true) }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Add</Button>
            <Button onClick={() => setOpenConfirm(true)} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">Delete All</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Google Api Keys" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={ggApikeys?.map((item, i) => ({ ...item, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={() => { }}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
      <input value={fileInput} onChange={onFileChange} id="fileid" type="file" hidden />


      <Dialog
        open={openAddDialog}
        onClose={() => { }}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {/* {`Add google api keys`} */}
        </DialogTitle>
        {/* <DialogContent> 
          <div style={{ float: 'right' }}>
            {!channel?.length ? <AddBoxIcon onClick={() => { }} style={{ cursor: 'pointer' }} color="secondary" /> : <></>}
          </div>
        </DialogContent> */}
        <TextField
          style={{ margin: 10 }}
          id="outlined-multiline-static"
          label="Add google api keys"
          multiline
          rows={20}
          placeholder="1 line for 1 key"
          alignContent="10"
          onChange={(event) => {
            setInputText(event.target.value)
          }}
        />
        <DialogActions>
          <Button autoFocus onClick={() => { setOpenAddDialog(false) }} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained" color="primary" disabled={inputText.length > 0 ? false : true}
            onClick={async () => {
              var lines = inputText.split("\n")
              await createGoogleApikeys({ data: lines })
              const list = await listGoogleApikeys(filter)
              setGgApikeys(list?.data)
              setTotal(list?.total)
              setOpenAddDialog(false)
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>


      <DialogConfirm
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        message="Are you sure delete all?"
        onSubmit={async () => {
          await delAllGoogleApikeys()
          const list = await listGoogleApikeys(filter)
          setGgApikeys(list?.data)
          setTotal(list?.total)
          setOpenConfirm(false);
        }} />
    </>
  );
}
