import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Edit from "@material-ui/icons/Edit";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import DataTable from "../../../components/Table/Table";
import {
  listDcomProxy,
  resetDcomProxy,
  updateDcomProxy,
} from "../../../apis/proxies";
import FormUpdateDcomProxy from "./DcomProxyCreate";
import {
  convertTimestampToDateTime,
  secondsToHms,
} from "../../../helpers/utils";
import { selectClasses } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  search: "",
};
export default function DcomProxy() {
  const classes = useStyles();
  const [proxies, setProxies] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [fullLoading, setFullLoading] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [currentItem, setCurrentItem] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState([]);
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getSelectedInfo = async () => {
    const result = proxies.filter((item) => {
      if (selectedRow.includes(item.uid)) {
        return true;
      } else {
        return false;
      }
    });
    // console.log(result)
    await resetDcomProxy(result);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  useEffect(() => {
    async function listData() {
      const list = await listDcomProxy(filter);
      setProxies(list?.data);
      setTotal(list?.total);
    }
    listData();

    const timer1 = setInterval(async () => {
      const list = await listDcomProxy(filter);
      setProxies(list?.data);
      setTotal(list?.total);
    }, 5000);

    return () => {
      clearInterval(timer1);
    };
  }, []);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  // const handleUpdateStatus = async (data) => {
  //   // setFullLoading(true)
  //   await SmsV2Update(data.id, data)
  //   // setFullLoading(false);
  // }
  const handleUpdateProxy = async (data) => {
    // setFullLoading(true)
    currentItem
      ? await updateDcomProxy(currentItem.id, {
          ...currentItem,
          ...data,
          id: undefined,
        })
      : await updateDcomProxy(data);
    const list = await listDcomProxy(filter);
    setCurrentItem(undefined);
    setProxies(list?.data);
    setTotal(list?.total);
    setOpen(false);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setOpen(true);
  };

  const handleColor = (isReseting, proxyStatus, realIp) => {
    if (isReseting || proxyStatus == "Disconnected" || !realIp) {
      return "#FF6666";
    }

    return "white";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };
  const columns = [
    {
      field: "stt",
      headerName: "STT",
      flex: 0.1,
      editable: false,
    },
    {
      field: "machine",
      headerName: "machine",
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              backgroundColor: handleColor(
                params?.row?.is_reseting,
                params?.row?.proxy_status,
                params?.row?.proxy_ip,
              ),
            }}
          >
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.machine}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.imei}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              signal: {params?.row?.signal}
            </div>
          </div>
        );
      },
    },
    {
      field: "device",
      headerName: "device",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div
              style={{ lineHeight: "normal !important", fontWeight: "bold" }}
            >
              {params?.row?.sim}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              dcom: {params?.row?.dcom_name}
            </div>
          </div>
        );
      },
    },
    {
      field: "l_proxy",
      headerName: "l_proxy",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div
              style={{ lineHeight: "normal !important" }}
            >{`${params?.row?.hub_name}:${params?.row?.l_port}`}</div>
            <div style={{ lineHeight: "normal !important" }}>
              time change:{" "}
              <strong>{secondsToHms(params?.row?.change_duration)}</strong>
            </div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "status",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {" "}
              {params?.row?.proxy_status}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.network_type}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.is_reseting}/{params?.row?.is_valid}
            </div>
          </div>
        );
      },
    },
    {
      field: "public",
      headerName: "public",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {" "}
              {params?.row?.ssh_info}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
                marginTop: "5px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >{`http://${params.row.p_user_proxy}:${params.row.p_pass_proxy}@${params.row.p_ip_proxy}:${params.row.p_port_proxy}`}</div>
            <div style={{ lineHeight: "normal !important" }}>
              real_ip: <strong>{params?.row?.proxy_ip}</strong>
            </div>
          </div>
        );
      },
    },
    {
      field: "c_info",
      headerName: "c_info",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              change at:{" "}
              <strong>
                {convertTimestampToDateTime(params?.row?.change_ip_at)}
              </strong>
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              hired: {`${params.row.hired} `}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              due_date: {params?.row?.due_date}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              customer_name: {params?.row?.customer_name}
            </div>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "action",
      width: 120,
      type: "number",
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <Edit onClick={() => handleEdit(params?.row)} />
            </IconButton>
          </>
        );
      },
    },
  ];

  var menus = [];
  menus.push({
    name: "Noaction",
    cp: (
      <MenuItem onClick={() => {}}>
        {" "}
        <Typography>Noaction</Typography>{" "}
      </MenuItem>
    ),
  });

  menus.push({
    name: "Reset",
    cp: (
      <MenuItem
        onClick={() => {
          getSelectedInfo();
        }}
      >
        {" "}
        <Typography>Reset</Typography>{" "}
      </MenuItem>
    ),
  });

  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="DcomProxy" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignContent="center"
            alignItems="center"
          >
            {/* <Filter filter={filter} setFilter={setFilterToParams} /> */}
            <Button
              disabled={!selectedRow.length}
              onClick={() => {
                const element = document.createElement("a");
                let plainText = ``;
                const dataExport = proxies.filter((item) => {
                  if (selectedRow.includes(item.uid)) {
                    plainText += `http://${item.p_user_proxy}:${item.p_pass_proxy}@${item.p_ip_proxy}:${item.p_port_proxy}\t${item.link_change}\n`;
                  }
                  return plainText;
                });
                const file = new Blob([plainText], { type: "text/plain" });
                element.href = URL.createObjectURL(file);
                element.download = "proxy-dcom.txt";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
              }}
              style={{ height: 48, width: 120, marginRight: 10 }}
              variant="contained"
              color="primary"
            >
              Export
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget
            title="DcomProxy"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            menus={menus}
          >
            <DataTable
              columns={columns}
              data={proxies?.map((item, i) => ({
                ...item,
                id: item.uid,
                stt: i + 1 + (filter?.page - 1) * filter?.limit,
              }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
              rowHeight={80}
            />
          </Widget>
        </Grid>
      </Grid>
      {open && (
        <FormUpdateDcomProxy
          currentItem={currentItem}
          open={open}
          onClose={handleClose}
          onSubmitForm={(data) => handleUpdateProxy(data)}
        />
      )}
    </>
  );
}
