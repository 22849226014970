import request from "../helpers/request";

export function createGoogleApikeys(data) {
    return request({
        headers: {
            "Content-Type": "application/json",
        },
        url: `/u-apis/thirdparty/apikeys/google`,
        method: "post",
        data,
    });
}

export function listGoogleApikeys(params) {
    return request({
        headers: {
            "Content-Type": "application/json",
        },
        url: `/u-apis/thirdparty/apikeys/google`,
        method: "get",
        params,
    });
}

export function delGoogleApikeys(id) {
    return request({
        headers: {
            "Content-Type": "application/json",
        },
        url: `/u-apis/thirdparty/apikeys/google/${id}`,
        method: "delete",
    });
}

export function delAllGoogleApikeys() {
    return request({
        headers: {
            "Content-Type": "application/json",
        },
        url: `/u-apis/thirdparty/apikeys/google/delete_all`,
        method: "patch",
    });
}

