import request from "../helpers/request";

export function performancesVmsInfo() {
    return request({
      headers: {
        "Content-Type": "application/json",
      },
      url: "/u-apis/performances/vms-info",
      method: "get",
    });
  }