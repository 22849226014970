import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Typography,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Edit from "@material-ui/icons/Edit";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import DataTable from "../../../components/Table/Table";
import {
  tinsoftProxyList,
  tinsoftProxyAdd,
  tinsoftProxyDel,
} from "../../../apis/proxies";
import { convertTimestampToDateTime } from "../../../helpers/utils";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  search: "",
};
export default function TinsoftProxy() {
  const classes = useStyles();
  const [proxies, setProxies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [inputText, setInputText] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const location = useLocation();
  const history = useHistory();

  const [openAddDialog, setOpenAddDialog] = React.useState(false);

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  async function listData() {
    const list = await tinsoftProxyList(filter);
    setProxies(list?.data);
    setTotal(list?.total);
  }
  useEffect(() => { 
    const timer1 = setInterval(async () => {
      listData( );
    }, 3000);

    return () => {
      clearInterval(timer1);
    }; 
  });

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      flex: 0.1,
      editable: false,
    },
    {
      field: "created_at",
      headerName: "created_at",
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {`${convertTimestampToDateTime(params?.row?.created_at)}`}
            </div>
          </div>
        );
      },
    },
    {
      field: "uid",
      headerName: "key",
      flex: 0.4,
      editable: false,
      sortable: false,
    },
    {
      field: "action",
      headerName: "action",
      width: 120,
      type: "number",
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <Delete
                onClick={async () => {
                  await tinsoftProxyDel([params?.row?.uid]);
                  await listData();
                }}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  var menus = [];
  menus.push({
    name: "Noaction",
    cp: (
      <MenuItem onClick={() => {}}>
        <Typography>Noaction</Typography>{" "}
      </MenuItem>
    ),
  });

  menus.push({
    name: "Delete",
    cp: (
      <MenuItem
        onClick={async () => {
          await tinsoftProxyDel(selectedRow);
          await listData();
        }}
      >
        <Typography>Delete</Typography>{" "}
      </MenuItem>
    ),
  });

  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="TinsoftProxy" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignContent="center"
            alignItems="center"
          >
            {/* <Filter filter={filter} setFilter={setFilterToParams} /> */}
            <Button
              onClick={() => {
                setOpenAddDialog(true);
              }}
              style={{ height: 48, width: 120, marginRight: 10 }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget
            title="DcomProxy"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            menus={menus}
          >
            <DataTable
              columns={columns}
              data={proxies?.map((item, i) => ({
                ...item,
                id: item.uid,
                stt: i + 1 + (filter?.page - 1) * filter?.limit,
              }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
              rowHeight={80}
            />
          </Widget>
        </Grid>
      </Grid>
      {openAddDialog ? (
        <Dialog
          open={openAddDialog}
          onClose={() => {
            setOpenAddDialog(false);
          }}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {"Add tinsoft proxy key"}
          </DialogTitle>
          {/* <DialogContent> 
          <div style={{ float: 'right' }}>
            {!channel?.length ? <AddBoxIcon onClick={() => { }} style={{ cursor: 'pointer' }} color="secondary" /> : <></>}
          </div>
        </DialogContent> */}
          <TextField
            style={{ margin: 10 }}
            id="outlined-multiline-static"
            label="Add short links"
            multiline
            rows={20}
            placeholder="1 line for 1 key"
            alignContent="10"
            onChange={(event) => {
              setInputText(event.target.value);
            }}
          />
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setOpenAddDialog(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={inputText.length > 0 ? false : true}
              onClick={async () => {
                var lines = inputText.split("\n");
                await tinsoftProxyAdd({ data: lines });
                await listData();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
}
