import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import MaterialUIPickers from "../../../components/DatePicker/DatePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Filter({ filter, setFilter }) {
  const classes = useStyles();
  const [formFilter, setformFilter] = useState(filter);
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={() => setFilter({ ...formFilter, time: Math.random() })}
    >
      <TextField
        id="standard-basic"
        label="Name"
        value={formFilter.name}
        onChange={(event) =>
          setformFilter({ ...formFilter, name: event.target.value })
        }
      />
      <Button type="submit" variant="contained" color="primary">
        Search
      </Button>
    </form>
  );
}
