import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function Filter({ filter, setFilter }) {
  const classes = useStyles();
  const [search, setsearch] = useState(filter)
  React.useEffect(() => {
    setsearch(filter)
  }, [filter])
  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={() => setFilter({ ...filter, ...search })}>
      <TextField id="standard-basic" label="machine" value={search.machine} onChange={(event) => setsearch({ ...search, machine: event.target.value })} />
      <TextField id="standard-basic" label="sub_machine" value={search.sub_machine} onChange={(event) => setsearch({ ...search, sub_machine: event.target.value })} />
      <Button type="submit" variant="contained" color="primary">Search</Button>
    </form>
  );
}