/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory, useParams } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

// data
import { createUser, getRoles, getUser, updateUser } from "../../../apis/users";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  formWrap: {
    display: 'flex'
  },
  formField: {
    marginRight: 35,
    minWidth: 200
  }
}))
const validationSchema = yup.object({
  user_name: yup
    .string('Enter your user name')
    .required('User name is required'),
  password: yup
    .string('Enter your password')
    // .min(5, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  // repassword: yup.string('Enter your password')
  //   .required('Password is required')
  //   .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export default function UserDetail() {
  const classes = useStyles();
  const { id } = useParams()
  // const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState(null);
  // const mounted = React.useRef(false);
  // const location = useLocation();
  const history = useHistory()

  const [checkedList, setCheckedList] = React.useState([]);

  var [showPassword, setShowPassword] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function onChange(e) {
    if (checkedList?.includes(e.target.value)) {
      setCheckedList(checkedList.filter(item => item !== e.target.value))
    } else {
      setCheckedList([...checkedList, e.target.value])
    }
  }
  const formik = useFormik({
    initialValues: {
      user_name: user?.user_name || '',
      password: '',
      // repassword: '',
    },
    enableReinitialize: true,
    validationSchema: id ? undefined : validationSchema,
    onSubmit: (values) => {
      handleUpdateCreateUser(values)
      // console.log('chec', checkedList)
      // alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    async function fetchData() {
      const user = await getUser(id)
      setUser(user)
      setCheckedList(user?.roles)
    }
    id && fetchData()
  }, [id])

  useEffect(() => {
    async function fetchData() {
      const roles = await getRoles()
      // setLoading(false)
      setRoles(roles)
    }
    fetchData()
  }, [])

  const isCheckedAll = useCallback((list) => {
    return list.every(v => checkedList.includes(v))
  }, [checkedList])

  const isCheckedAllParent = useCallback((list) => {
    let subList = []
    Object.keys(list).map(t => {
      subList = [...subList, ...list[t]]
      return subList
    })
    return subList.every(v => checkedList.includes(v))
  }, [checkedList])

  const handleCheckAll = useCallback((list) => {
    if (list.every(v => checkedList.includes(v))) {
      setCheckedList(checkedList.filter(function (el) {
        return !list.includes(el);
      }))
    } else {
      const temp = list.filter(function (el) {
        return !checkedList.includes(el);
      })
      setCheckedList([...checkedList, ...temp])
    }
  }, [checkedList])

  const handleCheckAllParent = useCallback((list) => {
    let subList = []
    Object.keys(list).map(t => {
      subList = [...subList, ...list[t]]
      return subList
    })
    if (subList.every(v => checkedList.includes(v))) {
      setCheckedList(checkedList.filter(function (el) {
        return !subList.includes(el);
      }))
    } else {
      const temp = subList.filter(function (el) {
        return !checkedList.includes(el);
      })
      setCheckedList([...checkedList, ...temp])
    }
  }, [checkedList])

  const handleUpdateCreateUser = async (data) => {
    // setLoading(true)
    const user = id ? await updateUser({ ...data, password: data.password || undefined, id, roles: checkedList }) : await createUser({ ...data, roles: checkedList })
    if (user) {
      history.push('/app/users')
    }
    // setLoading(false)
  }

  return (
    <>
      {/* <Loading open={loading} /> */}
      <PageTitle title="Users detail" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <form className={classes.formWrap} onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="user_name"
                name="user_name"
                label="User name"
                value={formik.values.user_name}
                onChange={formik.handleChange}
                error={formik.touched.user_name && Boolean(formik.errors.user_name)}
                helperText={formik.touched.user_name && formik.errors.user_name}
                className={classes.formField}
                disabled={!!id}
                autoComplete={false}
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                autoComplete={false}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                className={classes.formField}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
              {/* <TextField
                fullWidth
                id="repassword"
                name="repassword"
                label="Re Password"
                type="password"
                value={formik.values.repassword}
                onChange={formik.handleChange}
                error={formik.touched.repassword && Boolean(formik.errors.repassword)}
                helperText={formik.touched.repassword && formik.errors.repassword}
                className={classes.formField}
              /> */}
              <Button color="primary" variant="contained" fullWidth type="submit">
                Submit
              </Button>
            </form>
          </Box>
          <Box>
            {Object.keys(roles).length > 0 && Object.keys(roles).map((t, k) => (
              <div>
                <div direction="vertical" style={{ width: '100%', margin: 30, border: '1xp solid #ebebeb' }}>
                  <div title={t} style={{ width: '100%' }}>
                    {/* <h3>{t}</h3> */}
                    <div>
                      <FormControlLabel
                        control={<Checkbox checked={isCheckedAllParent(roles[t])} onChange={() => { handleCheckAllParent(roles[t]) }} />}
                        label={<h3>{t}</h3>}
                      />
                    </div>
                    {
                      Object.keys(roles[t]).length > 0 && Object.keys(roles[t]).map((t1, k) => (
                        <div title={t1} style={{ width: '100%', paddingLeft: 50, background: '#f0f0f0', padding: '10px 15px', borderRadius: 8, marginBottom: 15 }}>
                          <div>
                            <FormControlLabel
                              control={<Checkbox checked={isCheckedAll(roles[t][t1])} onChange={() => { handleCheckAll(roles[t][t1]) }} />}
                              label={t1}
                            />
                          </div>
                          <div style={{ marginLeft: 50, maxWidth: '100%', background: '#fff', padding: '10px 15px', borderRadius: 8 }}>
                            {
                              roles[t][t1]?.map(item => (
                                <div style={{ width: '30%', display: 'inline-block', textAlign: 'left' }}>
                                  <FormControlLabel
                                    control={<Checkbox checked={checkedList?.includes(item)} value={item} onChange={onChange} />}
                                    label={item}
                                    style={{ width: '100%' }}
                                  />
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <Divider />
              </div>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
