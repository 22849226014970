import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OPTIONS = [{
  name: 'RUNNING',
  value: 'RUNNING'
},
{
  name: 'DONE',
  value: 'DONE'
}]

export default function Filter({ filter, setFilter }) {
  const classes = useStyles();
  const [search, setsearch] = useState(filter)
  React.useEffect(() => {
    setsearch(filter)
  }, [filter])
  const handleSubmit = (e) => {
    e.preventDefault()
    setFilter({ ...filter, ...search })
  }
  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField id="standard-basic" label="Name" value={search.name} onChange={(event) => setsearch({ ...search, name: event.target.value })} />
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={search?.status}
          onChange={(event) => setsearch({ ...search, status: event.target.value })}
        >
          {
            OPTIONS?.map((item) => <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>)
          }
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary">Search</Button>
    </form>
  );
}