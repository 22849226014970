import request from "../helpers/request";

// =================================================
// ================ Premiums =======================
// =================================================
export function PremiumApiKeyList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/premium/api_keys",
    method: "get",
    params,
  });
}
export function PremiumApiKeyInsert(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/premium/api_keys",
    method: "post",
    data,
  });
} 
export function PremiumApiKeySyncCards(id, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/premium/api_keys/${id}/sync_cards`,
    method: "put",
    data,
  });
}
export function PremiumApiKeyDelete(id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/premium/api_keys/${id}`,
    method: "delete",
  });
}
// =================================================
// ================ Premiums Cards =================
// =================================================
export function PremiumCardInsert(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/premium/cards",
    method: "post",
    data,
  });
} 
export function PremiumCardList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/premium/cards",
    method: "get",
    params,
  });
} 
export function PremiumCardDeleteAll() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/premium/cards`,
    method: "delete",
  });
}

// =================================================
// ================ Premiums Codes =================
// =================================================
export function PremiumCodeInsert(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/premium/codes",
    method: "post",
    data,
  });
} 
export function PremiumCodeList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/premium/codes",
    method: "get",
    params,
  });
} 
export function PremiumCodeDeleteAll() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/premium/codes`,
    method: "delete",
  });
}