import request from "../helpers/request";

export function countOrders(data ) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "https://sms-otp.yservice.net/sms-service/stream/handlers/count-order",
    method: "get",
    data,
    withoutLoading: true,
    withoutError: true,
  });
}

export function requestOrderByMachine(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "https://sms-otp.yservice.net/sms-service/stream/handlers/request-order",
    method: "post",
    withoutLoading: true,
    withoutError: true,
    data,
  });
}

export function getImage(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "https://sms-otp.yservice.net/sms-service/stream/handlers/get-image",
    method: "post",
    withoutLoading: true,
    withoutError: true,
    data,
  });
}

export function getPosition(data){
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "https://sms-otp.yservice.net/sms-service/stream/handlers/get-position",
    method: "post",
    withoutLoading: true,
    withoutError: true,
    data,
  });
}

export function pushAction(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "https://sms-otp.yservice.net/sms-service/stream/handlers/push-actions",
    method: "post",
    withoutLoading: true,
    withoutError: true,
    data,
  });
}