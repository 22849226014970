import { Button, Grid, Paper, Typography, TextField } from "@material-ui/core";
import { SvgIcon } from "material-ui";
import React, { useEffect, useState } from "react";
import {
  countOrders,
  pushAction,
  requestOrderByMachine,
  getImage,
  getPosition,
} from "../../../../../apis/stream";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { updateSubMachine } from "../../../../../apis/machine";

export default function DialogSecretKeys({ openDialog, onClose, subMachine }) {
  const [secretKeys, setSecretKeys] = useState("");
  useEffect(() => {
    setSecretKeys(subMachine.acc_secret_keys);
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openDialog}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Secret Keys cho {subMachine.machine_name}:{subMachine.name} ( Là dãy số
        secret keys cần lưu lại, mỗi keys cách nhau dấu ,){" "}
      </DialogTitle>
      <DialogContent>
        <>
          <TextField
            fullWidth
            id="secretKeys"
            name="secretKeys"
            label="secretKeys"
            value={secretKeys}
            multiline={true}
            onChange={(event) => {
              var value = event.target.value;
              value = value.replaceAll("\t", "");
              value = value.replaceAll(" ", "");
              value = value.replaceAll("\n", ",");
              value = value.replaceAll("\r", ",");

              setSecretKeys(value);
            }}
          />
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await updateSubMachine(subMachine.machine_name, subMachine.name, {
              secret_keys: secretKeys,
            });
            onClose();
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
