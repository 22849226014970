import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import DataTable from "../../../components/Table/Table";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import { SmsV2List, SmsV2Insert, SmsV2Update, SmsV2Delete } from "../../../apis/sms";
import FormCreateSmsV2 from "./CreateSmsV2";
import EditCurrentType from "./EditCurrentType";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function SmsV2() {
  const classes = useStyles();
  const [sms, setSms] = useState([])
  const [loading, setLoading] = useState(true);
  // const [fullLoading, setFullLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [currentItem, setCurrentItem] = useState(undefined);
  // const [selectedRow, setSelectedRow] = useState([]);
  const [openChangeType, setOpenChangeType] = useState(false);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );


  useEffect(() => {
    (async () => {
      setLoading(true);
      const list = await SmsV2List(filter)
      setLoading(false);
      setSms(list?.data)
      setTotal(list?.total)
    })();
  }, []);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleDeleteSMS = async (item) => {
    // setFullLoading(true);
    await SmsV2Delete(item.id)
    const list = await SmsV2List(filter)
    setSms(list?.data)
    setTotal(list?.total)
    setCurrentItem(undefined)
    // setFullLoading(false);
    setOpenConfirm(false);
  }
  const handleShowConfirm = (item) => {
    setOpenConfirm(true)
    setCurrentItem(item)
  }

  // const handleUpdateStatus = async (data) => {
  //   // setFullLoading(true)
  //   await SmsV2Update(data.id, data)
  //   // setFullLoading(false);
  // }
  const handleCreateSmsV2 = async (data) => {
    // setFullLoading(true)
    currentItem ? await SmsV2Update(currentItem.id, { ...currentItem, ...data, id: undefined },) : await SmsV2Insert(data)
    const list = await SmsV2List(filter)
    setCurrentItem(undefined)
    setSms(list?.data)
    setTotal(list?.total)
    setOpen(false);
    setOpenChangeType(false);
    // setFullLoading(false);
  }

  const handleEdit = (item) => {
    setCurrentItem(item)
    setOpen(true)
  }
  const columns = [
    { field: 'stt', headerName: 'STT', editable: false },
    {
      field: 'ncc',
      headerName: 'NCC',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'country_code', headerName: 'Country code', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'service_code', headerName: 'Service code', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'api_key', headerName: 'api_key', width: 120,
      flex: 0.5,
      editable: false
    },
    {
      field: 'counter', headerName: 'counter', width: 120,
      flex: 0.2,
      editable: false
    },
    {
      field: 'status', headerName: 'status', width: 120,
      flex: 0.2,
      editable: false
    },
    {
      field: 'action', headerName: 'action', width: 120, type: 'number',
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            {/* <Tooltip title={params?.row?.status === "ENABLE" ? "DISABLE" : "ENABLE"}>
              <Switch checked={params?.row?.status === "ENABLE"} onChange={(e) => {
                handleUpdateStatus({ ...params?.row, status: e.target.checked ? 'ENABLE' : 'DISABLE' },)
              }} />
            </Tooltip> */}
            <IconButton>
              <Edit onClick={() => handleEdit(params?.row)} />
            </IconButton>
            <IconButton>
              <DeleteIcon onClick={() => handleShowConfirm(params?.row)} />
            </IconButton>
          </>
        );
      }
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="SMS NCC" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            {/* <Filter filter={filter} setFilter={setFilterToParams} /> */}
            <Button onClick={() => {
              setCurrentItem(undefined)
              handleClickOpen()
            }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">ADD</Button>
            {/* <Button onClick={() => setOpenConfirm(false)} disabled={!selectedRow.length} style={{ height: 48, width: 120 }} variant="contained" color="secondary">Delete</Button> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="NCC List" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={sms?.map((item, i) => ({ ...item, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={() => { }}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
      {
        open && <FormCreateSmsV2 currentItem={currentItem} open={open} onClose={handleClose} onSubmitForm={(data) => handleCreateSmsV2(data)} />
      }
      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete ncc?" onSubmit={() => { handleDeleteSMS(currentItem) }} />
      {openChangeType && <EditCurrentType
        packages={[
          {
            name: "REG_MAIL"
          },
          {
            name: "VER_MAIL"
          }
        ]}
        currentNcc={currentItem}
        open={openChangeType}
        onClose={() => setOpenChangeType(false)}
        onSubmitForm={(data) => { handleCreateSmsV2(data) }}
      />}
    </>
  );
}
