import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddBoxIcon from '@material-ui/icons/AddBox';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import TagsInput from '../../../../../components/TagInput/TagInput';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    cell_short: {
        minWidth: 200,
        width: 200,
    },
});


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
const defaultItem = {
    yt_elm_id: "",
    yt_elm_keyword: "",
    user_id: "",
    keywords: [],
    duration: 120
}
export default function DialogCreatePlayList({ open, setOpen, playlist, onSubmit }) {

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };
    const [list, setlist] = useState(playlist)

    useEffect(() => {
        setlist(playlist)
    }, [playlist])

    const handleAddItem = () => {
        setlist([...list, defaultItem])
    }

    const handleRemoveItem = (i) => {
        setlist(list.filter((item, index) => i !== index))
    }

    const handleChange = (e, i) => {
        setlist(list.map((item, index) => {
            return index !== i ?
                { ...item }
                : { ...item, [e.target.name]: e.target.value }
        }))
    }

    function handleSelecetedTags(items, i) {
        setlist(list.map((item, index) => {
            return index !== i ?
                { ...item }
                : { ...item, keywords: items }
        }))
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {!playlist?.length ? `Create Playlist` : `Edit Playlist`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.cell_short} align="left">name</TableCell>
                                        <TableCell className={classes.cell_short} align="left">yt_id</TableCell>
                                        <TableCell className={classes.cell_short} align="left">duration</TableCell>
                                        <TableCell align="left">keywords</TableCell>
                                        <TableCell align="left"> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list?.map((row, i) => (
                                        <TableRow key={row.name}>
                                            <TableCell align="left">
                                                <TextField name="yt_elm_keyword" onChange={(e) => handleChange(e, i)} value={row.yt_elm_keyword} variant="outlined" />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <TextField name="yt_elm_id" onChange={(e) => handleChange(e, i)} value={row.yt_elm_id} variant="outlined" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField name="duration" onChange={(e) => handleChange(e, i)} value={row.duration} variant="outlined" type="number" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <TagsInput
                                                    selectedTags={(items) => handleSelecetedTags(items, i)}
                                                    fullWidth
                                                    variant="outlined"
                                                    id="tags"
                                                    name="tags"
                                                    placeholder="add keyword"
                                                    label="Keywords"
                                                    tags={row.keywords}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {!playlist?.length ? <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => { handleRemoveItem(i) }} /> : <></>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                    <div style={{ float: 'right' }}>
                        {!playlist?.length ? <AddBoxIcon onClick={handleAddItem} style={{ cursor: 'pointer' }} color="secondary" /> : <></>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => { onSubmit(list) }} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}