import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";


import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import DataTable from "../../../components/Table/Table";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import { insertVpnAccount, deleteVpnAccount, listVpnAccount, updateVpnAccount } from "../../../apis/proxies";
import FormCreateVpnAccount from "./CreateVpnAccount";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function VpnAccountList() {
  const classes = useStyles();
  const [vpnAccount, setVpnAccount] = useState([])
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [currentItem, setCurrentItem] = useState(undefined);
  const location = useLocation();
  const history = useHistory()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  const getListVpnAccount = async () => {
    setLoading(true);
    const list = await listVpnAccount(filter)
    setLoading(false);
    setVpnAccount(list?.data)
    setTotal(list?.total)
  }

  useEffect(() => {
    (async () => {
      await getListVpnAccount()
    })();
  }, []);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleDeleteVpnAccunt = async (item) => {
    // setFullLoading(true);
    await deleteVpnAccount(item.uid)
    const list = await listVpnAccount(filter)

    setVpnAccount(list?.data)
    setTotal(list?.total)
    setCurrentItem(undefined)
    // setFullLoading(false);
    setOpenConfirm(false);
  }
  const handleShowConfirm = (item) => {
    setOpenConfirm(true)
    setCurrentItem(item)
  }

  const handleUpdateStatus = async (data) => {
    await updateVpnAccount(data.uid, data)
    await getListVpnAccount()
  }
  const handleInsertVpnAccount = async (data) => {
    // setFullLoading(true)
    currentItem ? await updateVpnAccount({ ...currentItem, ...data, uid: undefined }, currentItem.uid) : await insertVpnAccount(data)
    const list = await listVpnAccount(filter)
    setCurrentItem(undefined)
    setVpnAccount(list?.data)
    setTotal(list?.total)
    setOpen(false);
  }

  const handleEdit = (item) => {
    setCurrentItem(item)
    setOpen(true)
  }
  const columns = [
    { field: 'stt', headerName: 'STT', editable: false },
    {
      field: 'user_account',
      headerName: 'user_account',
      flex: 0.3,
      editable: false,
    },
    {
      field: 'supplier', headerName: 'supplier', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'countries', headerName: 'countries', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'login_info', headerName: 'login_info', width: 120,
      flex: 0.3,
      editable: false
    },
    {
      field: 'choose_duration', headerName: 'duration', width: 120,
      flex: 0.2,
      editable: false
    },
    {
      field: 'max_threads', headerName: 'max_threads', width: 120,
      flex: 0.2,
      editable: false
    },
    {
      field: 'counter', headerName: 'counter', width: 120,
      flex: 0.2,
      editable: false
    },
    {
      field: 'action', headerName: 'Actions', width: 120, type: 'number',
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params?.row?.status === "ENABLE" ? "DISABLE" : "ENABLE"}>
              <Switch checked={params?.row?.status === "ENABLE"} onChange={(e) => {
                handleUpdateStatus({ ...params?.row, status: e.target.checked ? 'ENABLE' : 'DISABLE' },)
              }} />
            </Tooltip>
            <IconButton>
              <Edit onClick={() => handleEdit(params?.row)} />
            </IconButton>
            <IconButton>
              <DeleteIcon onClick={() => handleShowConfirm(params?.row)} />
            </IconButton>
          </>
        );
      }
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="SMS NCC" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            {/* <Filter filter={filter} setFilter={setFilterToParams} /> */}
            <Button onClick={() => {
              setCurrentItem(undefined)
              handleClickOpen()
            }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">ADD</Button>
            {/* <Button onClick={() => setOpenConfirm(false)} disabled={!selectedRow.length} style={{ height: 48, width: 120 }} variant="contained" color="secondary">Delete</Button> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Vpn Accounts" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={vpnAccount?.map((item, i) => ({ ...item, id: item.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={() => { }}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
      {
        open && <FormCreateVpnAccount uid={currentItem} open={open} onClose={handleClose} onSubmitForm={(data) => handleInsertVpnAccount(data)} />
      }

      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete?" onSubmit={() => { handleDeleteVpnAccunt(currentItem) }} />
    </>
  );
}
