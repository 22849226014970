import React, { } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import Tree from 'react-animated-tree'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "@material-ui/core";

const ViewNode = (props) => {
    const { node, options, setInputValue, handleChange } = props
    const action = node?.action || "CLICK_HOME"
    return (
        <Tree content={
            <Autocomplete
                value={action}
                onChange={(event, newValue) => {
                    handleChange({ ...node, action: newValue }, node.curIndex);
                }}
                inputValue={node.action}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={options}
                style={{ width: 350, marginLeft: 15 }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                )}
            />
        }>
            {node?.children?.length > 0 &&
                node.children.map((item, index) => <ViewNode
                    setInputValue={setInputValue}
                    handleChange={handleChange}
                    options={options}
                    node={item} />)}
        </Tree>
    );
};

const TreeNodeWrap = ({ handleAddScript, data, actions, scenarioType }) => {
    const [listOpts, setListOpts] = React.useState([]);
    const [inputValue, setInputValue] = React.useState("");
    const storeSchema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .required("This field is required"),
        weight: yup
            .string()
            .trim()
            .required('This field is required'),
        max_duration: yup.number().required('This field is required'),
    });
    const formik = useFormik({
        initialValues: data,
        validationSchema: storeSchema,
        onSubmit: (values) => handleAddScript(values),
        enableReinitialize: true,
    });
    const [reScripts, setReScripts] = React.useState([]);

    const createDs = (index, list) => {
        var item = list[index];
        var node = {
            parent_index: item?.parent_index || -1,
            action: item.action,
            note: item.note,
            curIndex: index,
            right: 1,
            wrong: 2,
        };
        node.children = [];
        if (item.wrong !== -1) {
            node.children[1] = createDs(item.wrong, list);
        }
        if (item.right !== -1) {
            node.children[0] = createDs(item.right, list);
        }
        return node;
    };

    React.useEffect(() => {
        const ds1 = createDs(0, formik.values.scripts);
        setReScripts(ds1);
        setTimeout(() => {
            setReScripts(ds1);
        }, 5000)
    }, [formik.values.scripts, actions]);

    const handleChange = (data, index) => {
        if (data?.action?.toUpperCase().trim() === "END" || !data?.action) {
            const scrs = formik.values.scripts.map((item, i) => {
                return index !== i
                    ? item
                    : { ...item, action: "END", right: -1, wrong: -1 };
            });
            formik.setFieldValue(
                "scripts",
                scrs.filter((item) => item.parent_index !== index)
            );
        } else {
            const scrs = formik.values.scripts.map((item, i) => {
                return index !== i
                    ? item
                    : data?.children?.length
                        ? { ...item, action: data.action }
                        : {
                            ...item,
                            action: data.action,
                            right: formik.values.scripts.length,
                            wrong: formik.values.scripts.length + 1,
                        };
            });
            if (data?.children?.length) {
                formik.setFieldValue("scripts", scrs);
            } else {
                formik.setFieldValue("scripts", [
                    ...scrs,
                    {
                        parent_index: index,
                        curIndex: formik.values.scripts.length,
                        action: "END",
                        note: "END",
                        right: -1,
                        wrong: -1,
                    },
                    {
                        parent_index: index,
                        curIndex: formik.values.scripts.length + 1,
                        action: "END",
                        note: "END",
                        right: -1,
                        wrong: -1,
                    },
                ]);
            }
        }
    };
    React.useEffect(() => {
        setListOpts(actions?.map((item) => item.code) || []);
    }, [actions]);

    // const viewNode = (node) => {
    //     if (!node.action) {
    //         return;
    //     }
    //     return {
    //         label: node?.action,
    //         nodes: node?.children?.length > 0 ? node.children.map((item, index) => viewNode(item)) : []
    //     }
    // }
    // console.log("===============================")
    // console.log(listOpts)
    // console.log("scenarioType:", scenarioType)
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div
                    style={{ padding: 30, marginBottom: 30, alignItems: "flex-start" }}
                >
                    <div><strong> {scenarioType}</strong></div>
                    <TextField
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        name="name"
                        placeholder="Name"
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        onBlur={formik.handleBlur}
                        style={{ marginRight: 15 }}
                    />
                    <TextField
                        variant="outlined"
                        value={formik.values.weight}
                        onChange={formik.handleChange}
                        name="weight"
                        placeholder="Weight"
                        type="number"
                        error={formik.touched.weight && Boolean(formik.errors.weight)}
                        helperText={formik.touched.weight && formik.errors.weight}
                        onBlur={formik.handleBlur}
                        style={{ marginRight: 15 }}
                    />
                    <TextField
                        variant="outlined"
                        value={formik.values.max_duration}
                        onChange={formik.handleChange}
                        name="max_duration"
                        placeholder="Max duration"
                        type="number"
                        error={formik.touched.max_duration && Boolean(formik.errors.max_duration)}
                        helperText={formik.touched.max_duration && formik.errors.max_duration}
                        onBlur={formik.handleBlur}
                        style={{ marginRight: 15 }}
                    />
                    <Button type="submit" style={{ width: 120, height: 55 }} variant="contained" color="primary">
                        Lưu
                    </Button>
                </div>
            </form>

            <ViewNode
                handleChange={handleChange}
                setInputValue={setInputValue}
                options={listOpts}
                node={reScripts}
            />
        </>
    );
};

export default TreeNodeWrap;
