import request from "../helpers/request";

export function getListMachine(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/machines",
    method: "get",
    params,
  });
}
export function getListPreImport(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/pre-import",
    method: "get",
    params,
  });
}
export function vmPreBulkImport(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/vmpre",
    method: "post",
    data,
  });
}
export function vmPreBulkCommand(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/machines/set-command",
    method: "patch",
    data,
  });
}

export function resetCounterApi(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/machines/reset-counter",
    method: "patch",
    data,
  });
}

export function resetPowerApi(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/machines/reset-power",
    method: "patch",
    data,
  });
}

export function setUpdateMachinePasswordApi(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/machines/set_update_machine_password",
    method: "patch",
    data,
  });
}

export function unsetUpdateMachinePasswordApi(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/machines/unset_update_machine_password",
    method: "patch",
    data,
  });
}

// ==================================================
export function getListWorkerType(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/user/list-worker-types",
    method: "get",
    params,
  });
}

export function createUser(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/users`,
    method: "post",
    data
  });
}

export function updateMachine(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}`,
    method: "put",
    data
  });
}

export function updateMachines(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines`,
    method: "patch",
    data
  });
}

export function setPackage(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/user/set-current-package`,
    method: "post",
    data
  });
}

export function setMachineWorkerType(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/user/set-worker-type`,
    method: "post",
    data
  });
}

export function setMachineNotiPerformanceApi(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/user/set-noti-performance`,
    method: "post",
    data
  });
}

export function setMachineRequestLog(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/user/set-request-log`,
    method: "post",
    data
  });
}

// VMS api
export function getListVms(params, machine) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine}/vms`,
    method: "get",
    params,
    withoutLoading: true,
    withoutError: true,
  });
}

// android api
export function getListAndroid(params, machine) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine}/androids`,
    method: "get",
    params,
  });
}

// import
export function importMachineEmail(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/import_emails`,
    method: "post",
    data
  });
}

export function importMachineProxies(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/import_proxies`,
    method: "post",
    data
  });
}

export function importMachineChangePass(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/change_pass`,
    method: "post",
    data
  });
}

export function importMachineLogoutGmail(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/logout_gmail`,
    method: "post",
    data
  });
}

export function importMachineImportUserAgent(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/import_user_agents`,
    method: "post",
    data
  });
}

export function importMachineImportPremium(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/import_premium`,
    method: "post",
    data
  });
}

export function importMachineBSize(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/import_bsize`,
    method: "post",
    data
  });
}


export function importMachineAndroidInfo(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/import_android_info`,
    method: "post",
    data
  });
}
// import android 


// import
export function importMachineAndroidEmail(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/androids/import_emails`,
    method: "post",
    data
  });
}

export function changePassApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/change_pass`,
    method: "post",
    data
  });
}
export function logoutFuncApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/logout_gmail`,
    method: "post",
    data
  });
}

export function setRecreateApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/recreate`,
    method: "post",
    data
  });
}

export function setMachineIpApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_machine_ip`,
    method: "post",
    data
  });
}

export function setVerifyMailApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_verify_mail`,
    method: "post",
    data
  });
}

export function setChangeInfolApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_change_info`,
    method: "post",
    data
  });
}

export function setChangeAllInfoApi(machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_change_all_info`,
    method: "post",
  });
}

export function setIgnoreApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_ignore`,
    method: "post",
    data
  });
}

export function setUnignoreApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_unignore`,
    method: "post",
    data
  });
}

export function setPremiumApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_premium_start`,
    method: "post",
    data
  });
}

export function setRegMailApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_reg_mail`,
    method: "post",
    data
  });
}
export function unsetRegMailApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/unset_reg_mail`,
    method: "post",
    data
  });
}

export function setCommandApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_command`,
    method: "post",
    data
  });
}

export function setTurnOffApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/turnoff`,
    method: "post",
    data
  });
}

export function setStreaming(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_streaming`,
    method: "post",
    withoutLoading: true,
    withoutError: true,
    data
  });
}

export function unsetStreaming(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/unset_streaming`,
    method: "post",
    withoutLoading: true,
    withoutError: true,
    data
  });
}

export function setRegFacebookApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/reg_facebook`,
    method: "post",
    data
  });
}

export function setDeleteViewHistoryApi(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/delete_view_history`,
    method: "post",
    data
  });
}

export function importMachineAndroiProxies(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/androids/import_proxies`,
    method: "post",
    data
  });
}

export function deleteMachine(machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}`,
    method: "delete",
  });
}

export function setMachinePassword(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_change_info`,
    method: "patch",
    data
  });
}

export function unsetMachinePassword(data, machine_name) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machine_name}/vms/set_change_info`,
    method: "post",
    data
  });
}
// ========================= VMS =======================
// update secretKeys: data:{secret_keys: secretKeys}
export function updateSubMachine(machineName, subMachine, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/${machineName}/vms/${subMachine}`,
    method: "put",
    data
  });
}
export function checkOtp(secretKey, otp) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/machines/check_otp`,
    method: "patch",
    data: { secret_key: secretKey, },
  });
}
