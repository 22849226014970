import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { proxyV2ExtendLimit } from "../../../apis/proxies";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function DialogChangeLimit({ open, onClose, uids }) {
  const classes = useStyles();
  const [limitUsage, setLimitUsage] = React.useState("");

  return (
    <div>
      <form>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Thời gian sử dụng</DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Thời gian sử dụng
              </InputLabel>
              <Select
                value={limitUsage}
                onChange={(event) => {
                  setLimitUsage(event.target.value);
                }}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"1DAY"}>
                  <em>Thời gian sử dụng</em>
                </MenuItem>
                {[
                  { name: "1 ngày", value: "1DAY" },
                  { name: "3 ngày", value: "3DAYS" },
                  { name: "7 ngày", value: "7DAYS" },
                  { name: "14 ngày", value: "14DAYS" },
                  { name: "30 ngày", value: "30DAYS" },
                ]?.map((pk) => (
                  <MenuItem key={pk.name} value={pk.value}>
                    {pk.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                const data = {
                  uids: uids,
                  usage_duration: 86400,
                  bandwidth_limit: 1073741824 * 30,
                };
                if (limitUsage === "3DAYS") {
                  data.usage_duration = 86400 * 3;
                  data.usage_duration = 1073741824 * 500;
                }
                if (limitUsage === "7DAYS") {
                  data.usage_duration = 86400 * 7;
                  data.usage_duration = 1073741824 * 500;
                }
                if (limitUsage === "14DAYS") {
                  data.usage_duration = 86400 * 14;
                  data.usage_duration = 1073741824 * 500;
                }
                if (limitUsage === "30DAYS") {
                  data.usage_duration = 86400 * 30;
                  data.usage_duration = 1073741824 * 500;
                }

                await proxyV2ExtendLimit(data);
                onClose();
              }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
