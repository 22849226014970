import request from "../helpers/request";

export function getListGmailAccount(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/accounts/gmails",
    method: "get",
    params,
  });
}

export function getListFacebookAccount(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/accounts/facebooks",
    method: "get",
    params,
  });
}

export function deleteGmailAccount(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/accounts/gmails`,
    method: "delete",
    data,
  });
}

export function deleteFacebookAccount(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/accounts/facebooks`,
    method: "delete",
    data,
  });
}

// =============== Mail Nuoi ================

export function mailNuoiList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/accounts/mail-nuoi",
    method: "get",
    params,
  });
}

// data: {data:["mail1@hmail.com","mail2@gmail.com"]}
export function mailNuoiAdd(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/accounts/mail-nuoi",
    method: "post",
    data: data,
  });
}
// data: {data:["mail1@hmail.com","mail2@gmail.com"]}
export function mailNuoiDel(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/accounts/mail-nuoi",
    method: "delete",
    data: data,
  });
}

export function mailNuoiSendMail() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/accounts/mail-nuoi/send-mail",
    method: "patch",
  });
}
