import request from "../helpers/request";

export function getListSMS(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/sms/nccs",
    method: "get",
    params,
  });
}

export function getListSMSDetail(id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms/nccs/${id}/keys`,
    method: "get",
  });
}

export function createSMSNcc(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms/nccs`,
    method: "post",
    data
  });
}

export function createSMSKey(data, ncc) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms/nccs/${ncc}/keys`,
    method: "post",
    data
  });
}

export function updateSMSKey(data, ncc, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms/nccs/${ncc}/keys/${id}`,
    method: "put",
    data
  });
}

export function deleteSMSKey(ncc, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms/nccs/${ncc}/keys/${id}`,
    method: "delete",
  });
}


export function updateSMSNcc(data, id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms/nccs/${id}`,
    method: "put",
    data
  });
}


export function deleteSMSNcc(id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms/nccs/${id}`,
    method: "delete",
  });
}

// ====================== Sms V2 =================== 
export function SmsV2List(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/sms-v2",
    method: "get",
    params,
  });
}
export function SmsV2Insert(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/sms-v2",
    method: "post",
    data,
  });
}
export function SmsV2Detail(id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms-v2/${id}`,
    method: "get",
  });
}
export function SmsV2Update(id, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms-v2/${id}`,
    method: "put",
    data,
  });
}
export function SmsV2Delete(id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms-v2/${id}`,
    method: "delete",
  });
}

// =================== sms huu van =====================
export function apiSmsHuuVanList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/sms-huuvan",
    method: "get",
    params,
  });
}
export function apiSmsHuuVanInsert(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/sms-huuvan",
    method: "post",
    data,
  });
}
export function apiSmsHuuVanDeleteAll() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/sms-huuvan/all`,
    method: "delete",
  });
}
// =================== sms huu van transaction =====================
export function apiSmsHuuVanTransactionList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/sms-hvtransaction",
    method: "get",
    params,
    withoutLoading: true,
    withoutError: true,
  });
}
export function apiSmsHuuVanTransactionInsert() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/sms-hvtransaction",
    method: "post",
  });
} 