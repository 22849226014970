import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function FormCreatePackageDialog({ open, onClose, createPackage, packageTypes }) {

    const [name, setName] = React.useState("");
    const [packageType, setPackageType] = React.useState("");

    const classes = useStyles();

    return (
        <div>
            <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create package</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Package name"
                        value={name}
                        onChange={(event) => { setName(event.target.value) }}
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            Package Type
                        </InputLabel>
                        <Select
                            value={packageType}
                            onChange={(event) => { setPackageType(event.target.value); }}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {
                                packageTypes?.map(pk => <MenuItem key={pk} value={pk}>{pk}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => { createPackage({ name: name, package_type: packageType }) }}
                        variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}