import React, { useState, useEffect, useCallback } from "react";
import { Grid,  } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete'; 
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";


// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data 
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "../../../components/Table/Table";
 import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import { PremiumApiKeyList, PremiumApiKeyInsert, PremiumApiKeySyncCards, PremiumApiKeyDelete } from "../../../apis/premiums";
import FormCreatePremiumApiKey from "./CreatePremiumApiKey";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function PremiumApiKey() {
  const classes = useStyles();
  const [premiumApiKey, setPremiumApiKey] = useState([])
  const [loading, setLoading] = useState(true);
  const [fullLoading, setFullLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
   const [currentItem, setCurrentItem] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openChangeType, setOpenChangeType] = useState(false);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );


  useEffect(() => {
    (async () => {
      setLoading(true);
      const list = await PremiumApiKeyList(filter)
      setLoading(false);
      setPremiumApiKey(list?.data)
      setTotal(list?.total)
    })();
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleDeletePremiumApikey = async (item) => {
    setFullLoading(true);
    await PremiumApiKeyDelete(item.id)
    const list = await PremiumApiKeyList(filter)
    setPremiumApiKey(list?.data)
    setTotal(list?.total)
    setCurrentItem(undefined)
    setFullLoading(false);
    setOpenConfirm(false);
  }
  const handleShowConfirm = (item) => {
    setOpenConfirm(true)
    setCurrentItem(item)
  }

  const handleCreatePremiumApiKey = async (data) => {
    setFullLoading(true)
    await PremiumApiKeyInsert(data)
    const list = await PremiumApiKeyList(filter)
    setCurrentItem(undefined)
    setPremiumApiKey(list?.data)
    setTotal(list?.total)
    setOpen(false);
    setOpenChangeType(false);
    setFullLoading(false);
  }


  const handleSyncCards = async (item) => {
    setFullLoading(true);
    await PremiumApiKeySyncCards(item.id)
    const list = await PremiumApiKeyList(filter)
    setPremiumApiKey(list?.data)
    setTotal(list?.total)
    setCurrentItem(undefined)
    setFullLoading(false);
    setOpenConfirm(false);

  }

  const columns = [
    { field: 'id', headerName: 'id', editable: false },
    {
      field: 'ncc',
      headerName: 'NCC',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'secret_key',
      headerName: 'secret_key',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'action', headerName: 'action', width: 120, type: 'number',
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <Button onClick={() => handleSyncCards(params?.row)} style={{ height: 48, width: 120, marginRight: 10 }}
                color="primary" variant="contained" >SyncCard</Button>
            </IconButton>
            <IconButton>
              <DeleteIcon onClick={() => handleShowConfirm(params?.row)} />
            </IconButton>
          </>
        );
      }
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Premium Api Key" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            {/* <Filter filter={filter} setFilter={setFilterToParams} /> */}
            <Button onClick={() => {
              setCurrentItem(undefined)
              handleClickOpen()
            }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">ADD</Button>
            {/* <Button onClick={() => setOpenConfirm(false)} disabled={!selectedRow.length} style={{ height: 48, width: 120 }} variant="contained" color="secondary">Delete</Button> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Premium List" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={premiumApiKey?.map((item, i) => ({ ...item, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
      {
        open && <FormCreatePremiumApiKey currentItem={currentItem} open={open} onClose={handleClose} onSubmitForm={(data) => handleCreatePremiumApiKey(data)} />
      }
      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete ncc?" onSubmit={() => { handleDeletePremiumApikey(currentItem) }} />

    </>
  );
}
