import request from "../helpers/request";

export function getListApi(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/user/get-api-key",
    method: "get",
    params,
  });
}

export function createApi(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/user/create-api-key`,
    method: "post",
    data
  });
}


export function deleteApi(id) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/user/delete-api-key/${id}`,
    method: "delete",
  });
}

