import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function MailNuoiAddDialog({ open, onClose, onSubmit }) {
  const classes = useStyles();
  const [inputText, setInputText] = useState("");

  return (
    <div>
      <form>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {"Add emails"}
          </DialogTitle>
          {/* <DialogContent> 
          <div style={{ float: 'right' }}>
            {!channel?.length ? <AddBoxIcon onClick={() => { }} style={{ cursor: 'pointer' }} color="secondary" /> : <></>}
          </div>
        </DialogContent> */}
          <TextField
            style={{ margin: 10 }}
            id="outlined-multiline-static"
            label="Add short links"
            multiline
            minRows={20}
            placeholder="Mỗi email 1 dòng"
            onChange={(event) => {
              setInputText(event.target.value);
            }}
          />
          <DialogActions>
            <Button autoFocus onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={inputText.length > 0 ? false : true}
              onClick={() => {
                var lines = inputText.split("\n");
                onSubmit({ data: lines });
                onClose();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
