import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { proxyV2ChangeIpDuration } from "../../../apis/proxies";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function DialogChangeIpDuration({ open, onClose, uids }) {
  const classes = useStyles();
  const [changeIpDuration, setChangeIpDuration] = React.useState(0);

  return (
    <div>
      <form>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Duration </DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                hire
              </InputLabel>
              <Select
                value={changeIpDuration}
                onChange={(event) => {
                  setChangeIpDuration(event.target.value);
                }}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={180}>
                  <em>3 phút </em>
                </MenuItem>
                {[
                  { name: "3 phút", value: 180 },
                  { name: "5 phút", value: 300 },
                  { name: "7 phút", value: 420 },
                  { name: "10 phút", value: 600 },
                  { name: "15 phút", value: 900 },
                  { name: "20 phút", value: 1200 },
                  { name: "23 phút", value: 1380 },
                  { name: "25 phút", value: 1500 },
                  { name: "27 phút", value: 1620 },
                  { name: "30 phút", value: 1800 },
                ]?.map((pk) => (
                  <MenuItem key={pk.name} value={pk.value}>
                    {pk.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                const data = {
                  uids: uids,
                  change_ip_duration: changeIpDuration,
                };
                await proxyV2ChangeIpDuration(data);
                onClose();
              }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
