import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Chip from '@material-ui/core/Chip';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";


// components
import PageTitle from "@/components/PageTitle/PageTitle";
import Widget from "@/components/Widget/Widget";

// data
import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "@/components/Table/Table";
import DialogConfirm from "@/components/DialogConfirm/DialogConfirm";
import { useParams } from "react-router";
import { createPackageVideo, getListPackageVideo, updatePackageVideo } from "@/apis/packages";
import DialogCreateVideo from "./CreateVideo";
import { deleteAllPackageVideo, deletePackageVideo } from "../../../../../apis/packages";
import { ShowForPermission } from "../../../../../components/Permission/Permission";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, name: '', status: ''
}
let timer
export default function PackageVideo() {
  const classes = useStyles();
  const { id } = useParams()
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [currentVideo, setCurrentVideo] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setCurrentVideo(undefined)
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  const [videos, setPackageVideos] = useState([])

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      (async () => {
        setLoading(true);
        const list = await getListPackageVideo(filter, id)
        setLoading(false);
        setTotal(list?.total)
        setPackageVideos(list?.data?.map(item => ({ ...item, keywords: item?.keywords?.split("\n") })))
      })();
    }, 300)
  }, [filter, id]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleCreatePackageVideo = async (data) => {
    const reformatData = data.map(item => {
      return { ...item, duration: parseInt(item.duration, 10), keywords: Array.isArray(item?.keywords) ? item?.keywords?.join('\n') : item?.keywords }
    })
    await createPackageVideo({ data: reformatData }, id)
    history.push(`/app/package/${id}/video`)
    setOpen(false);
  }

  const handleUpdatePackageVideo = async (data) => {
    const reformatData = { ...data[0], keywords: data[0].keywords.join('\n'), duration: parseInt(data[0].duration, 10) }
    await updatePackageVideo(reformatData, id, data[0]?.uid)
    history.push(`/app/package/${id}/video`)
    setOpen(false);
  }
  const handleDeletePackageVideo = async () => {
    await deletePackageVideo({ uids: selectedRow }, id)
    const list = await getListPackageVideo(filter, id)
    setPackageVideos(list?.data?.map(item => ({ ...item, keywords: item?.keywords?.split("\n") })))
    setTotal(list?.total)
    setOpenConfirm(false);
  }
  const handleDeleteAllPackageVideo = async () => {
    await deleteAllPackageVideo(id)
    const list = await getListPackageVideo(filter, id)
    setPackageVideos(list?.data?.map(item => ({ ...item, keywords: item?.keywords?.split("\n") })))
    setTotal(list?.total)
    setOpenConfirm(false);
  }
  const handleShowConfirm = () => {
    setOpenConfirm(true)
  }

  const handleEdit = (row) => {
    setOpen(true)
    setCurrentVideo(row)
  }

  function openDialog() {
    document?.getElementById("fileid")?.click();
  }

  const onFileChange = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = function (progressEvent) {
        // By lines
        var lines = (this?.result).split("\n");
        let listVideo = [];
        for (var line = 0; line < lines.length; line++) {
          const stringArray = lines[line].split("\t");
          const item = {
            yt_elm_keyword: stringArray[0] || "",
            yt_elm_id: stringArray[1] || "",
            channel_id: stringArray[2] || "",
            duration: parseInt(stringArray[3], 10),
            keywords: stringArray[4] || "",
            loc_thresholds: stringArray[5] || "",
            topic: stringArray[6] || "",

          };
          stringArray[0] && listVideo.push(item);
        }
        handleCreatePackageVideo(listVideo);
      };
      reader.readAsText(file);
    }
  };
  const handleExport = () => {
    const element = document.createElement("a");
    let plainText = ``
    videos.map(item => {
      if (selectedRow.includes(item.uid)) {
        plainText += `${item.yt_elm_keyword}\t${item.yt_elm_id}\t${item.duration}\n`
      }
      return item
    })
    const file = new Blob(
      [plainText],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "videos.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const columns = [
    { field: 'stt', headerName: 'stt', width: 30, editable: false },

    {
      field: 'yt_elm_keyword', headerName: 'name', width: 120,
      flex: 0.7,
      editable: false
    },
    {
      field: 'yt_elm_id',
      headerName: 'yt id',
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'channel_id',
      headerName: 'channel_id',
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'duration', headerName: 'duration', width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: 'keywords', headerName: 'keywords', width: 120,
      flex: 0.7,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return params?.row?.keywords?.map(key => <Chip
          label={key}
          key={key}
        />)
      }
    },
    {
      field: 'search_counter', headerName: 'search', width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: 'suggest_counter', headerName: 'suggest', width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: 'loc_thresholds', headerName: 'loc_time', width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: 'topic', headerName: 'topic', width: 120,
      flex: 0.3,
      editable: false,
    },
    // {
    //   field: 'action', headerName: ' ', width: 30, type: 'number',
    //   flex: 0.3,
    //   editable: false,
    //   renderCell: (params) => {
    //     return (
    //       <ShowForPermission permission="packages-yt_videos-add">
    //         <IconButton>
    //           <EditIcon onClick={() => handleEdit(params?.row)} />
    //         </IconButton>
    //       </ShowForPermission>
    //     );
    //   }
    // },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Videos" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Filter filter={filter} setFilter={setFilterToParams} />
            <did>
              <ShowForPermission permission="packages-yt_videos-delete"><Button disabled={!selectedRow.length} onClick={() => { handleShowConfirm(); setIsDeleteAll(false) }} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">Delete</Button></ShowForPermission>
              <ShowForPermission permission="packages-yt_videos-delete"><Button onClick={() => { handleShowConfirm(); setIsDeleteAll(true) }} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">DeleteAll</Button></ShowForPermission>
              <ShowForPermission permission="packages-yt_videos-export"><Button disabled={!selectedRow.length} onClick={handleExport} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">Export</Button></ShowForPermission>
              <ShowForPermission permission="packages-yt_videos-add"><Button onClick={openDialog} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">Import</Button></ShowForPermission>
              <ShowForPermission permission="packages-yt_videos-add"><Button onClick={handleClickOpen} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">Add</Button></ShowForPermission>
            </did>
          </Box>
        </Grid>
        <Grid item xs={12} >
          <Box display="flex" flexGrow="1">
            {/* <div style={{ width: 180, marginRight: 20 }}>
              <MenuLink id={id} />
            </div> */}
            <div style={{ width: "100%" }} >
              <Widget title="Video List" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                <DataTable
                  total={total}
                  columns={columns}
                  data={videos?.map((user, i) => ({ ...user, id: user.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
                  loading={loading}
                  setSelectedRow={setSelectedRow}
                  filter={filter}
                  setFilter={setFilterToParams}
                />
              </Widget>
            </div>
          </Box>
        </Grid>
      </Grid>
      <DialogCreateVideo
        video={currentVideo ? [currentVideo] : []}
        open={open} setOpen={setOpen}
        onSubmit={(data) => currentVideo ? handleUpdatePackageVideo(data) : handleCreatePackageVideo(data)}
      />
      <DialogConfirm
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        message={isDeleteAll ? `Are you sure delete all videos?` : `Are you sure delete selected videos?`}
        onSubmit={() => { !isDeleteAll ? handleDeletePackageVideo() : handleDeleteAllPackageVideo() }}
      />
      <input onChange={onFileChange} id="fileid" type="file" hidden />
    </>
  );
}
