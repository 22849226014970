import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function DialogPackageRunningType({ open, closeDialog, onUpdate, currentPackage }) {
    const classes = useStyles();
    const [runningType, setRunningType] = React.useState(currentPackage?.package_running_type || '');

    console.log()
    return (
        <div>
            <form>
                <Dialog
                    fullWidth maxWidth="xs"
                    open={open}
                    onClose={closeDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit Running Type</DialogTitle>
                    <DialogContent>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Package Running Type
                            </InputLabel>
                            <Select
                                value={runningType}
                                onChange={
                                    (event) => { setRunningType(event.target.value); }
                                }
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    [{ name: "GENERAL" },
                                    { name: "INDIVIDUAL" },
                                    ].map(runningType => <MenuItem key={runningType.name} value={runningType.name}>{runningType.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color="primary">
                            Cancel
                        </Button>
                        <Button
                            disabled={!runningType}
                            onClick={() => {
                                onUpdate(currentPackage.uid, { ...currentPackage, package_running_type: runningType })
                            }} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}