import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    ncc: yup
        .string('Enter your package ncc')
        .required('Package ncc is required'),
});
export default function FormCreateSMSNcc({ open, onClose, onSubmitForm, ncc }) {

    const formik = useFormik({
        initialValues: ncc || {
            ncc: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmitForm(values)
        },
    });
    return (
        <div>
            <form>
                <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create package</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="name"
                            name="ncc"
                            label="NCC name"
                            value={formik.values.ncc}
                            onChange={formik.handleChange}
                            error={formik.touched.ncc && Boolean(formik.errors.ncc)}
                            helperText={formik.touched.ncc && formik.errors.ncc}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            fullWidth
                            id="service_code"
                            name="service_code"
                            label="Service code"
                            value={formik.values.service_code}
                            onChange={formik.handleChange}
                            error={formik.touched.service_code && Boolean(formik.errors.service_code)}
                            helperText={formik.touched.service_code && formik.errors.service_code}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            fullWidth
                            id="country_code"
                            name="country_code"
                            label="Country code"
                            value={formik.values.country_code}
                            onChange={formik.handleChange}
                            error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                            helperText={formik.touched.country_code && formik.errors.country_code}
                            onBlur={formik.handleBlur}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={formik.handleSubmit} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}