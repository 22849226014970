import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
} from "@material-ui/core";

import { orderDcomProxyV2 } from "../../../apis/proxies";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function DialogCreate({ open, onClose, filter, listData }) {
  const classes = useStyles();
  const [orderNumber, setOrderNumber] = React.useState(0);
  const [userProxy, setUserProxy] = React.useState("");
  const [passProxy, setPassProxy] = React.useState("");
  const [isRandom, setIsRandom] = React.useState(true);
  const [changeIpDuration, setChangeIpDuration] = React.useState(0);
  const [limitUsage, setLimitUsage] = React.useState("");

  return (
    <div>
      <form>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Duration </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              id="orderNumber"
              name="orderNumber"
              label="orderNumber"
              type={"number"}
              value={orderNumber}
              onChange={(event) => {
                var orderNumber = parseInt(`${event.target.value}`, 10);
                setOrderNumber(orderNumber);
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRandom}
                  onChange={() => {
                    setIsRandom(!isRandom);
                  }}
                />
              }
              label="Random user pass"
            />

            <TextField
              fullWidth
              disabled={isRandom}
              id="userProxy"
              name="userProxy"
              label="userProxy"
              value={userProxy}
              onChange={(event) => setUserProxy(event.target.value)}
            />
            <TextField
              fullWidth
              disabled={isRandom}
              id="passProxy"
              name="passProxy"
              label="passProxy"
              value={passProxy}
              onChange={(event) => setPassProxy(event.target.value)}
            />

            <Grid container spacing={1} xs={12}>
              <Grid item xs={6}>
                <div
                  style={{
                    lineHeight: "normal !important",
                    padding: 5,
                    textAlign: "center",
                    fontSize: 20,
                  }}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                    >
                      Thời gian đổi ip tối đa
                    </InputLabel>
                    <Select
                      value={changeIpDuration}
                      onChange={(event) => {
                        setChangeIpDuration(event.target.value);
                      }}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={0}>
                        <em>30 phút</em>
                      </MenuItem>
                      {[
                        { name: "3 phút", value: 180 },
                        { name: "5 phút", value: 300 },
                        { name: "7 phút", value: 420 },
                        { name: "10 phút", value: 600 },
                        { name: "15 phút", value: 900 },
                        { name: "20 phút", value: 1200 },
                        { name: "23 phút", value: 1380 },
                        { name: "25 phút", value: 1500 },
                        { name: "27 phút", value: 1620 },
                        { name: "30 phút", value: 1800 },
                      ]?.map((pk) => (
                        <MenuItem key={pk.name} value={pk.value}>
                          {pk.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    lineHeight: "normal !important",
                    padding: 5,
                    textAlign: "center",
                    fontSize: 20,
                  }}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                    >
                      Thời gian sử dụng
                    </InputLabel>
                    <Select
                      value={limitUsage}
                      onChange={(event) => {
                        setLimitUsage(event.target.value);
                      }}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"1DAY"}>
                        <em>Thời gian sử dụng</em>
                      </MenuItem>
                      {[
                        { name: "1 ngày", value: "1DAY" },
                        { name: "3 ngày", value: "3DAYS" },
                        { name: "7 ngày", value: "7DAYS" },
                        { name: "14 ngày", value: "14DAYS" },
                        { name: "30 ngày", value: "30DAYS" },
                      ]?.map((pk) => (
                        <MenuItem key={pk.name} value={pk.value}>
                          {pk.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                const data = {
                  order_number: orderNumber,
                  change_ip_duration: changeIpDuration,
                  user_proxy: userProxy.toLocaleLowerCase(),
                  pass_proxy: passProxy.toLocaleLowerCase(),
                  usage_duration: 86400,
                  bandwidth_limit: 1073741824 * 30,
                  is_random: isRandom,
                };
                if (limitUsage === "3DAYS") {
                  data.usage_duration = 86400 * 3;
                  data.bandwidth_limit = 1073741824 * 500;
                }
                if (limitUsage === "7DAYS") {
                  data.usage_duration = 86400 * 7;
                  data.bandwidth_limit = 1073741824 * 500;
                }
                if (limitUsage === "14DAYS") {
                  data.usage_duration = 86400 * 14;
                  data.bandwidth_limit = 1073741824 * 500;
                }
                if (limitUsage === "30DAYS") {
                  data.usage_duration = 86400 * 30;
                  data.bandwidth_limit = 1073741824 * 500;
                }
                await orderDcomProxyV2(data);
                listData(filter);
                onClose();
              }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
