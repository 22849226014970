import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OPTIONS = [{
  name: 'ALL',
  value: 'ALL'
},
{
  name: 'ENABLE',
  value: 'ENABLE'
},
{
  name: 'DISABLE',
  value: 'DISABLE'
}]
export default function Filter({ filter, setFilter }) {
  const classes = useStyles();
  const [search, setsearch] = useState(filter)

  useEffect(() => {
    setsearch(filter)
  }, [filter])
  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={() => setFilter({ ...filter, ...search, time: Math.random() })}>
      <TextField label="Machine name" value={search.machine_name} onChange={(event) => setsearch({ ...search, machine_name: event.target.value })} />
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Notify</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="nofity"
          value={search?.notify}
          onChange={(event) => setsearch({ ...search, notify: event.target.value })}
        >
          {
            OPTIONS?.map((item) => <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>)
          }
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary">Search</Button>
    </form>
  );
}