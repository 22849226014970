import React, { useState, useEffect, useCallback } from "react";
import { Grid, Box, MenuItem, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Refresh } from "@material-ui/icons";

import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import DataTable from "../../../components/Table/Table";
import {
  listDcomV2,
  resetDcomProxyV2,
  statisticDcomProxyV2,
} from "../../../apis/proxies";
import { convertTimestampToDateTime } from "../../../helpers/utils";
import Filter from "./Filter";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  search: "",
  status: "RUNNING",
  machine: "",
};
const defaultStatistic = {
  RESET: 0,
  START: 0,
  DISCONNECT: 0,
  RUNNING: 0,
  NO_NETWORK: 0,
  SIM_ERROR: 0,
};
export default function DcomV2() {
  const classes = useStyles();
  const [proxies, setProxies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [totalStatus, setTotalStatus] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [statisticStatus, setStatisticStatus] = useState(defaultStatistic);
  const location = useLocation();
  const history = useHistory();

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
        status: filterParams.status,
        search: filterParams.search,
        machine: filterParams.machine,
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  async function listData(filter) {
    var statisticStatus = await statisticDcomProxyV2(filter);
    const mStatisticStatus = new Map(
      statisticStatus.map((obj) => {
        return [obj.status, obj.count_status];
      }),
    );
    setStatisticStatus({
      RUNNING: mStatisticStatus.get("RUNNING") || 0,
      RESET: mStatisticStatus.get("RESET") || 0,
      START: mStatisticStatus.get("START") || 0,
      DISCONNECT: mStatisticStatus.get("DISCONNECT") || 0,
      NO_NETWORK: mStatisticStatus.get("NO_NETWORK") || 0,
      SIM_ERROR: mStatisticStatus.get("SIM_ERROR") || 0,
    });

    setTotalStatus(
      (mStatisticStatus.get("RUNNING") || 0) +
        (mStatisticStatus.get("RESET") || 0) +
        (mStatisticStatus.get("START") || 0) +
        (mStatisticStatus.get("DISCONNECT") || 0) +
        (mStatisticStatus.get("NO_NETWORK") || 0) +
        (mStatisticStatus.get("SIM_ERROR") || 0),
    );
    const list = await listDcomV2(filter);
    setProxies(list?.data);
    setTotal(list?.total);
  }
  useEffect(() => {
    listData(filter);

    const timer1 = setInterval(async () => {
      listData(filter);
    }, 5000);

    return () => {
      clearInterval(timer1);
    };
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleColor = (status) => {
    if (status === "RUNNING" || status === "CONNECTED") {
      return "#33CC66";
    }

    if (
      status === "CANCELED" ||
      status === "DISCONNECT" ||
      status === "LIMITED"
    ) {
      return "#FF6666";
    }

    if (status === "NO_NETWORK") {
      return "#FF9900";
    }
    if (status === "START" || status === "RESET") {
      return "#FFCC33";
    }
    return "white";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      flex: 0.1,
      editable: false,
    },
    {
      field: "usage_status",
      headerName: "usage_status",
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal",
            }}
          >
            <div
              style={{
                lineHeight: "normal !important",
              }}
            >
              {params?.row?.machine}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
              }}
            >
              {params?.row?.imei}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
              }}
            >
              {params?.row?.dcom_name}
            </div>
          </div>
        );
      },
    },
    {
      field: "isp",
      headerName: "isp",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.ip}
            </div>
            <div
              style={{ lineHeight: "normal !important", fontWeight: "bold" }}
            >
              {params?.row?.isp}
            </div>
            <div
              style={{ lineHeight: "normal !important", fontWeight: "bold" }}
            >
              {params?.row?.network_type}
            </div>
            <div
              style={{ lineHeight: "normal !important", fontWeight: "bold" }}
            >
              {params?.row?.dis_sms}
            </div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "status",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div
              style={{
                lineHeight: "normal !important",
                backgroundColor: handleColor(params?.row?.status),
              }}
            >
              {params?.row?.status}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
              }}
            >
              {params?.row?.dis_sms}
            </div>
          </div>
        );
      },
    },
    {
      field: "usage_count",
      headerName: "usage_count",
      flex: 0.1,
      editable: false,
      sortable: false,
    },
    {
      field: "network",
      headerName: "network",
      flex: 0.2,
      editable: false,
      sortable: false,
    },
    {
      field: "wakeup_at",
      headerName: "wakeup_at",
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {convertTimestampToDateTime(params?.row?.wakeup_at)}
            </div>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "action",
      width: 120,
      type: "number",
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <Refresh
                onClick={async () => {
                  await resetDcomProxyV2({ uids: [params?.row?.uid] });
                  await listData(filter);
                }}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  var menus = [];
  menus.push({
    name: "Noaction",
    cp: (
      <MenuItem onClick={() => {}}>
        {" "}
        <Typography>Noaction</Typography>{" "}
      </MenuItem>
    ),
  });

  menus.push({
    name: "Reset",
    cp: (
      <MenuItem onClick={() => {}}>
        {" "}
        <Typography>Reset</Typography>{" "}
      </MenuItem>
    ),
  });

  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="DcomProxy" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Filter filter={filter} setFilter={setFilterToParams} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} xs={14}>
            <Grid item xs={2}>
              <div
                style={{
                  lineHeight: "normal !important",
                  padding: 5,
                  textAlign: "center",
                  backgroundColor: "#33CC66",
                  fontSize: 20,
                }}
              >
                RUNNING: <strong>{statisticStatus.RUNNING}</strong>(
                {((statisticStatus.RUNNING * 100) / totalStatus).toFixed(1)}%)
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  lineHeight: "normal !important",
                  padding: 5,
                  textAlign: "center",
                  backgroundColor: "#FF6666",
                  fontSize: 20,
                }}
              >
                DISCONNECT: <strong>{statisticStatus.DISCONNECT}</strong>(
                {((statisticStatus.DISCONNECT * 100) / totalStatus).toFixed(1)}
                %)
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  lineHeight: "normal !important",
                  padding: 5,
                  textAlign: "center",
                  backgroundColor: "#FF6666",
                  fontSize: 20,
                }}
              >
                SIM_ERROR: <strong>{statisticStatus.SIM_ERROR}</strong>(
                {((statisticStatus.SIM_ERROR * 100) / totalStatus).toFixed(1)}
                %)
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  lineHeight: "normal !important",
                  padding: 5,
                  textAlign: "center",
                  backgroundColor: "#FF9900",
                  fontSize: 20,
                }}
              >
                NO_NETWORK: <strong>{statisticStatus.NO_NETWORK}</strong>(
                {((statisticStatus.NO_NETWORK * 100) / totalStatus).toFixed(1)}
                %)
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  lineHeight: "normal !important",
                  padding: 5,
                  textAlign: "center",
                  backgroundColor: "#FFCC33",
                  fontSize: 20,
                }}
              >
                START: <strong>{statisticStatus.START}</strong>(
                {((statisticStatus.START * 100) / totalStatus).toFixed(1)}
                %)
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  lineHeight: "normal !important",
                  padding: 5,
                  textAlign: "center",
                  backgroundColor: "#FFCC33",
                  fontSize: 20,
                }}
              >
                RESET: <strong>{statisticStatus.RESET}</strong>(
                {((statisticStatus.RESET * 100) / totalStatus).toFixed(1)}
                %)
              </div>
            </Grid>
          </Grid>
          <Widget
            title={"Total:  " + totalStatus}
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            menus={menus}
          >
            <DataTable
              columns={columns}
              data={proxies?.map((item, i) => ({
                ...item,
                id: item.uid,
                stt: i + 1 + (filter?.page - 1) * filter?.limit,
              }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
              rowHeight={80}
            />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
