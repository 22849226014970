import React, { useState, useEffect, useCallback } from "react";
import { Grid, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import TextField from '@mui/material/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";


// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data 
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "../../../components/Table/Table";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";
import { PremiumCardInsert, PremiumCardList, PremiumCardDeleteAll } from "../../../apis/premiums";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function PremiumCard() {
  const classes = useStyles();
  const [premiumCard, setPremiumCard] = useState([])
  const [loading, setLoading] = useState(true);
  const [fullLoading, setFullLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [inputText, setInputText] = useState("")
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );


  useEffect(() => {
    (async () => {
      setLoading(true);
      const list = await PremiumCardList(filter)
      setLoading(false);
      setPremiumCard(list?.data)
      setTotal(list?.total)
    })();
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);


  const handleShowConfirm = () => {
    setOpenConfirm(true)
  }

  const handleDeletePremiumCard = async () => {
    setFullLoading(true);
    await PremiumCardDeleteAll()
    const list = await PremiumCardList(filter)
    setPremiumCard(list?.data)
    setTotal(list?.total)
    setFullLoading(false);
    setOpenConfirm(false);
  }
  const columns = [
    { field: 'stt', headerName: 'stt', width: 30, editable: false, sortable: false },
    {
      field: 'card_pan',
      headerName: 'card_pan',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'card_name',
      headerName: 'card_name',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'expiration',
      headerName: 'expiration',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'cvv',
      headerName: 'cvv',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'zip_code',
      headerName: 'zip_code',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'amount',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'counter',
      headerName: 'counter',
      flex: 0.2,
      editable: false,
    },
    // {
    //   field: 'action', headerName: 'action', width: 120, type: 'number',
    //   flex: 0.3,
    //   editable: false,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <IconButton>
    //           <DeleteIcon onClick={() => handleShowConfirm(params?.row)} />
    //         </IconButton>
    //       </>
    //     );
    //   }
    // },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Premium Card" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            {/* <Filter filter={filter} setFilter={setFilterToParams} /> */}
            <Button onClick={() => { setOpenAddDialog(true) }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Add</Button>
            <Button onClick={() => {
              handleShowConfirm()
            }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">DELETE ALL</Button>
            {/* <Button onClick={() => setOpenConfirm(false)} disabled={!selectedRow.length} style={{ height: 48, width: 120 }} variant="contained" color="secondary">Delete</Button> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Premium List" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={premiumCard?.map((card, i) => ({ ...card, id: card.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              // setSelectedRow={setSelectedRow}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>


      <Dialog
        open={openAddDialog}
        onClose={() => { }}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {/* {`Add google api keys`} */}
        </DialogTitle>
        {/* <DialogContent> 
          <div style={{ float: 'right' }}>
            {!channel?.length ? <AddBoxIcon onClick={() => { }} style={{ cursor: 'pointer' }} color="secondary" /> : <></>}
          </div>
        </DialogContent> */}
        <TextField
          style={{ margin: 10 }}
          id="outlined-multiline-static"
          label="Add premium cards"
          multiline
          rows={20}
          placeholder="1 line for 1 key"
          alignContent="10"
          onChange={(event) => {
            setInputText(event.target.value)
          }}
        />
        <DialogActions>
          <Button autoFocus onClick={() => { setOpenAddDialog(false) }} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained" color="primary" disabled={inputText.length > 0 ? false : true}
            onClick={async () => {
              setLoading(true);
              var lines = inputText.split("\n")
              await PremiumCardInsert({ data: lines })
              const list = await PremiumCardList(filter)
              setLoading(false);
              setPremiumCard(list?.data)
              setTotal(list?.total)
              setOpenAddDialog(false)
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete cards ?" onSubmit={() => { handleDeletePremiumCard() }} />

    </>
  );
}
