import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";


// components
import PageTitle from "@/components/PageTitle/PageTitle";
import Widget from "@/components/Widget/Widget";

// data
import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "@/components/Table/Table";
import DialogConfirm from "@/components/DialogConfirm/DialogConfirm";
import { useParams } from "react-router";
import CreateComment from "./CreateComment";
import {
  createComments,
  deleteComments,
  deleteAllComments,
  getListComment,
}
  from "../../../../../apis/packages";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, name: '', status: ''
}
export default function PackagePlayList() {
  const classes = useStyles();
  const { id } = useParams()
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDeleteAllConfirm, setOpenDeleteAllConfirm] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [selectedRow, setSelectedRow] = useState([]);
  const location = useLocation();
  const history = useHistory()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  const [comments, setComments] = useState([])

  useEffect(() => {
    (async () => {
      setLoading(true);
      const list = await getListComment(filter, id)
      setLoading(false);
      setTotal(list?.total)
      setComments(list?.data)
    })();
  }, [filter, id]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleCreateComment = async (data) => {
    const reformatData = data.map(item => {
      if (item.comment) {
        return `${item.comment}\t${item.topic}`
      }
      return item
    })
    await createComments(id, { data: reformatData })
    history.push(`/app/package/${id}/comments`)
    setOpen(false);
  }

  const handleDeleteComments = async () => {
    await deleteComments(id, { data: selectedRow })
    const list = await getListComment(filter, id)
    setComments(list?.data)
    setTotal(list?.total)
    setOpenConfirm(false);
  }

  const handleDeleteAllComments = async () => {
    await deleteAllComments(id, { data: selectedRow })
    const list = await getListComment(filter, id)
    setComments(list?.data)
    setTotal(list?.total)
    setOpenDeleteAllConfirm(false);
  }

  function openDialog() {
    document?.getElementById("fileid")?.click();
  }

  const onFileChange = (e) => {
    const file = e?.target?.files ? e?.target?.files[0] : null;
    e.target.value = null
    if (file) {
      const reader = new FileReader();
      reader.onload = function (progressEvent) {
        // By lines
        var comments = (this?.result).split("\n");
        handleCreateComment(comments);
      };
      reader.readAsText(file);
    }
  };
  const handleExport = () => {
    const element = document.createElement("a");
    let plainText = ``
    comments.map(item => {
      if (selectedRow.includes(item.id)) {
        plainText += `${item.comment}\n`
      }
      return item
    })
    const file = new Blob(
      [plainText],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "comments.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const columns = [
    { field: 'stt', headerName: 'STT', width: 150, editable: false },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 0.3,
      editable: false
    }, {
      field: 'topic',
      headerName: 'topic',
      flex: 0.3,
      editable: false
    },

  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Comments" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Filter filter={filter} setFilter={setFilterToParams} />
            <did>
              <Button disabled={!selectedRow.length} onClick={() => { setOpenConfirm(true) }} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">Delete</Button>
              <Button onClick={() => { setOpenDeleteAllConfirm(true); }} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">DeleteAll</Button>
              <Button disabled={!selectedRow.length} onClick={handleExport} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="secondary">Export</Button>
              <Button onClick={openDialog} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">Import</Button>
              <Button onClick={handleClickOpen} style={{ height: 48, width: 120, marginLeft: 10 }} variant="contained" color="primary">Add</Button>
            </did>
          </Box>
        </Grid>
        <Grid item xs={12} >
          <Box display="flex" flexGrow="1">
            {/* <div style={{ width: 180, marginRight: 20 }}>
              <MenuLink id={id} />
            </div> */}
            <div style={{ width: "100%" }} >
              <Widget title="Comments" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                <DataTable
                  total={total}
                  columns={columns}
                  data={comments?.map((user, i) => ({ ...user, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
                  loading={loading}
                  setSelectedRow={setSelectedRow}
                  filter={filter}
                  setFilter={setFilterToParams}
                />
              </Widget>
            </div>
          </Box>
        </Grid>
      </Grid>
      <CreateComment
        open={open} setOpen={setOpen}
        onSubmit={(data) => handleCreateComment(data)}
      />
      <DialogConfirm
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        message={`Are you sure delete comments?`}
        onSubmit={() => { handleDeleteComments() }}
      />
      <DialogConfirm
        open={openDeleteAllConfirm}
        onCancel={() => setOpenDeleteAllConfirm(false)}
        message={`Are you sure delete all ?`}
        onSubmit={() => { handleDeleteAllComments() }}
      />
      <input onChange={onFileChange} id="fileid" type="file" hidden />
    </>
  );
}
