import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";



// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";
import FormCreatePackageDialog from "./CreatePackage";

// data
import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "../../../components/Table/Table";
import moment from "moment";
import { Link } from "react-router-dom";
import { createPackage, deletePackage, getListPackage, listPackageTypes, updatePackage } from "../../../apis/packages";
import DialogConfirm from "../../../components/DialogConfirm/DialogConfirm";

import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DialogPackageRunningType from "./DialogPackageRunningType";
import { hasPermission } from "../../../helpers/utils";
import { NotificationsActive } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
let timer
export default function Packages() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialogPackageRunningType, setOpenDialogPackageRunningType] = useState(false)
  const [filter, setFilter] = useState(defaultParams);
  const [currentId, setCurrentId] = useState(undefined);
  const [currentPackage, setCurrentPackage] = useState(undefined);
  const [packageTypes, setPackageTypes] = useState([])
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const history = useHistory()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  const [packages, setPackage] = useState([])

  const listPackages = async () => {
    setLoading(true);
    const list = await getListPackage(filter)
    setPackage(list?.data)
    //======================
    var packageTypes = await listPackageTypes()
    setPackageTypes(packageTypes)
    console.log(packageTypes)

    setTotal(list?.total)
    setLoading(false);
  }

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      (async () => {
        await listPackages()
      })();
    }, 300)
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleCreatePackage = async (data) => {
    await createPackage(data)
    history.push('/app/packages')
    setOpen(false);
  }
  const handleUpdatePackage = async (uid, data) => {
    await updatePackage(uid, data)
    history.push('/app/packages')
    setOpen(false);
    setOpenDialogPackageRunningType(false)
  }
  const handleDeletePackage = async (id) => {
    await deletePackage(id)
    await listPackages()
    setOpenConfirm(false);
  }
  const handleShowConfirm = (id) => {
    setOpenConfirm(true)
    setCurrentId(id)
  }
  const goToDetail = (params) => {
    history.push(`/app/package/${params?.row?.uid}/video`)
  }

  const columns = [];
  // stt
  columns.push({ field: 'stt', headerName: 'stt', width: 30, editable: false, sortable: false })
  // name
  columns.push({
    field: 'name',
    headerName: 'name',
    flex: 0.3,
    editable: false,
    renderCell: (params) => <Link style={{ cursor: 'pointer' }} onClick={() => goToDetail(params)} to={{
      pathname: `/app/package/${params?.row?.uid}/setting`,
    }} ><strong>{params?.row?.name}</strong></Link>
  })
  // created_at
  columns.push({
    field: 'created_at', headerName: 'created_at', width: 120,
    flex: 0.3,
    renderCell: (params) => moment.unix(params.row.created_at).format('DD-MM-YYYY HH:MM A'),
    editable: false
  })

  // package_running_type
  if (hasPermission(['packages-info-add'])) {
    columns.push({
      field: 'package_running_type', headerName: 'package_running_type', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
          <div>{params?.row?.package_running_type}</div>
          <Tooltip title="Edit package" aria-label="add">
            <IconButton>
              <EditIcon onClick={
                () => {
                  setOpenDialogPackageRunningType(true)
                  setCurrentPackage(params?.row)
                }
              } />
            </IconButton>
          </Tooltip>
        </div>
      )
    })
  }

  if (hasPermission(['packages-type-show'])) {
    columns.push({
      field: 'package_type', headerName: 'package_type', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
          <div>{params?.row?.package_type}</div>
        </div>
      )
    })
  }
  if (hasPermission(['packages-info-add'])) {
    columns.push({
      field: 'is_shown', headerName: 'shown', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'right', width: '100%', }}>
          <Tooltip title="Edit package" aria-label="add">
            <NotificationsActive style={{ color: params?.row?.is_shown ? "blue" : "" }}
              onClick={async () => {
                await updatePackage(params?.row.uid, { ...params?.row, is_shown: !params?.row?.is_shown })
                await listPackages()
              }} />

          </Tooltip>
        </div>
      )
    })
    columns.push({
      field: 'is_sync', headerName: 'sync', width: 120,
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'right', width: '100%', }}>
          <Tooltip title="Edit package" aria-label="add">
            <NotificationsActive style={{ color: params?.row?.is_sync ? "blue" : "" }}
              onClick={async () => {
                await updatePackage(params?.row.uid, { ...params?.row, is_sync: !params?.row?.is_sync })
                await listPackages()
              }} />

          </Tooltip>
        </div>
      )
    })
  }
  // action
  columns.push({
    field: 'action', headerName: 'actions', width: 120, type: 'number',
    flex: 0.3,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          <IconButton>
            <DeleteIcon onClick={() => handleShowConfirm(params?.row?.uid)} />
          </IconButton>
        </>
      );
    }
  })

  return (
    <>
      <PageTitle title="Package" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Filter filter={filter} setFilter={setFilterToParams} />
            {
              hasPermission(['packages-info-add']) ?
                <Button onClick={handleClickOpen} style={{ height: 48, width: 120, }} variant="contained" color="secondary">Add</Button> : <></>
            }
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Package List" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={packages?.map((user, i) => ({ ...user, id: user.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              total={total}
              setFilter={setFilterToParams}
              setSelectedRow={() => { }}
            />
          </Widget>
        </Grid>
      </Grid>
      {open && <FormCreatePackageDialog
        open={open}
        onClose={handleClose}
        createPackage={(data) => { 
          handleCreatePackage(data)
        }}
        packageTypes={packageTypes} />}
      {openDialogPackageRunningType && <DialogPackageRunningType
        open={openDialogPackageRunningType}
        currentPackage={currentPackage}
        onUpdate={handleUpdatePackage}
        closeDialog={() => { setOpenDialogPackageRunningType(false) }}
      />}
      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you sure delete package?" onSubmit={() => { handleDeletePackage(currentId) }} />
    </>
  );
}
