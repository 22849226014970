import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Delete, Refresh } from "@material-ui/icons";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
import DataTable from "../../../components/Table/Table";
import {
  listDcomProxyV2,
  orderDcomProxyV2,
  changeIpDcomProxyV2,
  delDcomProxyV2,
} from "../../../apis/proxies";
import {
  convertTimestampToDateTime,
  formatBytes,
  getNowUnix,
  secondsToDhms,
} from "../../../helpers/utils";
import Filter from "./Filter";
import DialogChangeIpDuration from "./DialogChangeDuration";
import DialogCreate from "./DialogCreate";
import DialogChangeUserPass from "./DialogChangeUserPass";
import DialogChangeLimit from "./DialogChangeLimit";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  proxy_status: "",
  usage_status: "",
  search: "",
  sort_dir: "desc",
  sort_by: "created_at",
};
export default function DcomProxyV2() {
  const classes = useStyles();
  const [proxies, setProxies] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [fullLoading, setFullLoading] = useState(false);
  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openChangeDuration, setOpenChangeDuration] = useState(false);
  const [openChangeUserPass, setOpenChangeUserPass] = useState(false);
  const [openExtendLimit, setOpenExtendLimit] = useState(false);
  const location = useLocation();
  const history = useHistory();

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
        search: filterParams.search,
        usage_status: filterParams.usage_status,
        proxy_status: filterParams.proxy_status,
        sort_dir: "desc",
        sort_by: "created_at",
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  async function listData(filter) {
    const list = await listDcomProxyV2(filter);
    setProxies(list?.data);
    setTotal(list?.total);
  }
  useEffect(() => {
    listData(filter);

    const timer1 = setInterval(async () => {
      listData(filter);
    }, 5000);

    return () => {
      clearInterval(timer1);
    };
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  // const handleUpdateStatus = async (data) => {
  //   // setFullLoading(true)
  //   await SmsV2Update(data.id, data)
  //   // setFullLoading(false);
  // }

  const handleColor = (status) => {
    if (status === "RUNNING" || status === "CONNECTED") {
      return "#33CC66";
    }

    if (
      status === "CANCELED" ||
      status === "DISCONNECT" ||
      status === "LIMITED"
    ) {
      return "#FF6666";
    }
    if (status === "DONT_USE") {
      return "#BBBBBB";
    }

    if (status === "CHANGE_IP" || status === "START") {
      return "#FFCC33";
    }
    return "white";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      flex: 0.1,
      editable: false,
    },
    {
      field: "usage_status",
      headerName: "usage_status",
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal",
            }}
          >
            <div
              style={{
                lineHeight: "normal !important",
                backgroundColor: handleColor(params?.row?.usage_status),
              }}
            >
              {params?.row?.usage_status}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
                backgroundColor: handleColor(params?.row?.proxy_status),
              }}
            >
              {params?.row?.proxy_status}
            </div>
          </div>
        );
      },
    },
    {
      field: "device",
      headerName: "device",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.uid}
            </div>
            <div
              style={{ lineHeight: "normal !important", fontWeight: "bold" }}
            >
              {formatBytes(params?.row?.bandwidth_usage)}/
              {formatBytes(params?.row?.bandwidth_limit)} -
              {convertTimestampToDateTime(params?.row?.due_at)}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              <strong>{params?.row?.ip}</strong>{" "}
            </div>
          </div>
        );
      },
    },
    {
      field: "time",
      headerName: "time",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              create:
              <strong>{`${convertTimestampToDateTime(
                params?.row?.created_at,
              )}`}</strong>
              <strong>
                {getNowUnix() - params?.row?.created_at > 120
                  ? ""
                  : " - ( NEW )"}
              </strong>
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              change:{convertTimestampToDateTime(params?.row?.change_ip_at)}{" "}
              <strong>
                ({secondsToDhms(params?.row?.change_ip_duration)})
              </strong>
            </div>
            <div
              style={{ lineHeight: "normal !important", fontWeight: "bold" }}
            >
              start: {convertTimestampToDateTime(params?.row?.started_at)}
            </div>

            <div style={{ lineHeight: "normal !important" }}>
              {convertTimestampToDateTime(params?.row?.first_connected_at)}:
              <strong
                style={{
                  color:
                    params?.row?.first_connected_at -
                      params?.row?.change_ip_at >
                      20 ||
                    (params?.row?.proxy_status !== "CONNECTED" &&
                      getNowUnix() - params?.row?.change_ip_at > 20)
                      ? "red"
                      : "",
                }}
              >
                {params?.row?.first_connected_at - params?.row?.change_ip_at > 0
                  ? secondsToDhms(
                      params?.row?.first_connected_at -
                        params?.row?.change_ip_at,
                    )
                  : secondsToDhms(getNowUnix() - params?.row?.change_ip_at)}
              </strong>
              (
              {secondsToDhms(
                params?.row?.last_connected_at - params?.row?.started_at,
              )}
              )
            </div>
          </div>
        );
      },
    },
    {
      field: "public",
      headerName: "public",
      flex: 0.6,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.ssh_info}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
                marginTop: "5px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              {params.row.proxy_user
                ? `http://${params.row.proxy_user}:${params.row.proxy_pass}@${params.row.proxy_ip}:${params.row.proxy_port}`
                : `http://${params.row.proxy_ip}:${params.row.proxy_port}`}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
                marginTop: "5px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              {params.row.proxy_user
                ? `socks5://${params.row.proxy_user}:${params.row.proxy_pass}@${params.row.proxy_ip}:${params.row.sock5_port}`
                : `socks5://${params.row.proxy_ip}:${params.row.sock5_port}`}
            </div>

            <div style={{ lineHeight: "normal !important" }}>
              <strong>{params?.row?.link_change}</strong>
            </div>
          </div>
        );
      },
    },
    {
      field: "user",
      headerName: "user",
      flex: 0.2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.machine} | {params?.row?.imei}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
                marginTop: "5px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              {params?.row?.user_source}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.user_name}
            </div>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "action",
      width: 120,
      type: "number",
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <Refresh
                onClick={async () => {
                  await changeIpDcomProxyV2({ uids: [params?.row?.uid] });
                  await listData(filter);
                }}
              />
              {params?.row?.user_source === "CUSTOMER" ? (
                <></>
              ) : (
                <Delete
                  onClick={async () => {
                    await delDcomProxyV2({ uids: [params?.row?.uid] });
                    await listData(filter);
                  }}
                />
              )}
            </IconButton>
          </>
        );
      },
    },
  ];

  var menus = [];
  menus.push({
    name: "Change UserPass",
    cp: (
      <MenuItem
        onClick={async () => {
          setOpenChangeUserPass(true);
        }}
      >
        <Typography>Change UserPass</Typography>{" "}
      </MenuItem>
    ),
  });
  menus.push({
    name: "Change IP",
    cp: (
      <MenuItem
        onClick={async () => {
          await changeIpDcomProxyV2({ uids: selectedRow });
        }}
      >
        <Typography>Change IP</Typography>{" "}
      </MenuItem>
    ),
  });

  menus.push({
    name: "UpdateDuration",
    cp: (
      <MenuItem
        onClick={() => {
          setOpenChangeDuration(true);
        }}
      >
        <Typography>UpdateDuration</Typography>
      </MenuItem>
    ),
  });

  menus.push({
    name: "Extend Limit",
    cp: (
      <MenuItem
        onClick={async () => {
          setOpenExtendLimit(true);
        }}
      >
        <Typography>Extend Limit</Typography>{" "}
      </MenuItem>
    ),
  });
  menus.push({
    name: "Delete",
    cp: (
      <MenuItem
        onClick={async () => {
          await delDcomProxyV2({ uids: selectedRow });
          await listData(filter);
        }}
      >
        <Typography>Delete</Typography>
      </MenuItem>
    ),
  });

  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="DcomProxy" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Filter filter={filter} setFilter={setFilterToParams} />
            <Button
              onClick={async () => {
                setOpenCreate(true);
              }}
              style={{ height: 48, width: 120, marginRight: 10 }}
              variant="contained"
              color="primary"
            >
              Order
            </Button>
            <Button
              disabled={!selectedRow.length}
              onClick={() => {
                const element = document.createElement("a");
                let plainText = ``;
                const dataExport = proxies.filter((item) => {
                  if (selectedRow.includes(item.uid)) {
                    if (item.proxy_user) {
                      plainText += `http://${item.proxy_user}:${item.proxy_pass}@${item.proxy_ip}:${item.proxy_port}\t${item.link_change}\n`;
                    } else {
                      plainText += `http://${item.proxy_ip}:${item.proxy_port}\t${item.link_change}\n`;
                    }
                    // plainText += item.proxy_user
                    //   ? `http://${item.proxy_user}:${item.proxy_pass}@${item.proxy_ip}:${item.proxy_port}`
                    //   : `http://${item.proxy_ip}:${item.proxy_port}`
                    // plainText += `http://${item.proxy_user}:${item.proxy_pass}@${item.proxy_ip}:${item.proxy_port}\t${item.link_change}\n`;
                  }
                  return plainText;
                });
                const file = new Blob([plainText], { type: "text/plain" });
                element.href = URL.createObjectURL(file);
                element.download = "proxy-v2.txt";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
              }}
              style={{
                height: 48,
                width: 120,
                marginRight: 10,
              }}
              variant="contained"
              color="secondary"
            >
              Export
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget
            title="DcomProxyV2"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            menus={menus}
          >
            <DataTable
              columns={columns}
              data={proxies?.map((item, i) => ({
                ...item,
                id: item.uid,
                stt: i + 1 + (filter?.page - 1) * filter?.limit,
              }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
              rowHeight={80}
            />
          </Widget>
        </Grid>
      </Grid>

      {openCreate && (
        <DialogCreate
          open={openCreate}
          onClose={() => {
            setOpenCreate(false);
          }}
          filter={filter}
          listData={listData}
        />
      )}
      {openChangeUserPass && (
        <DialogChangeUserPass
          open={openChangeUserPass}
          onClose={() => {
            setOpenChangeUserPass(false);
          }}
          uids={selectedRow}
        />
      )}

      {openChangeDuration && (
        <DialogChangeIpDuration
          open={openChangeDuration}
          onClose={() => {
            setOpenChangeDuration(false);
          }}
          uids={selectedRow}
        />
      )}

      {openExtendLimit && (
        <DialogChangeLimit
          open={openExtendLimit}
          onClose={() => setOpenExtendLimit(false)}
          uids={selectedRow}
        />
      )}
    </>
  );
}
