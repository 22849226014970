import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingLeft: theme.spacing.unit
  },

  input: {
    display: 'none',
  },

  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },

});

function ButtonLoading(props) {
  const { classes, children, loading } = props;
  return (
    <Button {...props} variant="contained" className={classes.button}>
      {loading && <CircularProgress color="secondary" className={classes.circularProgress} size={20} />}
      {children}
    </Button>
  );
}


export default withStyles(styles)(ButtonLoading);
