import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";
import moment from "moment";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data
// import Filter from "./Filter";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import DataTable from "../../../components/Table/Table";
import {
  apiSmsHuuVanTransactionInsert,
  apiSmsHuuVanTransactionList,
} from "../../../apis/sms";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  search: "",
};

export default function SmsHuuVanTransactionList() {
  const classes = useStyles();
  const [proxies, setProxies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(defaultParams);
  const [fileInput, setFileInput] = useState(undefined);
  // const [selectedRow, setSelectedRow] = useState([]);
  // const [fullLoading, setFullLoading] = useState(false);
  // const mounted = React.useRef(false);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const history = useHistory();

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  useEffect(() => {
    async function listData() {
      const list = await apiSmsHuuVanTransactionList(filter);
      setProxies(list?.data);
      setTotal(list?.total);
    }
    listData();

    const timer1 = setInterval(async () => {
      const list = await apiSmsHuuVanTransactionList(filter);
      setTotal(list?.total);
      setProxies(list?.data);
    }, 5 * 1000);

    return () => {
      clearInterval(timer1);
    };
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleStatusColor = (status) => {
    if (status === "SUCCESS") {
      return "lime";
    }
    if (status === "CREATED") {
      return "#FFCC66";
    }
    if (status === "FAILED" || status === "TIMEOUT" || status === "CANCELED") {
      return "Silver";
    }
  };
  const columns = [
    { field: "stt", headerName: "STT", width: 150, editable: false },
    {
      field: "phone",
      headerName: "phone",
      width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: "otp",
      headerName: "otp",
      width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 120,
      flex: 0.3,
      renderCell: (params) =>
        moment
          .unix(params.row.created_at)
          .utcOffset("+0700")
          .format("DD-MM-YYYY hh:mm:ss A"),
      editable: false,
    },
    {
      field: "user",
      headerName: "user",
      width: 120,
      flex: 0.3,
      editable: false,
    },
    {
      field: "status",
      headerName: "status",
      width: 120,
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal !important",
              backgroundColor: handleStatusColor(params?.row?.status),
            }}
          >
            {" "}
            {params?.row?.status}
          </div>
        );
      },
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Hữu văn" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignContent="center"
            alignItems="center"
          >
            <Button
              onClick={async () => {
                await apiSmsHuuVanTransactionInsert();
                const list = await apiSmsHuuVanTransactionList(filter);
                setProxies(list?.data);
                setTotal(list?.total);
              }}
              style={{ height: 48, width: 120, marginRight: 10 }}
              variant="contained"
              color="primary"
            >
              Order Phone
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget
            title="Phone List"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
          >
            <DataTable
              columns={columns}
              data={proxies?.map((item, i) => ({
                ...item,
                id: item.uid,
                stt: i + 1 + (filter?.page - 1) * filter?.limit,
              }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={() => {}}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
