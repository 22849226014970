import React, { } from "react";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import TreeNodeWrap from "./TreeNode";
import {
    createPackageScenarios, getListPackageScenariosAction,
    getListPackageScenariosDetail, updatePackageScenarios
} from "../../../../../apis/packages";

const defaultScript = {
    name: "",
    weight: "",
    type: "",
    max_duration: "",
    package_uid: "",
    scripts: [
        {
            "parent_index": -1,
            "action": "END",
            "note": "End",
            "right": -1,
            "wrong": -1,
            curIndex: 0
        },
    ],
}

const CreateUpdateScript = (props) => {
    const { id } = props.match.params; // package_uid
    const { uid } = props.match.params; // scenario_uid
    const history = useHistory()
    const [data, setData] = React.useState(defaultScript);
    const [actions, setActions] = React.useState([]);
    const [scenarioType, setScenarioType] = React.useState("YOUTUBE")

    // const actions = useSelector((state) => state?.common?.listAction);


    React.useEffect(() => {
        uid && (async () => {
            const list = await getListPackageScenariosDetail(id, uid)
            setData(list)
            console.log(list.type)
            setScenarioType(list.type)

            const actions = await getListPackageScenariosAction(list.type)
            setActions([...actions.data])
        })();
    }, [id, uid]);

    const handleAddScript = React.useCallback(
        async (data) => {
            const json = uid ? await updatePackageScenarios(data, id, uid) : await createPackageScenarios({ data: [data] }, id)
            console.log(json)

            history.push(`/app/package/${id}/script`)
        },
        [history, id, uid]
    );

    return (
        <div>
            <Typography
                style={{
                    fontSize: 24,
                    textTransform: "uppercase",
                    fontWeight: 600,
                    padding: "15px",
                    borderBottom: "1px solid #ebebeb",
                }}
            >
                {uid ? "Sửa kịch bản" : "Thêm kịch bản mới"}
            </Typography>
            <TreeNodeWrap
                scenarioType={scenarioType}
                actions={[
                    ...actions,
                    {
                        id: 0,
                        code: "END",
                        note: "END",
                    },
                ]}
                data={data}
                handleAddScript={(data) => handleAddScript(data)}
            />
        </div>
    );
};

export default CreateUpdateScript;
