import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

// data
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { createApi, getListApi } from "../../../apis/api";
import FullLoading from "../../../components/Loading/FullLoading";

const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function Apis() {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(defaultParams);
  const [keyName, setKeyName] = useState("");

  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);


  const [apis, setApis] = useState([])

  useEffect(() => {
    (async () => {
      const data = await getListApi(filter)
      setLoading(false);
      setApis(data)
    })();
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [
        JSON.stringify(
          {
            partner: apis?.partner_uid,
            api_key: apis?.api_key,
            secret_key: apis?.secret_key,
            vm_folders: [],
          },
          null,
          2
        ),
      ],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "key.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const hanldeGenerate = async () => {
    setLoading(true);
    await createApi({ name: keyName })
    const data = await getListApi(filter)
    setApis(data)
    setLoading(false);
  }


  return (
    <div style={{ paddingLeft: 30 }}>
      <FullLoading open={loading} />
      <PageTitle title="APIs" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            id="name"
            onChange={(e) => {
              setKeyName(e.target.value);
            }}
            label="Nhập tên key"
            value={keyName}
            optional
            style={{ width: 300 }}
            formControlStyle={{ maxWidth: 300 }}
          />
          <Button
            type="submit"
            variant="contained"
            size="medium"
            style={{ minWidth: "180px", marginRight: "20px", height: 40 }}
            color="primary"
            disabled={!keyName}
            onClick={() => hanldeGenerate()}
          >
            GENERATE API KEY
          </Button>
          {/* <Button
            type="submit"
            variant="contained"
            size="medium"
            style={{ minWidth: "180px", marginRight: "20px", height: 40 }}
            color="secondary"
            disabled={!apis?.id}
            onClick={() => setOpenConfirm(true)}
          >
            DELETE
          </Button> */}

        </Grid>
        <Box
          style={{ alignItems: "flext-start", justifyContent: "space-between" }}
        >
          <div>
            <div style={{ margin: "15px 0" }}>API_KEY: {apis?.api_key}</div>
            <div style={{ margin: "15px 0" }}>
              SECRET_KEY: {apis?.secret_key}
            </div>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              style={{ minWidth: "180px", marginRight: "20px", height: 40 }}
              color="primary"
              disabled={!apis?.id}
              onClick={downloadTxtFile}
            >
              GENERATE JSON
            </Button>
          </div>
          {apis?.id > 0 && (
            <div>
              <pre>
                <code>
                  <pre>
                    {JSON.stringify(
                      {
                        partner: apis?.partner_uid,
                        api_key: apis?.api_key,
                        secret_key: apis?.secret_key,
                        vm_folders: [],
                      },
                      null,
                      2
                    )}
                  </pre>
                </code>
              </pre>
            </div>
          )}
        </Box>

      </Grid>
    </div>
  );
}
