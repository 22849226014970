import React, { useState, useEffect, } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Inbox as InboxIcon, AccountTree } from "@material-ui/icons";
import { Link, } from "react-router-dom";
import { hasPermission } from "../../../../helpers/utils";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Dot from "../Dot";
import PackageVideo from "../../../../pages/packages/PackageDetail/Video/List/PackageVideo";
import PackagePlayList from "../../../../pages/packages/PackageDetail/PlayList/List/PackagePlayList";
import PackageChannel from "../../../../pages/packages/PackageDetail/Channel/List/PackageChannel";
import PackageGGSearch from "../../../../pages/packages/PackageDetail/GoogleSearch/List/PackageGGSearch";
import PackageScript from "../../../../pages/packages/PackageDetail/Script/List/PackageScript";
import VMSList from "../../../../pages/machine/MachineDetail/VMS/List/VMSList";
import AndroidList from "../../../../pages/machine/MachineDetail/Android/List/AndroidList";
import CommentList from "../../../../pages/packages/PackageDetail/Comments/List/CommentList";
import PackageSetting from "../../../../pages/packages/PackageDetail/Setting/PackageSetting";
import PackageHashtag from "../../../../pages/packages/PackageDetail/Hashtag/List/PackageHashtag";
import { getPackage } from "../../../../apis/packages";
import PackageShortLink from "../../../../pages/packages/PackageDetail/ShortLink/ShortLink";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  roles,
  notAuth
}) {
  var classes = useStyles();
  // const { id } = useParams()
  // const location = useLocation()
  const listLocation = location.pathname.split("/")
  const id = listLocation[3]
  // console.log('useLocation', location)
  // local
  var [isOpen, setIsOpen] = useState(false);
  const [packageInfo, setPackageInfo] = useState({})



  useEffect(() => {
    (async () => {
      if (link === '/app/packages' && id && location.pathname.includes('package')) {
        // query package

        var packInfo = await getPackage(id)
        // console.log(packInfo)
        setPackageInfo(packInfo)
      }
    })()
  }, []);

  var isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);
  if (!notAuth && !hasPermission(roles)) {
    return <></>
  }
  if (type === "title")
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;
  if (link && link.includes('http')) {
    return (
      <ListItem
        button
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <a className={classes.externalLink} href={link}>
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </a>
      </ListItem>
    )
  }
  if (link === '/app/packages' && id && location.pathname.includes('package')) {

    const subs = [
      {
        id: 179,
        label: "Setting",
        link: `/app/package/${id}/setting`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: PackageSetting,
        roles: ['packages-settings-list'],
        packageTypes: ["GENERAL"],
      },
      {
        id: 179,
        label: "Video",
        link: `/app/package/${id}/video`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: PackageVideo,
        roles: ['packages-yt_videos-list'],
        packageTypes: ["YOUTUBE", "TIKTOK"],
      },
      {
        id: 179,
        label: "Playlist",
        link: `/app/package/${id}/playlist`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: PackagePlayList,
        roles: ['packages-yt_playlists-list'],
        packageTypes: ["YOUTUBE"],
      },
      {
        id: 179,
        label: "Channel",
        link: `/app/package/${id}/channel`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: PackageChannel,
        roles: ['packages-yt_channels-list'],
        packageTypes: ["YOUTUBE", "TIKTOK"],
      },
      {
        id: 179,
        label: "GGSearch",
        link: `/app/package/${id}/ggsearch`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: PackageGGSearch,
        roles: ['packages-gg_search-list'],
        packageTypes: ["YOUTUBE"],
      },
      {
        id: 179,
        label: "Hashtag",
        link: `/app/package/${id}/hashtag`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: PackageHashtag,
        roles: ['packages-hashtags-list'],
        packageTypes: ["YOUTUBE"],
      },
      {
        id: 179,
        label: "Scripts",
        link: `/app/package/${id}/script`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: PackageScript,
        roles: ['packages-scenarios-list'],
        packageTypes: ["YOUTUBE", "TIKTOK"],
      },
      {
        id: 179,
        label: "Comments",
        link: `/app/package/${id}/comments`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: CommentList,
        roles: ['packages-comments-list'],
        packageTypes: ["YOUTUBE", "TIKTOK"],
      },
      {
        id: 179,
        label: "Short Links",
        link: `/app/package/${id}/short-links`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: PackageShortLink,
        roles: ['packages-short_links-list'],
        packageTypes: ["SHORT_LINK"],
      },
    ]
    return (
      <>
        <ListItem
          button
          component={link && Link}
          // onClick={toggleCollapse}
          className={classes.link}
          to={link}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {icon ? icon : <InboxIcon />}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </ListItem>
        {subs && (
          <Collapse
            in={true}
            timeout="auto"
            unmountOnExit
            className={classes.nestedList}
          >
            <List component="div" disablePadding>
              {subs.map(childrenLink => {
                if (childrenLink.packageTypes.includes(packageInfo.package_type) || childrenLink.packageTypes.includes("GENERAL")) { 
                  return <SidebarLink
                    key={childrenLink && childrenLink.link}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    classes={classes}
                    nested
                    {...childrenLink}
                  />
                }
              }

              )}
            </List>
          </Collapse>
        )}
      </>
    )
  }
  if (link === '/app/machines' && location.pathname.includes('machine-detail') && id) {
    const subs = [
      {
        id: 191,
        label: "VMS",
        link: `/app/machine-detail/${id}/vms`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: VMSList,
        roles: ['machines-info-list']
      },
      {
        id: 192,
        label: "Android",
        link: `/app/machine-detail/${id}/android`,
        icon: <AccountTree />,
        exact: true,
        ContentComponent: AndroidList,
        roles: ['machines-info-list']
      },
    ]
    return (
      <>
        <ListItem
          button
          component={link && Link}
          // onClick={toggleCollapse}
          className={classes.link}
          to={link}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {icon ? icon : <InboxIcon />}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </ListItem>
        {subs && (
          <Collapse
            in={true}
            timeout="auto"
            unmountOnExit
            className={classes.nestedList}
          >
            <List component="div" disablePadding>
              {subs.map(childrenLink => (
                <SidebarLink
                  key={childrenLink && childrenLink.link}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  classes={classes}
                  nested
                  {...childrenLink}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    )
  }
  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        to={link}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
    );

  return (
    <>
      <ListItem
        button
        component={link && Link}
        onClick={toggleCollapse}
        className={classes.link}
        to={link}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon ? icon : <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" disablePadding>
            {children.map(childrenLink => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
