import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory, useParams } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// data 
import DataTable from "../../../components/Table/Table";
import { getUserLogs } from "../../../apis/users";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

const defaultParams = {
  page: 1, limit: 100, search: ''
}
export default function UserLogs() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
   const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  // const mounted = React.useRef(false);
  const location = useLocation();
  const history = useHistory()

  const { id } = useParams()
  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  const [userLogs, setUserLogs] = useState([])
  useEffect(() => {
    (async () => {
      setLoading(true)
      const list = await getUserLogs(id, filter)
      setLoading(false);
      setUserLogs(list?.data)
      setTotal(list?.total)
     })();
  }, [filter, id]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);




  const columns = [
    { key: 'stt', field: 'stt', headerName: 'STT', width: 150, editable: false },
    {
      key: 'partner_uid',
      field: 'partner_uid',
      headerName: 'Partner',
      editable: false,
      width: 200
    },
    {
      key: 'created_at',
      field: 'created_at', headerName: 'Created At',
      width: 200,
      renderCell: (params) => moment.unix(params.row.created_at).format('DD-MM-YYYY HH:MM A'),
      editable: false
    },
    {
      key: 'method',
      field: 'method', headerName: 'Method', width: 150,
      editable: false
    },
    {
      key: 'action',
      field: 'action', headerName: 'Actions', width: 200,
      flex: 0.3,
      editable: false,
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="User Logs" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="User logs" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              columns={columns}
              data={userLogs?.map((user, i) => ({ ...user, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              setSelectedRow={() => { }}
              filter={filter}
              setFilter={setFilterToParams}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
