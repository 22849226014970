import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Filter({ filter, setFilter }) {
  const classes = useStyles();
  const [search, setsearch] = useState(filter)


  useEffect(() => {
    setsearch(filter)
  }, [filter])
  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={(e) => {
      e.preventDefault()
      setFilter({ ...filter, ...search })
    }}>
      <TextField id="standard-basic" label="Name" value={search.name} onChange={(event) => setsearch({ ...search, name: event.target.value })} />
      <Button type="submit" variant="contained" color="primary">Search</Button>
    </form>
  );
}