import { Button, TextField, Grid, Paper, Typography } from "@material-ui/core";
import { SvgIcon } from "material-ui";
import React, { useEffect, useState } from "react";
import {
    countOrders,
    pushAction,
    requestOrderByMachine,
    getImage,
    getPosition,
} from "../../../../../apis/stream";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { checkOtp, updateSubMachine } from "../../../../../apis/machine";


export default function DialogOtp({ openDialog, onClose, subMachine }) {
    const [otpSecretKey, setOtpSecretKey] = useState("");
    const [otp, setOtp] = useState("");
    useEffect(() => {
        setOtpSecretKey(subMachine.acc_otp_secret)
    }, []);

    return (
        <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"> OTP:{otp} </DialogTitle>
            <DialogContent>
                <>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="otpSecretKey"
                                name="otpSecretKey"
                                label="otpSecretKey"
                                value={otpSecretKey}
                                onChange={(event) => {
                                    var value = event.target.value
                                    value = value.replaceAll("\t", "")
                                    value = value.replaceAll(" ", "")

                                    setOtpSecretKey(value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                style={{ height: 30, width: 100, marginRight: 10, display: 'display' }}
                                variant="contained" color="primary"
                                onClick={async () => {
                                    var otpResp = await checkOtp(otpSecretKey.replaceAll(" ", ""))
                                    console.log(otpResp)
                                    if (otpResp.otp) {
                                        setOtp(otpResp.otp)
                                    } else {
                                        setOtp("key lỗi")
                                    }
                                }} >
                                Get Otp
                            </Button>
                        </Grid>
                    </Grid>
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={async () => {
                    await updateSubMachine(subMachine.machine_name, subMachine.name, { otp_secret: otpSecretKey.replaceAll(" ", "") })
                    onClose()
                }} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
