import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    api_key: yup
        .string('Enter your api_key ncc')
        .required('api_key is required'),
});
export default function FormCreateSMSKey({ open, key, onClose, onSubmitForm, currentItem }) {
    const formik = useFormik({
        initialValues: currentItem || {
            api_key: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmitForm(values)
        },
    });
    return (
        <div>
            <form>
                <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create key</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="name"
                            name="api_key"
                            label="Key"
                            value={formik.values.api_key}
                            onChange={formik.handleChange}
                            error={formik.touched.api_key && Boolean(formik.errors.api_key)}
                            helperText={formik.touched.api_key && formik.errors.api_key}
                            onBlur={formik.handleBlur}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={formik.handleSubmit} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}