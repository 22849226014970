import request from "../helpers/request";

export function getListDynamicProxies(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dynamic",
    method: "get",
    params,
  });
}

export function createDynamic(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/dynamic`,
    method: "post",
    data,
  });
}

export function deleteAllDynamic() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/dynamic`,
    method: "delete",
  });
}

// =================================================
// ================ Static Proxy ===================
// =================================================
export function getListStaticProxies(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/static",
    method: "get",
    params,
  });
}

export function createStatic(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/static`,
    method: "post",
    data,
  });
}

export function deleteAllStatic() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/static`,
    method: "delete",
  });
}

// =================================================
// ================ Rotation Proxy ===================
// =================================================
export function getListRotationProxies(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/proxy_rotation",
    method: "get",
    params,
  });
}

export function createRotationProxies(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/proxy_rotation`,
    method: "post",
    data,
  });
}

export function deleteAllRotationProxies() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/proxy_rotation`,
    method: "delete",
  });
}

// =================================================
// ================ User Agent ===================
// =================================================
export function getListUserAgents(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/user_agents",
    method: "get",
    params,
  });
}

export function createUserAgents(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/user_agents`,
    method: "post",
    data,
  });
}

export function deleteAllUserAgents() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/user_agents`,
    method: "delete",
  });
}

// =================================================
// ================ Auth Proxy ===================
// =================================================
export function getListAuthIpProxies(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/auth-ip",
    method: "get",
    params,
  });
}

export function createAuthIpProxies(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/auth-ip`,
    method: "post",
    data,
  });
}

export function deleteAllAuthIpProxies() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/auth-ip`,
    method: "delete",
  });
}
// =================================================
// ================ Ads Proxy ===================
// =================================================
export function getListAdsProxies(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/proxy_ads",
    method: "get",
    params,
  });
}

export function createAdsProxies(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/proxy_ads`,
    method: "post",
    data,
  });
}

export function deleteAllAdsProxies() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/proxy_ads`,
    method: "delete",
  });
}

// =================================================
// ================ Guy Proxy ======================
// =================================================
export function getListGuyProxies(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/guy",
    method: "get",
    params,
  });
}

export function createGuy(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/guy`,
    method: "post",
    data,
  });
}

export function deleteAllGuy() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/guy`,
    method: "delete",
  });
}

export function deleteByIds(ids) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/guy/ids`,
    method: "delete",
    data: { ids: ids },
  });
}

// =================================================
// ================ Starvpn  ======================
// =================================================
export function getListStarvpn(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/starvpn",
    method: "get",
    params,
  });
}

export function createStarvpn(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/starvpn`,
    method: "post",
    data,
  });
}

export function deleteAllStarvpn() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/starvpn`,
    method: "delete",
  });
}

// =================================================
// ================ BlacklistIp  ===================
// =================================================
export function getListBlacklistIp(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/blacklist_ip",
    method: "get",
    params,
  });
}

export function createBlacklistIp(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/blacklist_ip`,
    method: "post",
    data,
  });
}

export function deleteAllBlacklistIp() {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/blacklist_ip`,
    method: "delete",
  });
}

// =================================================
// ================ VpnAccount  ===================
// =================================================
export function listVpnAccount(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/vpn_account",
    method: "get",
    params,
  });
}

export function insertVpnAccount(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/vpn_account`,
    method: "post",
    data,
  });
}

export function updateVpnAccount(uid, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/vpn_account/${uid}`,
    method: "put",
    data,
  });
}

export function deleteVpnAccount(uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/vpn_account/${uid}`,
    method: "delete",
  });
}

// =================================================
// ================ Dcom Proxy  ===================
// =================================================
export function listDcomProxy(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_proxy",
    method: "get",
    params,
    withoutLoading: true,
    withoutError: true,
  });
}

export function updateDcomProxy(uid, data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/dcom_proxy/${uid}`,
    method: "put",
    data,
  });
}

export function resetDcomProxy(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: `/u-apis/proxies/dcom_proxy/reset`,
    method: "patch",
    data: { data: data },
  });
}

// =================================================
// ================ Dcom ProxyV2  ===================
// =================================================
export function listDcomV2(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_v2",
    method: "get",
    params,
    withoutLoading: true,
    withoutError: true,
  });
}

export function resetDcomProxyV2(data) {
  // {uids:[u1,u2]}
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_v2/reset",
    method: "patch",
    data: data,
    withoutLoading: true,
    withoutError: true,
  });
}

export function statisticDcomProxyV2(params) {
  // {uids:[u1,u2]}
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_v2/statistic",
    method: "get",
    params,
    withoutLoading: true,
    withoutError: true,
  });
}

//----------------- order --------
export function listDcomProxyV2(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_proxy_v2",
    method: "get",
    params,
    withoutLoading: true,
    withoutError: true,
  });
}
export function orderDcomProxyV2(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_proxy_v2",
    method: "post",
    data: data,
    withoutLoading: true,
    withoutError: true,
  });
}

export function changeIpDcomProxyV2(data) {
  // {uids:[u1,u2]}
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_proxy_v2/change-ip",
    method: "patch",
    data: data,
    withoutLoading: true,
    withoutError: true,
  });
}

export function proxyV2ChangeIpDuration(data) {
  // {uids:[u1,u2]}
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_proxy_v2/change-ip-duration",
    method: "patch",
    data: data,
    withoutLoading: true,
    withoutError: true,
  });
}

export function proxyV2ChangeUserPass(data) {
  // {uids:[u1,u2]}
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_proxy_v2/change-user-pass",
    method: "patch",
    data: data,
    withoutLoading: true,
    withoutError: true,
  });
}
export function proxyV2ExtendLimit(data) {
  // {uids:[u1,u2]}
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_proxy_v2/extend-limit",
    method: "patch",
    data: data,
    withoutLoading: true,
    withoutError: true,
  });
}
export function delDcomProxyV2(data) {
  // {uids:[u1,u2]}
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/dcom_proxy_v2/del",
    method: "patch",
    data: data,
    withoutLoading: true,
    withoutError: true,
  });
}

// ---------- tinsoft --------------------
export function tinsoftProxyList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/tinsoft-proxy",
    method: "get",
    params,
    withoutLoading: true,
    withoutError: true,
  });
}

// data: {data:[key1,key2]}
export function tinsoftProxyAdd(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/tinsoft-proxy",
    method: "post",
    data: data,
    withoutLoading: true,
    withoutError: true,
  });
}
export function tinsoftProxyDel(uids) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/u-apis/proxies/tinsoft-proxy",
    method: "delete",
    data: { data: uids },
    withoutLoading: true,
    withoutError: true,
  });
}
